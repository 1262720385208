import {EmailField} from './Fields'

const ForgotPasswordForm = {
    'name': 'ForgotPassword',
    'title': 'Reestablecer Contraseña',
    'description': 'Si ya tienes una cuenta, ingresa tu e-email y enviaremos un link para que crees una nueva contraseña',
    'fields': {
        'email': EmailField
        ,
    },
    'buttons': {
        'primary': {
            'type': 'submit',
            'value': 'Enviar nueva clave',
            'block': true,
            'color': 'primary'
        },
    },

}

export default ForgotPasswordForm
