import React, {Component} from 'react';
import {connect} from 'react-redux';
import {EDIT_FORM, SCREEN_LOAD, SUBMIT} from 'constants/ReducerActions/Login'
import LoginAction from "../../actions/Login";
import LoginForm from 'constants/Forms/Login'
import FormLogin from 'components/Login/FormLogin';
import {Link, withRouter} from "react-router-dom"
import config from 'configReactApp'
import ProfileAction from "../../actions/Profile";
import {Container, Row} from "reactstrap";
import Waiting from "components/Waiting/Waiting";
import {GoBackButton, GoForgotPasswordButton, SocialLoginButtons} from "../../components/Buttons";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faExclamationTriangle} from '@fortawesome/free-solid-svg-icons'
import {isAuth} from 'isAuth';
import ErrorAlert from 'components/Alert/ErrorAlert'
import {redirectToUrl} from "../../helper";

class LoginView extends Component {
    args = {};

    socialLoginErrors

    constructor(props) {
        super(props);
        if (isAuth()) {

            props.history.replace(config('app.after_login'));
        } else {
            props.onInit();
        }
        this.socialLoginErrors = this.props.location.state?.error;
        // Clears navigation state. To delete errors persisted in navigation state
        window.history.replaceState(null, '')
    }

    handleSubmitLogin = (e) => {
        e.preventDefault();
        const referer_url = this.props.location.pathname;
        this.props.history.replace(config('app.social_login_callback'));
        this.props.onSubmit(e)
            .then(response => {
                const {redirectTo, params} = redirectToUrl(response);
                this.props.history.replace(redirectTo, params);
            })
            .catch(err => {
                console.error(err);
                this.props.history.replace(referer_url);
            })
    }

    prepare() {
        this.args = {
            ...LoginForm,
            onChange: this.props.onChange,
            onSubmit: this.handleSubmitLogin,
            inline: false,
        };
    }

    render() {
        this.prepare();
        const history = this.props.history;

        const inviteRegister = (
            <strong className="font-weight-bolder">
                <FontAwesomeIcon icon={faExclamationTriangle} size="1x" className="text-primary mr-2"/>
                ¿Eres nuevo en turnera? registrate <Link to={'/register'}
                                                         onClick={() => history.push('/register')}>acá</Link>
            </strong>
        );

        return <Waiting waiting={this.props.waiting || this.props.waiting_profile_data}>
            {this.socialLoginErrors && <Container>
                <Row>
                    <ErrorAlert text={this.socialLoginErrors}/>
                </Row>
            </Container>}
            <div className="mt-3"><FormLogin description={this.props.errors ? inviteRegister : ''}
                                             name='Login' {...this.args}
                                             data={this.props.data}/>
                <Container className="my-1 ">
                    <GoForgotPasswordButton/>
                </Container>
                <SocialLoginButtons/>
                <Container className="my-4"><GoBackButton href="/"/></Container>
            </div>
        </Waiting>
    }
}

const mapStateToProps = state => {

    return {
        data: state.LoginReducer || null,
        waiting: state.LoginReducer.waiting || null,
        waiting_profile_data: state.ProfileReducer.waiting || null,
        errors: state.LoginReducer.errors || null,
        profile: state.ProfileReducer ?? {},
    }
};

const mapDispatchToProps = (dispatch, getState) => {
    return {
        onInit: () => dispatch({
            type: SCREEN_LOAD
        }),
        onChange: e => dispatch({
            type: EDIT_FORM,
            payload: {
                name: e.target.name,
                value: e.target.value,
                type: e.target.type,
                files: e.target.files
            }
        }),
        onSubmit: e => {
            e.preventDefault();
            LoginAction.FetchRequest = SUBMIT;
            return dispatch(LoginAction.save(dispatch, getState, e));


        },
        loadProfile: () => dispatch(ProfileAction.loadProfileData()),
    };
};

export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(LoginView));
