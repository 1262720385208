import {getenv} from 'configReactApp'

const _ = {
    "google_client_id": getenv('GOOGLE_OAUTH_CLIENT_ID','1045761277073-bv7rcnc6r6bnbhl9tmavbav1asng5bmj.apps.googleusercontent.com'),
    "facebook_client_id": getenv('FACEBOOK_OAUTH_CLIENT_ID','222194462444422'),
    "instagram_client_id": getenv('INSTAGRAM_OAUTH_CLIENT_ID','665082004097760'),
    "linkedin_client_id": getenv('LINKEDIN_OAUTH_CLIENT_ID','77tr7qg9syh3mw'),
    "microsoft_client_id": getenv("MICROSOFT_OAUTH_CLIENT_ID",'4731068c-c76f-40b7-93f8-f631b0eba495'),
    "google_analytics_id": getenv('GOOGLE_ANALYTICS_ID',''),
}
export default _;
