import React, {Component} from 'react';
import {connect} from "react-redux";
import {EDIT_FORM, SCREEN_LOAD, SUBMIT} from 'constants/ReducerActions/ForgotPassword'
import ForgotPasswordAction from "../../actions/ForgotPassword";
import ForgotPasswordForm from 'constants/Forms/ForgotPassword'
import {withRouter} from "react-router-dom"
import {GoBackButton} from "../../components/Buttons";
import {Container} from "reactstrap";
import FormTag from "../../components/Forms/FormTag";
import config from "../../configReactApp";


class ForgotPasswordView extends Component {
    args = {};

    constructor(props) {
        super(props);
        props.onInit();
    }

    handleSubmit = (e) => {
        this.props.onSubmit(e)
            .then(response => {
                if (response) {
                    this.props.history.push(config('app.forgot_password_succeed'))
                }
            })
            .catch(err => console.log(err))
    }

    prepare() {
        this.args = {
            ...ForgotPasswordForm,
            onChange: this.props.onChange,
            onSubmit: this.handleSubmit,
            inline: false,
        };
    }

    render() {
        this.prepare();
        return <div className="my-3">
            <FormTag name='NewForgotPassword' {...this.args} data={this.props.data} errors={this.props.errors}/>

            <Container className="my-4">
                <GoBackButton href={config('app.login')}/>

            </Container>
        </div>
    }
}

const mapStateToProps = state => {

    return {
        errors: state.ForgotPasswordReducer.errors || null,
        data: state.ForgotPasswordReducer || null
    }
};

const mapDispatchToProps = (dispatch, getState) => {
    return {
        onInit: () => dispatch({
            type: SCREEN_LOAD
        }),
        onChange: e => dispatch({
            type: EDIT_FORM,
            payload: {
                name: e.target.name,
                value: e.target.value,
                type: e.target.type,
                files: e.target.files
            }
        }),
        onSubmit: e => {
            e.preventDefault();
            ForgotPasswordAction.FetchRequest = SUBMIT;
            return dispatch(ForgotPasswordAction.save(dispatch, getState, e));
        }
    };
};

export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(ForgotPasswordView));
