import React, {useState} from 'react';
import {
    Carousel,
    CarouselItem,
    CarouselControl,
    CarouselIndicators,
    // CarouselCaption
} from 'reactstrap';

import Image1 from 'assets/img/horario_wong.png';
import Image2 from 'assets/img/horario_metro.jpeg';
//import Image3 from 'assets/img/Bienvenido3.png';
//import Image4 from 'assets/img/metroaviso-02-07-2020.jpg'
//import Image5 from 'assets/img/wongaviso-02-07.png'
// import LogoTurnera from "assets/img/logo-turnera.png";

const items = [
    {id: 1, altText: 'Slide 1', caption: 'Slide 1', src: Image1,},
    {id: 2, altText: 'Slide 2', caption: 'Slide 2', src: Image2,},
//    {id: 3, altText: 'Slide 3', caption: 'Slide 3', src: Image3,},
//    {id: 4, altText: 'Slide 4', caption: 'Slide 4', src: Image4,},
//    {id: 5, altText: 'Slide 5', caption: 'Slide 5', src: Image5,},
//
];

const Slider = (props) => {
    const [activeIndex, setActiveIndex] = useState(0);
    const [animating, setAnimating] = useState(false);

    const next = () => {
        if (animating) return;
        const nextIndex = activeIndex === items.length - 1 ? 0 : activeIndex + 1;
        setActiveIndex(nextIndex);
    }

    const previous = () => {
        if (animating) return;
        const nextIndex = activeIndex === 0 ? items.length - 1 : activeIndex - 1;
        setActiveIndex(nextIndex);
    }

    const goToIndex = (newIndex) => {
        if (animating) return;
        setActiveIndex(newIndex);
    }

    const slides = items.map((item) => {
        const css = {
            backgroundImage: `url(${item.src})`,
            'width': '100%',
            'height': '100%',
            // 'background-size': '50%',
            backgroundPosition: 'center center',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'contain',
            backgroundAttachment: 'inherit'


        }
        // console.log(css)
        return (
            <CarouselItem
                tag="div"
                key={item.id}
                onExiting={() => setAnimating(true)}
                onExited={() => setAnimating(false)}
            >
                <div style={css}/>
                {/*<CarouselCaption className="text-danger" captionText={item.caption} captionHeader={item.caption}/>*/}
            </CarouselItem>
        );
    });

    return (
        <>
            {/*<style>*/}
            {/*    {*/}
            {/*        `.custom-tag {*/}
            {/*  max-width: 100%;*/}
            {/*  height: 500px;*/}
            {/*  background: black;*/}
            {/*}`*/}
            {/*    }*/}
            {/*</style>*/}
            <Carousel
                activeIndex={activeIndex}
                next={next}
                previous={previous}
            >
                <CarouselIndicators items={items} activeIndex={activeIndex} onClickHandler={goToIndex}/>
                {slides}
                <CarouselControl direction="prev" directionText="Previous" onClickHandler={previous}/>
                <CarouselControl direction="next" directionText="Next" onClickHandler={next}/>
            </Carousel>
        </>
    );
}

export default Slider;
