const EditProfileForm = {
    'name': 'User',
    'title': {'text': 'Editar datos'},
    'description': 'Agenda tu visita al mall y salta la fila de tus tiendas favoritas con Turnos Cencosud',
    'fields': {
        'first_name': {
            'label': 'Nombre',
            'placeholder': 'Ingrese su nombre',
            'required': true,
            'maxLength': 30,
            'value':'test'
        },
        'last_name': {
            'label': 'Apellido',
            'placeholder': 'Ingrese su apellido',
            'required': true,
            'maxLength': 30
        },
        'identity_value': {
            'label': 'Documento de Identidad',
            'placeholder': 'Ingrese el número aquí',
            'type': 'input-group-dropdown',
            'required': true,
            'dropdown': {
                'identity_id': {
                    'label': 'Tipo de documento',
                }
            }
        },
        'phone': {
            'label': 'Teléfono móvil',
            'value': 'phone',
            'placeholder': '+51 22 9399 2322',
            'required': false,
            'description': 'Solo usaremos tu teléfono para comunicarnos en casos necesarios'
        },
    },
    'buttons': {
        'submit': {
            'type': 'submit',
            'block': true,
            'color': 'primary',
            'label': 'Guardar'

        }
    }

}

export default EditProfileForm
