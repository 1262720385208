import React from 'react';
import { Media } from 'reactstrap';
import LoadImage from '../Branding/Logo';

const imgStyle = {
    maxHeight: '74px',
    maxWidth: '74px',
}

class BrandItem extends React.Component {

    render() {

        const brand = this.props.item;

        return (
            <Media>
                <Media left middle>
                    <Media object src={LoadImage(brand.logo)} alt={brand.name} className="mr-2" style={imgStyle} />
                </Media>
                <Media body>
                    <Media heading>{brand.name}</Media>
                </Media>
            </Media>
        );

    }
}

export default BrandItem
