export const SCREEN_LOAD = "EDITLOCATION_SCREEN_LOAD";

// CREAR REGISTRO
export const EDIT_FORM = "EDITLOCATION_EDIT_FORM";
export const SUBMIT = "EDITLOCATION_SUBMIT";
export const SUBMIT_DONE = "EDITLOCATION_SUBMIT_DONE";
export const SUBMIT_ERROR = "EDITLOCATION_SUBMIT_ERROR";

// ANULAR REGISTRO
export const CANCEL = "EDITLOCATION_CANCEL";
export const CANCEL_DONE = "EDITLOCATION_CANCEL_DONE";
export const CANCEL_ERROR = "EDITLOCATION_CANCEL_ERROR";

// MODIFICAR REGISTRO
export const UPDATE = "EDITLOCATION_UPDATE";
export const UPDATE_DONE = "EDITLOCATION_UPDATE_DONE";
export const UPDATE_ERROR = "EDITLOCATION_UPDATE_ERROR";