import React, { Component } from 'react';
import Information from "../components/Feedback/Information";
import { withRouter } from "react-router-dom"
import config from 'configReactApp'

class SucceedDeleteAccountView extends Component {

    componentDidMount() {
        setTimeout(() => {
            this.props.history.push(config('app.after_logout'))
        }, 3000);
    }

    render = () => <Information title="Cuenta eliminada" description="Se cerrará su sesión" />
}

export default withRouter(SucceedDeleteAccountView);