export const SCREEN_LOAD = "BOOKING_SCREEN_LOAD";
export const SET_STORE_TIME_DATA = "BOOKING_SET_STORE_TIME_DATA";

// CREAR RESERVA
export const EDIT_FORM = "BOOKING_EDIT_FORM";
export const SUBMIT = "BOOKING_SUBMIT";
export const SUBMIT_DONE = "BOOKING_SUBMIT_DONE";
export const SUBMIT_ERROR = "BOOKING_SUBMIT_ERROR";
export const SET_PENDING_BOOKING_SAVE = "BOOKING_SET_PENDING_SAVE"
export const UNSET_PENDING_BOOKING_SAVE = "BOOKING_UNSET_PENDING_SAVE"

// ANULAR RESERVA
export const CANCEL = "BOOKING_CANCEL";
export const CANCEL_DONE = "BOOKING_CANCEL_DONE";
export const CANCEL_ERROR = "BOOKING_CANCEL_ERROR";
export const CLEAR_ERRORS = "BOOKING_CLEAR_ERRORS";

// MODIFICAR RESERVA
export const UPDATE = "BOOKING_UPDATE";
export const UPDATE_DONE = "BOOKING_UPDATE_DONE";
export const UPDATE_ERROR = "BOOKING_UPDATE_ERROR";
