const BookingEditForm = {
    'name': 'BookingEdit',
    'title': false,
    'description': '',
    'fields': {

        'date': {
            'label': 'Día',
            'type': 'select',
            'required': true,
            'disabled': true,
            'items': [
                {'key': 123, 'value': 'Lunes'},
                {'key': 321, 'value': 'Martes'},
            ],
        },
        'store_time_id': {
            'label': 'Hora',
            'type': 'select',
            'items': [
                {'key': 123, 'value': '9:00 - 10:00 cupos 20 de 200'},
                {'key': 12, 'value': '19:00 - 10:00 cupos 20 de 200'},
            ],
            'required': true
        },
    },
    'buttons': {
        'submit': {
            'type': 'submit',
            'color': 'primary',
            'className': 'mt-6 mb-4',
            'block': true,
        }
    }

}

export default BookingEditForm
