import CountryAction from "./Country";
import IdentityAction from "./Identity";
import CityAction from "./City";
import {APP_END, APP_START} from "../constants/ReducerActions/App"
import LocationsAction from "./Locations";

class InitAction {
    running = false;
    init = () => (dispatch, getState) => {
        this.running = true;
        const state = getState();
        const Loaders = [];
        if (!state.LocationsReducer.list.length) {
            Loaders.push(LocationsAction.all());
        }
        if (!state.CountryReducer.list.length) {
            Loaders.push(CountryAction.all());
        }
        if (!state.CityReducer.all.length) {
            Loaders.push(CityAction.all());
        }
        if (!state.IdentityReducer.list.length) {
            Loaders.push(IdentityAction.all());
        }
        if (!state.IdentityReducer.personal.length) {
            Loaders.push(IdentityAction.personal());
        }
        if (!state.IdentityReducer.social.length) {
            Loaders.push(IdentityAction.socials());
        }

        dispatch({
            type: APP_START
        });
        if (Loaders.length) {
            return Promise.allSettled(Loaders.map(dispatch)).then(() => dispatch({
                type: APP_END
            })).catch(e => console.error(e));
        } else {
            dispatch({
                type: APP_END
            });
        }

    }
}

export default InitAction
