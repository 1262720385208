import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCircleNotch} from "@fortawesome/free-solid-svg-icons";
import React from "react";

class Waiting extends React.Component {
    render = () => this.props.waiting || this.props.waiting_alt ?
        <h4 className="text-center pt-6">
            <FontAwesomeIcon icon={faCircleNotch} size="4x" className="text-primary fa-spin"/>
        </h4>
        : (this.props.children || '')
}

export default Waiting
