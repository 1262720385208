import React from "react";
import {Alert, Col, Container, Row} from 'reactstrap';
import {connect} from 'react-redux';
import {isAppIntern, isNotAppIntern} from "helper";
import {LoginFacebook, LoginGoogle, LoginInstagram, LoginLinkedin, LoginMicrosoft} from '../Social/SocialLogin'

class SocialLoginButtons extends React.Component {
    state = {
        modal: false
    };


    toggle = () => {
        this.setState({modal: (!this.state.modal)});
    }

    render() {

        const {socialIdentities} = this.props
        const facebook = isNotAppIntern() && Object.values(socialIdentities).find(s => s.name === "facebook");
        const google = isNotAppIntern() && Object.values(socialIdentities).find(s => s.name === "google");
        const instagram = isNotAppIntern() && Object.values(socialIdentities).find(s => s.name === "instagram");
        const linkedin = isNotAppIntern() && Object.values(socialIdentities).find(s => s.name === "linkedin");
        const microsoft = isAppIntern();


        return <Container fluid={true} className="mt-sm-5 p-2 pt-sm-4" id="LoginButtons"><Row>
            {isNotAppIntern() && <Col xs="auto">
                { facebook || google 
                    ? 
                    <strong className="text-center d-sm-block"
                            style={{marginBottom: '1rem'}}>{this.state.modal} o usa tus redes sociales ...</strong>
                    : <></>
                }
            </Col>}
            <Alert color="primary" isOpen={this.state.modal} toggle={this.toggle}> Próximamente </Alert>
            {facebook && <Col><LoginFacebook identity_id={facebook.id}/></Col>}
            {google && <Col><LoginGoogle identity_id={google.id}/></Col>}
            {instagram && <Col><LoginInstagram onClick={this.toggle}/></Col>}
            {linkedin && <Col><LoginLinkedin onClick={this.toggle}/></Col>}
            {microsoft && <Col><LoginMicrosoft/></Col>}
        </Row></Container>;
    }
}

const mapStateToProps = (state) => ({
    socialIdentities: state.IdentityReducer.social
})


export default connect(mapStateToProps)(SocialLoginButtons);
