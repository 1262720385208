import * as ReducerActions from '../constants/ReducerActions/Register'
import LoginAction from './Login'
import RegisterForm from "constants/Forms/Register";
import { validate } from "./FieldValidator";
import CountryAction from "../actions/Country";

// copiado de RegisterAction
const GuestRegisterAction = {
    FetchRequest: '',
    initAction: () => {
        return {type: GuestRegisterAction.FetchRequest}
    },

    addError: (dispatch) => (errors) => dispatch({ type: ReducerActions.UPDATE_ERROR, payload: errors }),

    onValidateError: (dispatch) => (errors) => dispatch({ type: ReducerActions.SUBMIT_ERROR, payload: errors }),

    validate: (data, dispatch) => {
        // Clean all errors
        GuestRegisterAction.onValidateError(dispatch)({});
        const generalValidation = validate(data, RegisterForm.fields, GuestRegisterAction.onValidateError(dispatch));
        return generalValidation;
    },

    save: () => (dispatch, getState) =>
        new Promise(function (resolve, reject) {
            let {errors, waiting, ...data} = {...getState().RegisterReducer};
            if (data.phone) {
                if (!data.phone.startsWith('+')){
                    data.phone = data.prefix + data.phone;
                }
            }else{
                delete data.phone;
            }
            delete data.prefix;
            if (GuestRegisterAction.validate(data,dispatch)) {
                dispatch({ type: ReducerActions.SUBMIT });
                dispatch(LoginAction.phoneLogin(data))
                    .then(response => {
                        dispatch(GuestRegisterAction.success(response))
                        resolve(response);
                    })
                    .catch(error => {
                        if (error.response) {
                            dispatch(GuestRegisterAction.showErrors(error.response.data))
                            reject(error.response.data)
                        } else {
                            dispatch(GuestRegisterAction.showErrors(error))
                            reject(error)
                        }
                    })
                ;
                dispatch(GuestRegisterAction.initAction())
            } else {
                reject()
            }
        })
    ,

    showErrors: (response) => {
        return {type: ReducerActions.SUBMIT_ERROR, payload: response || null}
    },

    success: (response) => {
        return {type: ReducerActions.SUBMIT_DONE, payload: response.data || null};
    },

    setDefaultCountryAndCity : (countryId) => (dispatch,getState) => {
        dispatch({
            type: ReducerActions.EDIT_FORM,
            payload: {
                name: "country_id",
                value: countryId,
                type: 'change'
            }
        })
        dispatch(CountryAction.cities()).then(() => {
            if(Object.values(getState().CityReducer.list).filter(c=> c.country_id.toString() === countryId.toString()).length === 1){
                dispatch({
                    type: ReducerActions.EDIT_FORM,
                    payload: {
                        name: "city_id",
                        value: Object.values(getState().CityReducer.list).find(c=> c.country_id.toString() === countryId.toString())?.id,
                        type: 'change'
                    }
                })
            }
        });
    },

};

export default GuestRegisterAction
