import React from 'react';
import FormTag from "components/Forms/FormTag";

class FormLogin extends React.Component {

    render = () => <FormTag name={this.props.name} {...this.props} data={this.props.data}/>

}

export default FormLogin
