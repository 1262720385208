import * as Action from '../constants/ReducerActions/StoreTime'
import InitialState from '../constants/InitialState/StoreTime'

const StoreTimeReducer = (state = InitialState, {type, payload}) => {
    switch (type) {
        case Action.SET_STORE:
            return {...InitialState, store_id: payload};
        case Action.SET_DATE:
            const date = payload || state.date;
            return {...state, date: date, list: state.all[date]};
        case Action.LIST :
            return {...state, waiting: true};
        case Action.LIST_DONE    :
            return {...state, waiting: false, ...payload};
        case Action.LIST_ERROR   :
            return {...state, waiting: false, errors: {...payload}};
        default:
            return state;
    }
};

export default StoreTimeReducer;
