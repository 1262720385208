import React from 'react';
import LoginButtons from "../../components/Buttons/SocialLoginButtons";
import {GoGuestSignUpButton, GoLoginButton, GoSignUpButton} from "../../components/Buttons";
import {useLocation} from "react-router-dom";
import {isNotAppIntern} from 'helper';

// copiado de PublicHomeView
const MustLoginView = (props) => {

    const location = useLocation();
    const bprops = {className: "my-sm-5 my-2", outline: false, navigatestate: location.state, search: location.search};

    return <>
        <div className="mt-3">
            <h1>Identifícate</h1>
            <p>Debes autenticarte en Turnera para continuar.</p>
        </div>
        {isNotAppIntern() && <GoLoginButton {...bprops} />}
        {isNotAppIntern() && <GoSignUpButton {...bprops} />}
        {isNotAppIntern() && <GoGuestSignUpButton {...bprops}/>}
        <LoginButtons />
    </>;
}

export default MustLoginView;
