import * as Action from '../constants/ReducerActions/VirtualQueue'

const VirtualQueueReducer = (state = {list: []}, {type, payload}) => {
    switch (type) {
        case Action.SCREEN_LOAD:
            return {
                list: [], ...payload
            };
        case Action.LIST :
            return {...state, waiting: true};
        case Action.LIST_DONE    :
            return {...state, waiting: false, list: {...payload}};
        case Action.LIST_ERROR   :
            return {...state, waiting: false, errors: {...payload}};
        case Action.SET_PENDING_QUEUE_SAVE:
            return {...state, pendingSave: true}
        case Action.UNSET_PENDING_QUEUE_SAVE:
            return {...state, pendingSave: false}
        default:
            return state;
    }
};

export default VirtualQueueReducer;
