import React from 'react';
import { Jumbotron, Button,Container,Row,Col } from 'reactstrap';


export default function () {
    return <Container><Row><Col><Jumbotron>
        <h1 className="display-3">Hey!</h1>
        <p className="lead">La página no existe.</p>
        <hr className="my-2" />
        <p>Error 404</p>
        <p className="lead">
            <Button color="primary" href="/">Volver al Home</Button>
        </p>
    </Jumbotron></Col></Row></Container>
}
