import ApiBooking from '../api/ApiBooking'
import * as Actions from '../constants/ReducerActions/BookingDetail'

const BookingDetailAction = {
    initAction: () => {
        return {type: Actions.GET}
    },
    success: (booking) => {
        return {type: Actions.GET_DONE, payload: booking || null}
    },
    errors: (response) => {
        return {type: Actions.GET_ERROR, payload: response || null}
    },
    get: () => (dispatch, getState) => new Promise(function(resolve,reject){
        const id = getState().BookingDetailReducer.id;
        if (id) {
            dispatch(BookingDetailAction.initAction());
            ApiBooking
                .get(id)
                .then(response => {
                    dispatch(BookingDetailAction.success(response.data || {}))
                    resolve(response);
                })
                .catch(error => {
                    dispatch(BookingDetailAction.errors(error))
                    reject(error);
                })
            ;
        } else {
            dispatch(BookingDetailAction.errors("no id"))
        }
    })
};

export default BookingDetailAction
