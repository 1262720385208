import React, {Component} from 'react';
import ListItem from "../../components/Lists/ListItem";
import StoreAction from "../../actions/Store";
import {connect} from "react-redux";
import StoreSearchBar from "../../components/Search/StoreSearchBar"
import config from "../../configReactApp";
import Waiting from "../../components/Waiting/Waiting";
import {hasChildren} from 'helper';
import {withRouter} from 'react-router-dom';
import {Progress} from 'reactstrap';
import {APP_RESET} from "../../constants/ReducerActions/App";
// import {SCREEN_LOAD} from '../../constants/ReducerActions/Store';
import {SET_VIRTUAL_QUEUE} from '../../constants/ReducerActions/Settings';
import queryString from 'query-string'
import _ from "lodash";

class VirtualQueueSearchView extends Component {

    componentDidMount() {
        const search = queryString.parse(this.props.location.search);
        let parent_id = parseInt(search?.parent_id);

        if (isNaN(parent_id) && this.props.settings.parentStoreId) {
            parent_id = this.props.settings.parentStoreId;
            window.location.search = queryString.stringify({...search, parent_id});
        }

        this.props.onInit(parent_id, this.props);
    }

    listado = () => {
        if (Object.values(this.props.stores).length) {
            let stores = Object.values(this.props.stores);
            if (!this.props.location?.search?.indexOf('parent_id') > 0) {
                stores = Object.values(this.props.stores).filter(s => hasChildren(s));
            }
            return <ListItem name="Store" data={stores} isVirtualQueue={true}
                             link={config('app.booking.new')}/>
        } else {
            return <h4 className="text-center p-5">No se encontró Tienda</h4>
        }
    }


    render() {
        const progress = this.props.progress;
        const showProgress = progress > 0 && progress < 100;

        return <>
            <Waiting waiting={this.props.waiting}>
                <h2 className="d-none d-sm-block">{_.get(this, 'props.store.name', 'Fila Virtual')}</h2>
                <h5 className="d-none d-sm-block">Elige tu tienda</h5>
                <p>Pide tu turno de forma rápida y segura, y evita demoras.</p>
                <p>* Fila virtual en tiempo real<br/>* Turnos solo por el día.</p>
                <StoreSearchBar/>
                {/*<div className={"text-center " + showProgress ? 'd-block' : 'hide'}>{progress}</div>*/}
                <Progress value={progress} style={{
                    margin: '10px',
                    display: showProgress ? 'block' : 'none',
                    opacity: showProgress ? '100%' : '0%'
                }}/>
                {this.listado()}
            </Waiting>


        </>
    }
}

const mapStateToProps = (state) => {

    return {
        stores: state.StoreReducer.list || [],
        waiting: state.StoreReducer.waiting || false,
        store: state.StoreReducer.parent || {
            id: 0, name: "",
            street: "",
            street_number: "",
            suburb: "",
            state: "",
        },
        progress: state.ProgressReducer.percentage.value,
        settings: state.SettingsReducer
    }
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        onInit: (parent_id, props) => {
            if (parent_id) {
                dispatch(StoreAction.getChildren(parent_id));
                dispatch({type: SET_VIRTUAL_QUEUE, payload: parent_id});
            } else {
                dispatch({type: APP_RESET})
                // dispatch({type: SCREEN_LOAD});
            }
        },
    };
};


export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(VirtualQueueSearchView));
