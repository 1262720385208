import React, {Component} from 'react';
import FormTag from "components/Forms/FormTag";
import {connect} from 'react-redux';
import {EDIT_FORM, SCREEN_LOAD, SUBMIT} from 'constants/ReducerActions/Register'
import RegisterAction from "../../actions/Register";
import RegisterForm from 'constants/Forms/Register'
import {InputEvts, toOptions} from 'components/Forms/Utils'
import CountryAction from "../../actions/Country";
import {Container, Row} from 'reactstrap'
import {withRouter} from "react-router-dom"
import {GoBackButton, GoLoginButton, SocialLoginButtons} from "components/Buttons";
import TermsAndConditionsCheck from "../../components/Forms/FormTermsAndConditions";
import ErrorAlert from 'components/Alert/ErrorAlert';
import {CHILE, DNI, redirectToUrl, RUT} from "../../helper"
import Waiting from "components/Waiting/Waiting";

class RegisterView extends Component {
    args = {};

    socialLoginErrors

    constructor(props) {
        super(props);

        this.state = {
            missing_terms: false
        }
        this.socialLoginErrors = this.props.location.state?.error;
        // Clears navigation state. To delete errors persisted in navigation state
        window.history.replaceState(null, '')
    }

    // componentDidUpdate(newProps) {
    //     const identities = Object.values(newProps.identities);
    //     console.log(identities)
    //     if (!newProps.data.identity_id && identities.length > 0) {
    //         this.props.setDefaultIdentity()
    //     }
    // }

    componentDidMount() {
        this.props.onInit(this.props.locations, this.props);
    }

    handleOnCountryChange = e => {
        e && e.preventDefault();
        const selectedCountry = e.target.value;
        this.props.onCountryChange(selectedCountry);
        this.setPhonePrefix(selectedCountry, this.props.countries)
        this.props.setIdentityField(selectedCountry, this.props);
    }

    setPhonePrefix = (selectedCountry, countries) => {
        let selectedCountryPhonePrefix = null;
        for (const countryIndex in countries) {
            const country = countries[countryIndex];
            if (country.id === parseInt(selectedCountry)) {
                selectedCountryPhonePrefix = country.phone_code;
                break;
            }
        }
        this.props.setPhonePrefix(selectedCountryPhonePrefix);
    }

    handleRegisterSubmit = (e) => {
        e.preventDefault();
        if (!this.props.data.accept_terms_and_conditions) {
            this.setState({missing_terms: true});
        } else {
            this.setState({missing_terms: false});
            // const referer_url = this.props.location.pathname;
            // this.props.history.replace(config('app.social_login_callback'));
            this.props.onSubmit(e)
                .then(response => {
                    const {redirectTo, params} = redirectToUrl(response);
                    this.props.history.replace(redirectTo, params);
                })
                .catch(err => {
                    console.error(err);
                    // this.props.history.replace(referer_url);
                })
        }
    }


    prepare() {
        this.args = {
            ...RegisterForm,

            onChange: this.props.onChange,
            onSubmit: this.handleRegisterSubmit,

            inline: false,

        };
        const country_on_events = new InputEvts();
        country_on_events.add('change', this.props.onChange);
        country_on_events.add('change', this.handleOnCountryChange);
        const countryOptions = toOptions(this.props.countries)
        this.args.fields.country_id.items = countryOptions;
        this.args.fields.country_id.placeholder = "Seleccione un país";
        this.args.fields.country_id.selectedValue = this.props.data.country_id ?
            countryOptions.find(o => o.key.toString() === this.props.data.country_id.toString())?.value : this.args.fields.country_id.placeholder;
        this.args.fields.country_id.e = country_on_events;

        const cityOptions = toOptions(this.props.cities);
        this.args.fields.city_id.items = cityOptions;
        this.args.fields.city_id.placeholder = "Seleccione una ciudad";
        this.args.fields.city_id.selectedValue = this.props.data.city_id ?
            cityOptions.find(o => o.key.toString() === this.props.data.city_id.toString())?.value : this.args.fields.city_id.placeholder;

        this.args.fields.identity_value.items = toOptions(this.props.identities);
        this.args.fields.identity_value.selectedValue = Object.values(this.props.identities).find(i => i.id.toString() === this.props.data.identity_id?.toString())?.name;
        this.args.fields.phone.prefix = this.props.data.prefix;

        if (countryOptions.length === 1 && cityOptions.length === 1) {
            this.args.fields.country_id.type = 'hidden';
            this.args.fields.city_id.type = 'hidden';
        }
    }

    render() {
        this.prepare();

        const form = <FormTag name='NewUser' {...this.args} data={this.props.data} footer={
            <>
                {this.state.missing_terms && <ErrorAlert
                    text="Debe aceptar los términos y condiciones"/>}
                <TermsAndConditionsCheck checked={this.props.data.accept_terms_and_conditions}
                                         country={this.props.data.country_id}
                                         onValueChange={this.props.onTermsCheckChange}/>
            </>

        }/>
        return <Waiting waiting={this.props.waiting}>
            <div className="my-3 scrollable">
                {this.socialLoginErrors && <Container>
                    <Row>
                        <ErrorAlert text={this.socialLoginErrors}/>
                    </Row>
                </Container>}
                {form}
                <Container className="my-3">
                    <GoLoginButton/>
                </Container>
                <SocialLoginButtons/>
                <Container className="my-4"><GoBackButton/></Container>
            </div>
        </Waiting>
    }
}

const mapStateToProps = state => {

    return {
        locations: state.LocationsReducer.list || [],
        countries: state.CountryReducer.list || null,
        cities: state.CityReducer.list || null,
        identities: state.IdentityReducer.personal || null,
        data: state.RegisterReducer || null,
        waiting: state.RegisterReducer.waiting || state.AppReducer.load || state.LocationsReducer.list === null
    }
};

const mapDispatchToProps = (dispatch, getState) => {
    return {
        onInit: (locations, props) => {
            dispatch({type: SCREEN_LOAD});
            if (locations.length === 1) {
                const country = locations[0];
                props.setDefaultCountry(country.id);
                props.setDefaultCity(country.cities[0].id);
                props.setPhonePrefix(country.phone_code);
                props.setIdentityField(country.id, props);
            }
        },

        onChange: e => dispatch({
            type: EDIT_FORM,
            payload: {
                name: e.target.name,
                value: e.target.value,
                type: e.target.type,
                files: e.target.files
            }
        }),
        setDefaultCountry(country_id) {
            dispatch({
                type: EDIT_FORM,
                payload: {
                    name: "country_id",
                    value: country_id,
                    type: "change",
                }
            })
        },
        setDefaultCity(id) {
            dispatch({
                type: EDIT_FORM,
                payload: {
                    name: "city_id",
                    value: id,
                    type: "change",
                }
            })
        },
        setPhonePrefix(value) {

            dispatch({
                type: EDIT_FORM,
                payload: {
                    name: "prefix",
                    value,
                    type: "change",
                }
            })
        },
        setIdentityField(country, props) {
            if (country.toString() === CHILE.toString()) {
                props.setIdentity(RUT);
            } else {
                props.setIdentity(DNI);
            }
        },
        setIdentity(value) {
            dispatch({
                type: EDIT_FORM,
                payload: {
                    name: "identity_id",
                    value,
                    type: "change",
                }
            })
        },
        onTermsCheckChange: value => dispatch({
            type: EDIT_FORM,
            payload: {
                name: 'accept_terms_and_conditions',
                value,
                type: 'change',
            }
        }),
        onCountryChange: (countryId) => {
            dispatch(CountryAction.cities(countryId))
                .then((response) => {
                    dispatch({
                        type: EDIT_FORM,
                        payload: {
                            name: "city_id",
                            value: response.data?.length === 1 ? response.data[0].id : null,
                            type: "change",
                        }
                    })
                });
        },
        onSubmit: e => {
            e.preventDefault();
            RegisterAction.FetchRequest = SUBMIT;
            return dispatch(RegisterAction.save(dispatch, getState, e));
        },

        setDefaultIdentity() {
            dispatch({
                type: EDIT_FORM,
                payload: {
                    name: "identity_id",
                    value: DNI,
                    type: "change",
                }
            })
        },
        setDefaultCountryAndCity(country) {
            dispatch(
                RegisterAction.setDefaultCountryAndCity(country)
            )
        }
    };
};


export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(RegisterView));
