import Api from './Api'
import config from 'configReactApp'

class _ApiQueue extends Api {

    save(id, config) {
        return this.post('queues', {'queue_id':id}, config);
    }

    getAll(config) {
        return this.get('queues',config);
    }

    getById(id,config) {
        return this.get("queues/" + id,config);
    }
    
}

const ApiQueue = new _ApiQueue({endpoint: config('api.endpoint.queue')});

export default ApiQueue
