import * as ReducerActions from '../constants/ReducerActions/Register'
import LoginAction from './Login'
import ApiRegister from "api/ApiRegister";
import RegisterForm from "constants/Forms/Register";
import { validate, validateIdentityField } from "./FieldValidator";
import CountryAction from "../actions/Country";


const RegisterAction = {
    FetchRequest: '',
    initAction: () => {
        return {type: RegisterAction.FetchRequest}
    },

    addError: (dispatch) => (errors) => dispatch({ type: ReducerActions.UPDATE_ERROR, payload: errors }),

    onValidateError: (dispatch) => (errors) => dispatch({ type: ReducerActions.SUBMIT_ERROR, payload: errors }),

    validate: (data, dispatch) => {
        // Clean all errors
        RegisterAction.onValidateError(dispatch)({});
        const generalValidation = validate(data, RegisterForm.fields, RegisterAction.onValidateError(dispatch));
        const identityValidation = validateIdentityField(data.identity_id, data.identity_value, RegisterAction.addError(dispatch));
        return generalValidation && identityValidation;
    },

    save: () => (dispatch, getState) =>
        new Promise(function (resolve, reject) {
            let {errors, waiting, ...data} = {...getState().RegisterReducer};
            if (data.phone) {
                if (!data.phone.startsWith('+')){
                    data.phone = data.prefix + data.phone;
                }
            }else{
                delete data.phone;
            }
            delete data.prefix;

            const cities = Object.values(getState().CityReducer.list);
            const countries = Object.values(getState().CountryReducer.list);
            
            if (cities.length === 1 && countries.length === 1) {
                data.city_id = cities[0].id
                data.country_id = countries[0].id
            }

            if (RegisterAction.validate(data,dispatch)) {
                dispatch({ type: ReducerActions.SUBMIT });
                ApiRegister
                    .register(data)
                    .then(response => {
                        dispatch(RegisterAction.success(response))
                        dispatch(LoginAction.setUserData())
                        resolve(dispatch(LoginAction.save()))
                    })
                    .catch(error => {
                        if (error.response) {
                            dispatch(RegisterAction.showErrors(error.response.data))
                            reject(error.response.data)
                        } else {
                            dispatch(RegisterAction.showErrors(error))
                            reject(error)
                        }
                    })
                ;
                dispatch(RegisterAction.initAction())
            } else {
                reject()
            }
        })
    ,

    showErrors: (response) => {
        return {type: ReducerActions.SUBMIT_ERROR, payload: response || null}
    },

    success: (response) => {
        return {type: ReducerActions.SUBMIT_DONE, payload: response.data || null};
    },

    setDefaultCountryAndCity : (countryId) => (dispatch,getState) => {
        dispatch({
            type: ReducerActions.EDIT_FORM,
            payload: {
                name: "country_id",
                value: countryId,
                type: 'change'
            }
        })
        dispatch(CountryAction.cities()).then(() => {
            if(Object.values(getState().CityReducer.list).filter(c=> c.country_id.toString() === countryId.toString()).length === 1){
                dispatch({
                    type: ReducerActions.EDIT_FORM,
                    payload: {
                        name: "city_id",
                        value: Object.values(getState().CityReducer.list).find(c=> c.country_id.toString() === countryId.toString())?.id,
                        type: 'change'
                    }
                })
            }
        });
    },

};

export default RegisterAction
