import {PasswordField, PasswordConfirmationField} from './Fields'

const ResetPasswordForm = {
    'name': 'ForgotPassword',
    'title': 'Reestablecer Contraseña',
    'description': 'Ingresa tu nueva contraseña',
    'fields': {
        'password': PasswordField,
        'password_confirmation': PasswordConfirmationField
    },
    'buttons':
        {
            'primary':
                {
                    'type': 'submit',
                    'value': 'Enviar nueva clave',
                    'block': true,
                    'color': 'primary'
                }
            ,
        }
    ,

}

export default ResetPasswordForm
