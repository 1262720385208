import * as Action from '../constants/ReducerActions/EditProfile'
import InitialState from '../constants/InitialState/EditProfile'

const EditProfileReducer = (state = InitialState, { type, payload }) => {
    switch (type) {
        case Action.SCREEN_LOAD:
            return { ...InitialState, ...payload };
        case Action.EDIT_FORM:
            return { ...state, [payload.name]: payload.value };
        case Action.SUBMIT:
            return { ...state, waiting: true };
        case Action.SUBMIT_DONE:
            return { ...state, waiting: false, ...payload };
        case Action.SUBMIT_ERROR:
            return { ...state, waiting: false, errors: { ...payload } };
        case Action.UPDATE_ERROR:
            return { ...state, errors: { errors: { ...state.errors?.errors, ...payload } } };
        default:
            return state;
    }
};

export default EditProfileReducer;
