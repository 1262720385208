import React, {Component} from 'react'
import Header from '../components/Header/Header'
import Footer from '../components/Footer/Footer'
import {connect} from "react-redux";
import PropTypes from 'prop-types';
import {Col, Container, Row} from 'reactstrap'
import LogoTurnera from "assets/img/logo-turnera.png";
import {isAuth} from 'isAuth';
import Sidebar from "../components/Menu/Sidebar";
import {Link} from "react-router-dom"
import _ from 'lodash'

class VirtualQueueLayout extends Component {
    constructor(props) {
        super(props)
        this.myRef = React.createRef()   // Create a ref object
    }

    componentDidMount() {
        this.myRef.current.scrollTo(0, 0);
    }

    getContent = () => this.props.children ?? <></>
    getHeader = () => this.props.showHeader || isAuth() ?
        <Header title={this.props.title} showBackButton={this.props.showBackButton}/> : ''
    getFooter = () => this.props.showFooter || isAuth() ? <Footer title={this.props.title}/> : ''
    getMT = () => "pt-sm-0 mt-sm-0 pb-7 " + (this.props.showHeader || isAuth() ? "mt-7" : '')
    getWrap = (c) => <Container fluid className={this.getMT()}>
        <Row className="justify-content-between">
            {isAuth() ?
                <Col className="bg-gradient-light p-0 d-sm-block d-none" align="center" xs={4} lg={3} tag="aside">
                    <Sidebar/>
                </Col> :
                <Col className="py-5" align="center" sm={4} xl={6} md={5} lg={5}>
                    <Link to='/'><img src={LogoTurnera} alt="LogoTurnera" id="LogoTurnera" className="d-block"/>
                    </Link>
                </Col>
            }
            <Col sm={{size: 8}} md={{size: 7}} lg={{size: 6}} xl={{size: 5}}
                 className="p-md-5 box-shadow mr-lg-5 my-sm-5 border-dark" id="">
                {c}
            </Col>
        </Row>
    </Container>

    render() {

        return <div className={"background " + this.props.country} ref={this.myRef}>
            {this.getHeader()}
            {this.getWrap(this.getContent())}
            {this.getFooter()}
        </div>

    }
}


const mapStateToProps = state => {
    return {
        data: state.AppReducer || null,
        country: _.get(state.ProfileReducer, 'city.country.iata', 'cencosud').toLowerCase(),
    }
};

VirtualQueueLayout.propTypes = {
    showHeader: PropTypes.bool.isRequired,
    showFooter: PropTypes.bool.isRequired,
    title: PropTypes.string
}
VirtualQueueLayout.defaultProps = {
    showHeader: true,
    showFooter: false,
    title: process.env.REACT_APP_TITLE,
    showBackButton: false
}

export default connect(mapStateToProps
)(VirtualQueueLayout);
