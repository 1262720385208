import * as Action from '../constants/ReducerActions/App'
import InitialState from "../constants/InitialState/App";

const AppReducer = (state = InitialState, {type, payload}) => {
    switch (type) {
        case Action.APP_START :
            return {...state, load: true};
        case Action.APP_END:
            return {...state, load: false};
        case Action.APP_RESET:
            return {...InitialState};
        case Action.APP_TITLE:
            return {...state, title: payload};
        case Action.APP_FORCE_HEADER    :
            return {...state, forceHeader: payload};
        case Action.APP_FORCE_FOOTER    :
            return {...state, forceFooter: payload};
        case Action.APP_ASIDE_LOGO    :
            return {...state, sideLogo: payload};
        case Action.APP_HEADER_LOGO    :
            return {...state, headerLogo: payload};
        default:
            return state;
    }
}

export default AppReducer;
