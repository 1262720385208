import * as Action from '../constants/ReducerActions/OAuth'
import InitialState from "../constants/InitialState/OAuth";
import _ from 'lodash'

const OAuthReducer = (state = InitialState, {type, payload}) => {
    switch (type) {
        case Action.OAUTH_RESET:
            return {...InitialState};
        case Action.OAUTH_ORIGIN:
            return {...state, referer_url: payload};
        case Action.OAUTH_SCREEN_LOAD:
            return {...state, waiting: true, ...payload};
        case Action.OAUTH_LOGIN:
            return {...state, waiting: true, data: {}, ...payload};
        case Action.OAUTH_SET_PROFILE:
            return {...state, raw: payload};
        case Action.OAUTH_SET_REDIRECT_URL:
            return {...state, redirect_to: payload}
        case Action.OAUTH_PARSE_GOOGLE_PROFILE:
            const profile = payload.getBasicProfile();

            return {
                ...state, data: {
                    first_name: profile.getGivenName(),
                    last_name: profile.getFamilyName(),
                    email: profile.getEmail(),
                    username: profile.getEmail(),
                    social_id: profile.getId(),
                }
            };
        case Action.OAUTH_PARSE_FACEBOOK_PROFILE:
            const facebook_profile = {
                social_id: _.get(payload, 'id'),
                username: _.get(payload, 'email'),
                ...(_.pick(payload, ['first_name', 'last_name', 'email']))
            };

            return {
                ...state, data: facebook_profile
            };
        case Action.OAUTH_LOGIN_DONE:
            return {...state, waiting: false};
        default:
            return state;
    }
}
export default OAuthReducer;
