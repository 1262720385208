import {Button} from "reactstrap";
import React from "react";
import {withRouter} from "react-router-dom"
import PropTypes from 'prop-types';

const propTypes = {
    active: PropTypes.bool,
    'aria-label': PropTypes.string,
    color: PropTypes.string,
    disabled: PropTypes.bool,
    children: PropTypes.node,
    className: PropTypes.string,
    href: PropTypes.string,
    cssModule: PropTypes.object,
    close: PropTypes.bool,
};

class DButton extends React.Component {

    back = () => window.history.back()

    render = () => {
        const history = this.props.history;
        const {href, ...args} = this.props
        return <Button {...args}
                       onClick={() =>href === "back" ? this.back() : history.push(href,{...args.navigateState})}
        >{this.props.children}</Button>
    }

}

DButton.defaultProps = {
    color: "primary",
    block: true,
    outline: false,
    className: "mt-3"
}
DButton.propTypes = propTypes

export default withRouter(DButton)
