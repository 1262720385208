import {UsernameField, PasswordField} from "./Fields";

const LoginForm = {
    'name': 'Login',
    'title': 'Entra a Turnera',
    // 'description': 'Agenda tu visita al mall y salta la fila de tus tiendas favoritas con Turnos Cencosud',
    'fields': {
        'username': UsernameField,
        'password': {...PasswordField},
    },
    'buttons': {
        'submit': {
            'type': 'submit',
            'block': true,
            'color': 'primary',
            'label': 'Ingresar'

        }
    }

}

export default LoginForm
