import ApiLocations from '../api/ApiLocations'
import * as Actions from '../constants/ReducerActions/Locations'

const LocationsAction = {
    initAction: () => {
        return { type: Actions.LIST }
    },
    listSuccess: (response) => {
        return { type: Actions.LIST_DONE, payload: response || null }
    },
    listErrors: (response) => {
        return { type: Actions.LIST_ERROR, payload: response || null }
    },
    all: () => (dispatch, getState) => (new Promise(function (resolve, reject) {
        ApiLocations
            .all()
            .then(response => {
                dispatch(LocationsAction.listSuccess(response.data || []))
                resolve(response.data)
            })
            .catch(error => {
                dispatch(LocationsAction.listErrors(error))
                reject(error)
            })
            ;
        dispatch(LocationsAction.initAction())
    }))
};

export default LocationsAction
