import Inflector from "../../Inflector";
import {Col, Row} from "reactstrap";
import FormButton from "./FormButton";
import React from "react";

const renderOneButton = (props, button, i) => {

    const maybeEvent = button.onClick || props['on' + Inflector.classify(button.name || '')];
    switch (button.type || 'submit') {
        case 'reset':
            button.onClick = maybeEvent || props.onReset || (e => e.preventDefault());
            break;
        case 'submit':
            button.onClick = undefined;
            break;
        default:
            button.onClick = maybeEvent || props.onSubmit || (e => e.preventDefault());
            break;

    }

    let {label, children, ...b} = button

    return <Col key={Inflector.classify('col_button_' + i)}>
        <FormButton {...b} >{children ?? label}</FormButton>
    </Col>;
}

const renderButtons = (props, buttons) => {
    const buttons_rendered = [];
    for (let i in buttons) {
        buttons_rendered.push(renderOneButton(props, buttons[i], i));
    }
    return <Row>{buttons_rendered}</Row>
}

export default renderButtons;
