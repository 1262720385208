import React from 'react';
import Succeed from './Succeed'
import config from "../../configReactApp";
import animatedCheck from '../../assets/img/animated_check.gif';

function AnimatedSucceed({booking, btnDesc}) {
    return (<Succeed
                icon={false}
                title="¡Has realizado tu check in!"
                btnDesc={btnDesc ? btnDesc : "Finalizar"}
                btnLink={config('app.homesite')}>
                    <img src={animatedCheck} alt="confirmed" style={{width: '70%'}} />
            </Succeed>
    );
}

export default AnimatedSucceed;
