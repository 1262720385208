import React, { Component } from 'react';
import ListItem from "components/Lists/ListItem"
import { connect } from 'react-redux';
import { SCREEN_LOAD } from 'constants/ReducerActions/Store'
import StoreAction from 'actions/Store';

class StoreView extends Component {
    constructor(props) {
        super(props);
        props.onInit();
    }

    render() {
        const args = {
            name: 'Store',
            data: this.props.data.list ? this.props.data.list : {}
        };

        return <ListItem {...args}/>
    }
}

const mapStateToProps = state => {

    return {
        data: state.StoreReducer || null
    }
};

const mapDispatchToProps = (dispatch, getState) => {
    const Loaders = [
        StoreAction.all()
    ];
    Loaders.forEach(dispatch);

    return {
        onInit: () => dispatch({
            type: SCREEN_LOAD
        })
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(StoreView);

