import Api from './Api'
import configReactApp from "../configReactApp";

const itemsPerPage = function() {
    const v = parseInt(configReactApp('api.store_items_per_page'));
    if (isNaN(v) || v <= 0) {
        return null;
    }

    return v;
}();

class _ApiCity extends Api {

    stores(id, config, page) {
        const data = { limit: itemsPerPage };
        if (page) {
            data.page = page;
        }
        return this.get([id, 'stores'], data, config);
    }

    children_stores(city_id, parent_id, page) {
        const data = { parent_id, limit: itemsPerPage };
        if (page) {
            data.page = page;
        }
        return this.get([city_id,'stores'], data);
    }
}

const endpoint = configReactApp('api.endpoint.city');
const ApiCity = new _ApiCity({endpoint: endpoint});
export default ApiCity


