import React from 'react';
import { Media, Row, Col } from 'reactstrap';
import LoadImage from '../Branding/Logo';
import { withRouter } from "react-router-dom"
import moment from 'moment';
import 'moment/locale/es';
import { isQueueEnabled } from "../../helper";
import _ from 'lodash';

moment.locale('es');

const imgStyle = {
    maxHeight: '84px',
    maxWidth: '84px',
}

class QueueItem extends React.Component {

    render() {

        const queueItem = this.props.item;
        const queue = queueItem.queue;
        const store = queue.store;
        const to = "queue/" + queueItem.id;
        const logo = LoadImage(store?.brand?.logo);

        const turno = _.get(queueItem,'iid',0);

        const actual = _.get(queueItem,'queue.actual',0)

        const people_ahead = turno - actual;

        return (
            <Media style={{ opacity: !isQueueEnabled(queueItem) ? '40%' : '100%' }}
                onClick={() => {
                    if (isQueueEnabled(queueItem)) this.props.history.push(to)
                }}>
                <Media left middle>
                    <Media object
                        className="mr-2 mt-2"
                        style={imgStyle}
                        src={logo}
                        alt={store.name}
                    />
                </Media>
                <Media body>
                    <Media heading>{store.name}</Media>
                    <Row>
                        <Col><p style={{ marginBottom: 0 }}>Tu turno es el <strong>{turno}</strong> </p></Col>
                    </Row>
                    <Row>
                        <Col><p style={{ marginBottom: 0 }}>{people_ahead === 0 ? "Eres el próximo" : <span>Hay <strong>{people_ahead}</strong> personas esperando</span> }</p></Col>
                    </Row>
                    
                </Media>
            </Media>
        );

    }
}

export default withRouter(QueueItem)
