import React from 'react'
import {Alert, Col, Container, Row} from 'reactstrap'
import PropTypes from 'prop-types';
import {DefaultButton} from "./BootstrapParameters";
import Inflector from 'Inflector'
import renderInputs from './RenderInputs'
import renderButtons from "./RenderButtons";

class FormTag extends React.Component {
    constructor(props) {
        super(props);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleReset = this.handleReset.bind(this);
        // this.renderButtons = this.renderButtons.bind(this);
    }


    renderErrors() {
        let error;
        if (this.props.errorMessage) {
            error = this.props.errorMessage;
        } else if (this.props.errors && this.props.errors.message) {
            error = this.props.errors.message;

        }
        return error ? <Alert color="danger" className="font-weight-bold"
                              style={{borderRadius: '0.5rem'}}>{error}</Alert> : '';
    }

    handleSubmit(event) {

        this.props.onSubmit(event);
    }

    handleReset(event) {
        this.props.onReset(event);
    }

    getIdentification(name) {
        const i = Inflector.classify(this.props.name + '_' + name);
        return i;
    }

    renderTitle() {
        const t = this.props.title ? this.props.title : Inflector.humanize(this.props.name);
        const {text, ...o} = ((typeof (t) === 'object') ? t : {text: t});
        return <Row key={this.getIdentification('title')}><Col><h2 {...o}>{text}</h2></Col></Row>

    }


    render() {

        const {
            footer,
        } = this.props;
        // console.log(this.props)
        const fieldSet = renderInputs(this.props);
        const buttons = renderButtons(this.props, this.props.buttons);
        const title = this.props.title && this.renderTitle();
        const errorMessage = this.renderErrors();
        const subtitle = this.props.subtitle &&
            <Row key={this.getIdentification('subtitle')} className="pt-3 pb-4"><Col><h5>{this.props.subtitle}</h5>
            </Col></Row>;

        const description = this.props.description &&
            <Row key={this.getIdentification('description')} className="pt-1 pb-4"><Col>{this.props.description}</Col></Row>;

        return <form onSubmit={this.handleSubmit} id={this.getIdentification(this.props.name)}>
            <Container key={this.getIdentification('container')} fluid={true}>
                {title}
                {subtitle}
                {description}
                {errorMessage}
                {this.props.children}
                {fieldSet}
                {footer}
                {buttons}
            </Container>
        </form>
    }
}

FormTag.propTypes = {
    name: PropTypes.string.isRequired,
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.bool, PropTypes.object]),
    subtitle: PropTypes.string,
    between: PropTypes.string,
    errorMessage: PropTypes.string,
    onChange: PropTypes.func,
    onSubmit: PropTypes.func,
    onReset: PropTypes.func,
    fields: PropTypes.object.isRequired,
    buttons: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
    inline: PropTypes.bool,
    data: PropTypes.object.isRequired,
    footer: PropTypes.oneOfType([PropTypes.string, PropTypes.bool, PropTypes.object]),
    description: PropTypes.oneOfType([PropTypes.elementType, PropTypes.string])
};
FormTag.defaultProps = {
    name: false,
    title: false,
    subtitle: '',
    errorMessage: '',
    fields: [],
    inline: true,
    buttons: [[DefaultButton]],
    data: {}
};
export default FormTag
