import {DNI_DISPLAY_NAME} from '../../helper';
import {CityId, CountryId, PhoneField} from "./Fields";

const LoginFulfillmentForm = {
    'name': 'SocialLogin',
    'title': {'text': 'Complete los siguientes datos para continuar'},
    'description': 'Agenda tu visita al mall y salta la fila de tus tiendas favoritas con Turnos Cencosud',
    'fields': {
        'country_id': CountryId,
        'city_id': CityId,
        'identity_value': {
            'label': 'Documento de Identidad',
            'placeholder': 'Ingrese el número aquí',
            'type': 'input-group-dropdown',
            'selectedValue': DNI_DISPLAY_NAME,
            'required': true,
            'dropdown': {
                'identity_id': {
                    'label': 'Tipo de documento',
                }
            }
        },
        'phone': PhoneField
    },
    'buttons': {
        'submit': {
            'type': 'submit',
            'block': true,
            'color': 'primary',
            'label': 'Continuar'

        }
    }

}

export default LoginFulfillmentForm
