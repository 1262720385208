import React, {Component} from 'react';
import FormTag from "components/Forms/FormTag";
import {connect} from 'react-redux'
import {Col, Container, CustomInput, FormGroup, Input, Label, Row} from 'reactstrap';
import {EDIT_FORM, SUBMIT} from 'constants/ReducerActions/Booking'
import BookingAction from "../../actions/Booking";
import StoreTimeAction from "../../actions/StoreTime";
import BookingEditForm from 'constants/Forms/BookingEdit'
import {InputEvts, toOptions} from "../../components/Forms/Utils";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheck} from "@fortawesome/free-solid-svg-icons";
import {withRouter} from 'react-router-dom'
import {SET_STORE} from "../../constants/ReducerActions/StoreTime";
import config from "../../configReactApp";
import Waiting from "../../components/Waiting/Waiting";
import InfoAlert from "../../components/Alert/InfoAlert";
import StoreAction from '../../actions/Store';
import StoreItem from '../../components/Lists/StoreItem';
import Inflector from "Inflector";
import {SET_BOOKING} from 'constants/ReducerActions/BookingDetail'
import _ from "lodash"


const getStoreId = (props) => {
    const query = props.location.search;
    return query.substring(query.indexOf('store_id') + 9);
}

const person_count = {
    'label': '¿Con cuántas personas?',
    'value': 'persons_count',
    'type': 'dropdown',
    'items': ['1', '2', '3'],
    'required': true,
    e: new InputEvts()
}

class BookingEditView extends Component {

    state = {
        accompanied: false
    }

    componentDidMount() {
        const {booking} = this.props.match.params
        this.props.onInit(booking, getStoreId(this.props));
    }

    isNotEmptyBookingList = () => {
        return this.props.data && Object.values(this.props.dateList).length > 0 && Object.values(this.props.allStoresTimes).length > 0
    }

    isDateFullyBooked = (date) => {
        return Object.values(this.props.allStoresTimes[date]).every(val => val.fully_booked);
    }

    isNotFullyBooked = () => {
        return !Object.values(this.props.dateList).every(val => this.isDateFullyBooked(val.id))
    }

    handleSubmitEditBooking = (e) => {
        e.preventDefault();
        const {booking} = this.props.match.params
        this.props.onSubmit(booking).then(response => {
            if (response.status === 201 || response.status === 200) {
                this.props.history.replace(config('app.booking.after_create').replace(':booking', response.data.id))
            }
        });

    }

    prepare() {
        this.args = {
            ...BookingEditForm,
            onChange: this.props.onChange,
            onSubmit: this.handleSubmitEditBooking,

            inline: false,
        };
        const date_on_events = new InputEvts();
        date_on_events.add('change', this.props.onChange);
        date_on_events.add('change', this.props.onDateChange);
        this.args.fields.date.items = toOptions(this.props.dateList);
        this.args.fields.date.e = date_on_events;
        this.args.fields.store_time_id.items = toOptions(this.props.storeTimeList);
        const selectedDate = Object.entries(this.props.allStoresTimes || [])?.find(([key, value]) => value?.find(v => v.key === this.props.data.store_time_id));
        if (selectedDate) {
            this.args.fields.date.selectedValue = selectedDate[0];
        }
        // this.args.buttons.submit.label = <Row><Col>Confirmar</Col><Col><FontAwesomeIcon icon={faCheck}/></Col></Row>;
        this.args.buttons.submit.label = <>Confirmar <FontAwesomeIcon icon={faCheck}/></>;

        return this.args
    }

    render() {

        const args = this.prepare();
        let content;
        if (this.isNotEmptyBookingList() && this.isNotFullyBooked()) {
            content = <FormTag name='NewBooking' {...args}
                               data={this.props.data} errors={this.props.errors} footer={this.props.data.can_invite ?
                <>
                    <FormGroup>
                        <Label>¿Vienes acompañado?</Label>
                        <div>
                            <CustomInput type="radio" id="accompained_yes" label="Si" name="accompained"
                                         onClick={() => {
                                             this.props.onPersonsChange("1")
                                             this.setState({accompanied: true})
                                         }}/>
                            <CustomInput checked={!this.state.accompanied} type="radio" id="accompained_no" label="No"
                                         name="accompained" onClick={() => {
                                this.props.onPersonsChange("0")
                                this.setState({accompanied: false})
                            }}/>
                        </div>

                    </FormGroup>
                    {this.state.accompanied && <>
                        <fieldset>{person_count.label}</fieldset>
                        <Input onChange={(e) => this.props.onPersonsChange(e.currentTarget.value)}
                               type="select">{toOptions(person_count.items).map(o => {
                            const id = o.value === this.props.placeholder ? 'Placeholder' : Inflector.classify(o.key.toString());
                            const input = {
                                id: Inflector.classify("persons") + id,
                                value: o.key,
                                key: o.key

                            };

                            return <option {...input}>{o.value}</option>
                        })}</Input></>}
                </> : null
            }/>
        } else {
            content = <InfoAlert text="No hay reservas disponibles"/>
        }

        return <Waiting waiting={this.props.waiting} waiting_alt={this.props.waiting_alt}>
            <h2 className="d-none d-sm-block">Seleccione Día y Hora</h2>
            <Container fluid>
                <Row>
                    <Col className="pb-3 mb-4 pl-2 border-bottom"><StoreItem key={this.props.store.id}
                                                                             item={this.props.store}/></Col>
                </Row>
                <Row><Col>
                    {content}
                </Col></Row>
            </Container>
        </Waiting>
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        errors: state.BookingReducer.errors || null,
        data: state.BookingReducer.data || null,
        waiting: state.StoreTimeReducer.waiting || null,
        waiting_alt: state.BookingReducer.waiting || null,
        dateList: state.StoreTimeReducer.dates || [],
        storeTimeList: state.StoreTimeReducer.list || [],
        allStoresTimes: state.StoreTimeReducer.all || null,
        store: Object.values(state.StoreReducer.all || {})?.find(s => s.id?.toString() === getStoreId(ownProps)?.toString()) || {
            id: 0, 
            name: "",
            street: "",
            street_number: "",
            suburb: "",
            state: "",
        }
    }
};

const mapDispatchToProps = (dispatch, getState) => {
    return {
        onInit: (booking, store_id) => {
            dispatch({
                type: SET_STORE,
                payload: store_id
            });
            dispatch({
                type: SET_BOOKING,
                payload: booking
            });
            dispatch(StoreAction.calendar(store_id)).then((response) => {
                let calendar_id = _.get('response.data[0].id');
                dispatch(BookingAction.initEdit());
                dispatch({
                    type: EDIT_FORM,
                    payload: {
                        name: "calendar_id",
                        value: calendar_id,
                    }
                });
                let can_invite = _.get('response.data[0].calendar_type.can_invite');
                dispatch({
                    type: EDIT_FORM,
                    payload: {
                        name: "can_invite",
                        value: can_invite,
                    }
                })
                dispatch({
                    type: EDIT_FORM,
                    payload: {
                        name: "calendar_id",
                        value: response.data ? response.data[0]?.id : undefined,
                    }
                });
            });
            // To retrieve the store information if the stores have not been retrieved yet
            dispatch(StoreAction.get(store_id))
        },
        onChange: e => dispatch({
            type: EDIT_FORM,
            payload: {
                name: e.target.name,
                value: e.target.value,
                type: e.target.type,
                files: e.target.files
            }
        }),
        onSubmit: booking => {
            BookingAction.FetchRequest = SUBMIT;
            return dispatch(BookingAction.edit(booking));
        },
        setPending: () => {
            dispatch(BookingAction.setPendingSaveOnLogin())
        },
        onPersonsChange: (persons) => dispatch({
            type: EDIT_FORM,
            payload: {
                name: "invites",
                value: Number(persons)
            }
        }),
        onDateChange: e => {
            e.preventDefault();
            dispatch(StoreTimeAction.selectDate(e.target.value));
        },
    };
};

export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(BookingEditView));

