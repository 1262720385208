import React, { Component } from 'react';
import FormTag from "components/Forms/FormTag";
import {connect} from "react-redux";
import ChangePasswordForm from 'constants/Forms/ChangePassword'
import { Container } from 'reactstrap'
import ChangePasswordAction from "../../actions/ChangePassword"
import { withRouter } from "react-router-dom"
import config from 'configReactApp'
import { EDIT_FORM, CLEAR_FORM } from 'constants/ReducerActions/ChangePassword'
import { GoBackButton } from '../../components/Buttons'

class ChangePasswordView extends Component {
    args = {};

    constructor(props) {
        super(props);
        props.clearForm()
    }

    //validateData = () => Object.keys(ChangePasswordForm.fields).every(field => !ChangePasswordForm.fields[field].required || (ChangePasswordForm.fields[field].required && this.state[field]))

    changePassword = (e) => {
        this.props.onSubmit(e).then(response => {
            if (response.status === 200) {
                this.props.history.push(config("app.succeed_change_password"))
            }
        }).catch((err) => console.log(err))

    }

    prepare() {
        this.args = {
            ...ChangePasswordForm,
            onChange: this.props.onChange,
            onSubmit: this.changePassword,
            inline: false,
        };
    }

    render() {
        this.prepare();
        return (
            <>
                <FormTag name='ChangePassword' {...this.args} data={this.props.data} />
                <Container className="my-4"><GoBackButton /></Container>
            </>
        )
    }
}

const mapStateToProps = state => {
    return {
        data: state.ChangePasswordReducer || null,
    }
};

const mapDispatchToProps = (dispatch, getState) => {
    return {
        clearForm: () => {
            dispatch({ type: CLEAR_FORM })
        },
        onChange: e => dispatch({
            type: EDIT_FORM,
            payload: {
                name: e.target.name,
                value: e.target.value,
                type: e.target.type,
                files: e.target.files
            }
        }),
        onSubmit: e => {
            e.preventDefault();
            return dispatch(ChangePasswordAction.changePassword(dispatch, getState, e))

        }
    };
};


export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(ChangePasswordView));
