import InitialState from "../constants/InitialState/Settings";
import * as Action from '../constants/ReducerActions/Settings';
import config from "../configReactApp";

let dashboardRoute = null;

const SettingsReducer = (state = InitialState, {type, payload}) => {
    switch (type) {

        case Action.SET_VIRTUAL_QUEUE:
            dashboardRoute = config('app.virtualQueue.search') + '?parent_id=' + payload;
            return {...state, dashboardRoute, isVirtualQueue: true, parentStoreId: payload};
        case Action.UNSET_VIRTUAL_QUEUE:
            dashboardRoute = InitialState.dashboardRoute;
            const { isVirtualQueue, parentStoreId } = InitialState;
            return {...state, dashboardRoute, isVirtualQueue, parentStoreId};
        default:
            return state;
    }
}

export default SettingsReducer;
