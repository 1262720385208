import Api from './Api'
import config from 'configReactApp'

class _ApiCountry extends Api {

    id = 0;

    setCountry(id) {
        this.id = id;
        return this;
    }

    cities(id) {
        return this.get([id || this.id, 'cities'].join('/'))
    }
}

const ApiCountry = new _ApiCountry({endpoint: config('api.endpoint.country')});
export default ApiCountry


