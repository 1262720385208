export const SCREEN_LOAD = "REGISTER_SCREEN_LOAD";

// CREAR REGISTRO
export const EDIT_FORM = "REGISTER_EDIT_FORM";
export const SUBMIT = "REGISTER_SUBMIT";
export const SUBMIT_DONE = "REGISTER_SUBMIT_DONE";
export const SUBMIT_ERROR = "REGISTER_SUBMIT_ERROR";

// ANULAR REGISTRO
export const CANCEL = "REGISTER_CANCEL";
export const CANCEL_DONE = "REGISTER_CANCEL_DONE";
export const CANCEL_ERROR = "REGISTER_CANCEL_ERROR";

// MODIFICAR REGISTRO
export const UPDATE = "REGISTER_UPDATE";
export const UPDATE_DONE = "REGISTER_UPDATE_DONE";
export const UPDATE_ERROR = "REGISTER_UPDATE_ERROR";