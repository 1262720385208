import * as ReducerActions from '../constants/ReducerActions/ResetPassword'
import ApiResetPassword from "api/ApiResetPassword";

const ResetPasswordAction = {
    FetchRequest: '',
    initAction: () => {
        return {type: ResetPasswordAction.FetchRequest}
    },

    validate: () => {
        return true;
    },

    save: () => (dispatch, getState) =>
        new Promise(function (resolve, reject) {
                let {errors, waiting, ...data} = {...getState().ResetPasswordReducer};
                if (ResetPasswordAction.validate(data)) {
                    ApiResetPassword
                        .post('', data)
                        .then(response => {
                            dispatch(ResetPasswordAction.success(response))
                            resolve(response)
                        })
                        .catch(error => {
                            console.log(error)
                            if (error.response) {
                                dispatch(ResetPasswordAction.showErrors(error.response.data))
                                reject(error.response.data)
                            } else {
                                dispatch(ResetPasswordAction.showErrors(error))
                                reject(error)
                            }
                        })
                    ;
                    dispatch(ResetPasswordAction.initAction())
                } else {
                    dispatch(ResetPasswordAction.showErrors())
                }
            }
        ),

    showErrors: (response) => {
        return {type: ReducerActions.SUBMIT_ERROR, payload: response || null}
    },

    success: (response) => {
        return {type: ReducerActions.SUBMIT_DONE, payload: response.data || null};
    },

};

export default ResetPasswordAction
