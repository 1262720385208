import * as Action from '../constants/ReducerActions/LoginFulfillment'
import InitialState from '../constants/InitialState/LoginFulfillment'

const LoginFulfillmentReducer = (state = InitialState, {type, payload}) => {
    switch (type) {
        case Action.RESET:
            return {...InitialState}
        case Action.SCREEN_LOAD:
            return {...InitialState, ...payload};
        case Action.SET_IDENTITY_ID :
            return {...state, identity_id: payload};
        case Action.EDIT_FORM :
            return {...state, [payload.name]: payload.value};
        case Action.SUBMIT :
            return {...state, waiting: true};
        case Action.SUBMIT_DONE    :
            return {...state, waiting: false, ...payload};
        case Action.SUBMIT_ERROR   :
            return {...state, waiting: false, errors: {...payload}};
        case Action.UPDATE_ERROR:
            return {...state, errors: {errors: {...state.errors?.errors, ...payload}}};
        default:
            return state;
    }
};

export default LoginFulfillmentReducer;
