import React from 'react';
import config from 'configReactApp'
import OAuthActions from "../../actions/OAuth";
import {LoginSocialAmazon, LoginSocialFacebook, LoginSocialGithub, LoginSocialGoogle, LoginSocialInstagram, LoginSocialLinkedin, LoginSocialMicrosoft} from "reactjs-social-login";
import {AmazonLoginButton, FacebookLoginButton, GithubLoginButton, GoogleLoginButton, InstagramLoginButton, LinkedInLoginButton, MicrosoftLoginButton} from "react-social-login-buttons";
import {useDispatch} from "react-redux";
import {useHistory, useLocation} from "react-router-dom";
import * as Actions from "../../constants/ReducerActions/OAuth";
import {OAUTH_LOGIN_DONE} from "../../constants/ReducerActions/OAuth";
import {parseLocationToURI} from "../../helper";
import {needsFulfillment} from "../../isAuth";

const REDIRECT_URI = config('api.host') + config('app.oauth_callback_google');

const dprops = {
    onResolve: data => console.log(data),
    onReject: (err) => console.error(err),
    language: 'es_CL',
    className: "social-network-login-button"
}
const dprops_redirect = {...dprops, redirect_uri: REDIRECT_URI}
const after_callback = ({response, redirectTo, params}, history) => (dispatch, getState) => {
    const needs_fulfillment = needsFulfillment();
    console.log(needs_fulfillment)
    history.replace(needs_fulfillment ? config('app.login_fulfillment') : redirectTo, params);
    dispatch({type: OAUTH_LOGIN_DONE});

};
const catch_callback = e => console.error(e);

export const LoginFacebook = (props) => {
        const dispatch = useDispatch();
        const history = useHistory();
        const location = useLocation();
        const callback = OAuthActions.processFacebook;
        return <LoginSocialFacebook
            {...dprops}
            appId={config('social.facebook_client_id')}
            fieldsProfile="id,name,first_name,last_name,email"
            onResolve={({provider, data}) => {
                dispatch({type: Actions.OAUTH_ORIGIN, payload: parseLocationToURI(location)});
                history.replace(config('app.social_login_callback'));
                console.log(config('app.social_login_callback'))
                dispatch(callback(provider, props.identity_id, data)).then((response) => dispatch(after_callback(response, history))).catch(catch_callback);
            }}
        >
            <FacebookLoginButton className="social-network-login-button"><span>facebook</span></FacebookLoginButton>
        </LoginSocialFacebook>
    }
;
export const LoginGoogle = (props) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const location = useLocation();
    const callback = OAuthActions.processGoogle;

    return <LoginSocialGoogle
        {...dprops}
        // scope="openid"
        // ux_mode="redirect"
        // fetch_basic_profile={false}
        client_id={config('social.google_client_id') || ''}
        onResolve={(({provider, data}) => {
            dispatch({type: Actions.OAUTH_ORIGIN, payload: parseLocationToURI(location)});
            history.replace(config('app.social_login_callback'));
            dispatch(callback(provider, props.identity_id, data)).then((response) => dispatch(after_callback(response, history))).catch(catch_callback);
        })}
    >
        <GoogleLoginButton className="social-network-login-button"><span>Google</span></GoogleLoginButton>
    </LoginSocialGoogle>
};

export const LoginAmazon = (props) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const callback = OAuthActions.processFacebook;
    return <LoginSocialAmazon
        {...dprops}
        client_id={config('social.amazon_client_id') || ''}
        onResolve={({provider, data}) => {
            history.replace(config('app.social_login_callback'));
            dispatch(callback(provider, props.identity_id, data)).then((response) => dispatch(after_callback(response, history))).catch(catch_callback);
        }}
    >
        <AmazonLoginButton className="social-network-login-button"><span>Amazon</span></AmazonLoginButton>
    </LoginSocialAmazon>

};
export const LoginInstagram = (props) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const callback = OAuthActions.processFacebook;
    return <LoginSocialInstagram
        {...dprops_redirect}
        client_id={config('social.instagram_client_id') || ''}
        onResolve={({provider, data}) => {
            history.replace(config('app.social_login_callback'));
            dispatch(callback(provider, props.identity_id, data)).then((response) => dispatch(after_callback(response, history))).catch(catch_callback);
        }}
    >
        <InstagramLoginButton className="social-network-login-button"><span>Instagram</span></InstagramLoginButton>
    </LoginSocialInstagram>

};
export const LoginMicrosoft = (props) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const callback = OAuthActions.processFacebook;
    return <LoginSocialMicrosoft
        {...dprops_redirect}
        client_id={config('social.microsoft_client_id') || ''}
        onResolve={({provider, data}) => {
            history.replace(config('app.social_login_callback'));
            dispatch(callback(provider, props.identity_id, data)).then((response) => dispatch(after_callback(response, history))).catch(catch_callback);
        }}
    >
        <MicrosoftLoginButton className="social-network-login-button"><span>Microsoft</span></MicrosoftLoginButton>
    </LoginSocialMicrosoft>;

};
export const LoginLinkedin = (props) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const callback = OAuthActions.processFacebook;
    return <LoginSocialLinkedin
        {...dprops_redirect}
        client_id={config('social.linkedin_client_id') || ''}
        onResolve={({provider, data}) => {
            history.replace(config('app.social_login_callback'));
            dispatch(callback(provider, props.identity_id, data)).then((response) => dispatch(after_callback(response, history))).catch(catch_callback);
        }}
    >
        <LinkedInLoginButton className="social-network-login-button"><span>Linkedin</span></LinkedInLoginButton>
    </LoginSocialLinkedin>

};
export const LoginGithub = (props) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const callback = OAuthActions.processFacebook;
    return <LoginSocialGithub
        {...dprops_redirect}
        client_id={config('social.github_client_id') || ''}
        onResolve={({provider, data}) => {
            history.replace(config('app.social_login_callback'));
            dispatch(callback(provider, props.identity_id, data)).then((response) => dispatch(after_callback(response, history))).catch(catch_callback);
        }}
    >
        <GithubLoginButton className="social-network-login-button"><span>Github</span></GithubLoginButton>
    </LoginSocialGithub>
};
