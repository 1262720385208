import Alert from "./Alert"

class WarningAlert extends Alert{}

WarningAlert.defaultProps = {
    ...Alert.defaultProps,
    color: "warning",
}

export default WarningAlert
