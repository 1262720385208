import React, {Component} from 'react';
import {connect} from 'react-redux';
import BookingDetailView from './BookingDetailView'

class SucceedBookingView extends Component {

    render() {

        return <BookingDetailView title={"Reserva Confirmada!"}/>

    }
}

const mapStateToProps = state => {

    return {
        booking: state.BookingReducer || null,

    }
};
const mapDispatchToProps = (dispatch, getState) => {
    return {
        onInit: () => {
        }
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(SucceedBookingView)
