export const UsernameField = {
    'label': 'E-mail o Teléfono',
    'value': 'email',
    'type': 'text',
    'placeholder': 'usuario@mail.com',
    'required': true,
    'maxLength': 320,
    /*
    Para usar validaciones utilizando expresiones regulares usar el campo regex. Completar el campo customValidateMessage con el
    mensaje que se desea mostrar en caso de que el texto no cumpla con la expresion. De lo contrario se mostrara un mensaje general.
    'regex': '^[0-9]+$',
    'customValidateMessage': 'Solo se aceptan numeros',
     */
}
export const EmailField = {
    'label': 'E-mail',
    'value': 'email',
    'type': 'email',
    'placeholder': 'usuario@mail.com',
    'required': true,
    'maxLength': 320,
    /*
    Para usar validaciones utilizando expresiones regulares usar el campo regex. Completar el campo customValidateMessage con el
    mensaje que se desea mostrar en caso de que el texto no cumpla con la expresion. De lo contrario se mostrara un mensaje general.
    'regex': '^[0-9]+$',
    'customValidateMessage': 'Solo se aceptan numeros',
     */
}
export const PasswordField = {
    'label': 'Contraseña',
    'value': 'password',
    'type': 'password',
    'placeholder': 'Clave',
    'required': true,
    'maxLength': 30,
}
export const PasswordConfirmationField = {
    'label': 'Repita contraseña',
    'type': 'password',
    'required': true,
    'maxLength': 30,
}


export const PhoneField = {
    'label': 'Teléfono móvil',
    'value': 'phone',
    'type': 'phone',
    'prefix': '+00',
    'placeholder': '11 9399 2322',
    'required': false,
    'description': 'Solo usaremos tu teléfono para comunicarnos en casos necesarios'
}

export const FirstName = {
    'label': 'Nombre',
    'placeholder': 'Ingrese su nombre',
    'required': true,
    'maxLength': 30
}

export const LastName = {
    'label': 'Apellido',
    'placeholder': 'Ingrese su apellido',
    'required': true,
    'maxLength': 30
}

export const CountryId = {
    'label': 'País',
    // 'placeholder': '- Selecciona tu país -',
    'value': null,
    'type': 'dropdown',
    'items': ['chile', 'argentina', 'brazil', 'Perú', 'colombia'],
    'required': true,
}


export const CityId = {
    'label': 'Ciudad',
    'value': 'Lima',
    'type': 'dropdown',
    'items': ['Santiago', 'Buenos Aires', 'Sao Pablo', 'Lima', 'Bogota'],
    'required': true,
}
