import React from "react";
import {FormGroup, Input, Label} from 'reactstrap';
import {Link} from "react-router-dom";
import {useSelector} from "react-redux";

export default (props) => {
    const defaultCountry = useSelector(state => Object.values(state.CountryReducer.list || {})[0]);
    // console.log(props)
    return <FormGroup className="py-4" check>
        <Label check>
            <Input name={props.name || 'accept_terms_and_conditions'} disabled={props.disabled} value={props.checked} type='checkbox' onChange={(value) => props.onValueChange(value.target.checked)}/>
            He leído y acepto los <Link target="_blank" to={`/terms-of-service/${props.country || defaultCountry?.id}`}>términos y condiciones</Link>
        </Label>
    </FormGroup>
}
