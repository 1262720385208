export const SCREEN_LOAD = "BOOKINGDETAIL_SCREEN_LOAD";

// Listar Tiendas disponibles
export const SET_BOOKING = "BOOKINGDETAIL_SET_BOOKING_ID";
export const GET = "BOOKINGDETAIL_GET";
export const GET_DONE = "BOOKINGDETAIL_GET_DONE";
export const GET_ERROR = "BOOKINGDETAIL_GET_ERROR";


// Listar Tiendas disponibles
export const LIST = "BOOKINGDETAIL_LIST";
export const LIST_DONE = "BOOKINGDETAIL_LIST_DONE";
export const LIST_ERROR = "BOOKINGDETAIL_LIST_ERROR";
