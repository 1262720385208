import React, {Component} from 'react';
import {connect} from 'react-redux';
import ListItem from "components/Lists/ListItem"
import {SCREEN_LOAD} from 'constants/ReducerActions/BookingList'
import BookingListAction from 'actions/BookingList';
import config from 'configReactApp';
import Waiting from "components/Waiting/Waiting";
import {Button, Col, Row} from "reactstrap";
import {withRouter} from "react-router-dom";
import BookingAction from 'actions/Booking';

class BookingListView extends Component {
    args = {};

    queueInfoTimer = null;

    country_id = this.props.profile.city.country_id;

    componentDidMount() {
        this.props.onInit(this.props.settings.isVirtualQueue);
        if (this.props.isVirtualQueue) {
            this.queueInfoTimer = setInterval(() => {
                this.props.getAllQueues()
            }, 30000)
        }
    }

    componentWillUnmount() {
        if (this.props.isVirtualQueue) {
            clearInterval(this.queueInfoTimer);
        }
    }

    // constructor(props) {
    //     super(props);
    //     props.onInit(props.settings.isVirtualQueue);
    // }

    prepare() {
        this.args = {
            name: 'BookingList',
            data: this.props.data ? Object.values(this.props.data) : [],
            queues: this.props.virtualQueues ? Object.values(this.props.virtualQueues) : [],
            link: config('app.booking.detail')
        };
    }

    render() {
        this.prepare();

        let output = [<h2 key="titulo" className="d-none d-sm-block">Mis Reservas</h2>];

        if (Object.keys(this.args.data).length === 0 && this.args.queues.length === 0) {
            output = [...output, <h6 key="subtitulo" className="my-3 my-sm-5">No tienes reservas.</h6>,
                <Row key="sin_reservas" style={{marginBottom: '1rem'}} className="mt-3 text-center">
                    { this.country_id === 3 
                        ?
                        <></>
                        :
                        <Col>
                            <Button color="primary" block onClick={() => this.props.history.push('/search')}>Reservar
                                turno</Button>
                        </Col>
                    }
                </Row>];
        } else {
            output = output.concat(<ListItem key="listado" {...this.args}/>);
        }

        return <Waiting waiting={this.props.waiting}>{output}</Waiting>;
    }
}

const mapStateToProps = state => {
    const waiting = state.SettingsReducer.isVirtualQueue ? state.VirtualQueueListReducer.waiting : state.BookingListReducer.waiting;
    return {
        waiting: waiting || false,
        data: state.BookingListReducer.list || [],
        virtualQueues: state.VirtualQueueListReducer.list || [],
        settings: state.SettingsReducer || {},
        profile: state.ProfileReducer || {}
    }
};

const mapDispatchToProps = (dispatch, getState) => {
    return {

        onInit: (isVirtualQueue) => {
            if (isVirtualQueue) {
                dispatch(BookingAction.getAllvirtualQueues());
            } else {
                dispatch({type: SCREEN_LOAD});
                dispatch(BookingListAction.all());
            }

        },
        getAllQueues: () => {
            dispatch(BookingAction.getAllvirtualQueues())
        }
    };
};

export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(BookingListView));

