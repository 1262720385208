/**
 * Created by carlosherrera on 11/7/17.
 */
export default class Inflector {

    constructor(obj) {
        this._this = obj;
    }


    camelize = () => {

        return this._this.replace(/[\s\W]/g, '_').split('_').map(function (e) {
            return e.charAt(0).toUpperCase() + e.substr(1)
        }).join('');
    };
    classify = () => {

        let x = this._this.replace(/]\[/g, '_').replace('[', '_').replace(']', '_').split(/[_\s]/).map(function (e) {
            return e.charAt(0).toUpperCase() + e.substr(1)
        }).join('');
        return x;
    };
    snakefy = () => {
        let x = this.classify().replace(/[A-Z]/g, function (x) {
            return '_' + x.toLowerCase();
        });
        if (x.charAt(0) === '_') {
            return x.substr(1);
        } else {
            return x;
        }

    };

    humanize = () => {
        let x = this.camelize().replace(/[A-Z]/g, function (x) {
            return ' ' + x;
        });
        return x.trim();

    };

    ucFirst = () => this._this.charAt(0).toUpperCase() + this._this.slice(1);

    ucWords = (minLength) => {
        let x = this._this.split(/[.\s]/);

        x = x.map(function (e) {
            return e.length >= (minLength ?? 3) ? Inflector.ucFirst(e) : e;
        });
        return x.join(' ');

    };

    dotToName = () => {
        let x = this._this.split(/[.\s]/);
        if (x.length > 1) {
            let r = x.shift();
            x = x.map(function (e) {
                return '[' + Inflector.snakefy(e) + ']';
            });
            x.unshift(r);
        }

        return x.join('');

    };

    static camelize(obj) {
        return new Inflector(obj).camelize();
    }

    static humanize(obj) {
        return new Inflector(obj).humanize();
    }

    static classify(obj) {
        return new Inflector(obj).classify();
    }

    static snakefy(obj) {
        return new Inflector(obj).snakefy();
    }

    static dotToName(obj) {
        return new Inflector(obj).dotToName();
    }

    static ucFirst(obj) {
        return new Inflector(obj).ucFirst();
    }

    static ucWords(obj, minLength) {
        return new Inflector(obj).ucWords(minLength);
    }
}



