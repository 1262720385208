import {mainMenu} from "../../routes";
import {filterMenu} from "../../helper";
import {Nav, NavItem, NavLink} from "reactstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import React from "react";
import {withRouter} from "react-router";
import ProfileResume from "./ProfileResume";
import {connect} from "react-redux";
import _ from 'lodash';

const menu_default_props = {color: "link", size: "lg", block: true, className: "text-left ml-2"}

class Sidebar extends React.Component {
    isCurrentPath = (menu_item) => window.location.pathname === menu_item.path;
    render = () => {
        return <div className="mb-4">
            <div className="border-bottom pb-3" id="ProfileMenu">{ProfileResume(this.props.profile)}</div>
            <Nav vertical className="shadow h5 font-weight-light">
                {filterMenu(mainMenu, this.props.settings.isVirtualQueue).map((menu, i) => {
                    const {label, icon, ...b} = {
                        ...menu,
                        ...menu_default_props,
                        onClick: () => this.props.history.push(menu.path)
                    };
                    const c = _.pick(b, 'className', 'styles', 'key', 'id', 'size', 'color', 'onClick');
                    return <NavItem key={i} className="my-2 btn btn-outline-primary"
                                    active={this.isCurrentPath(menu)}
                    >
                        {label === '_' ? <hr/> : <NavLink {...c}>
                            {icon ? <FontAwesomeIcon icon={icon} className="mr-2"/> : ''}
                            {label}
                        </NavLink>}
                    </NavItem>;
                })}
            </Nav>
        </div>
    }

}

const mapStateToProps = state => {
    return {
        profile: state.ProfileReducer || null,
        settings: state.SettingsReducer || {},
    }
};

export default withRouter(connect(mapStateToProps)(Sidebar));

