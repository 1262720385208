import React, {useEffect} from 'react'
import {BrowserRouter as Router, Redirect, Route, Switch, useHistory} from "react-router-dom";
import 'assets/css/turnera.css';
import {Provider, useSelector} from "react-redux"
import {persistor, store} from './ConfigureStore'
import InitAction from "./actions/InitAction";
import PublicHomeView from "./views/Public/PublicHomeView";
import DefaultLayout from "./layouts/DefaultLayout";
import appRoutes from "./routes";
import Error404View from "./views/Error404View";
import config from './configReactApp';
import {PersistGate} from 'redux-persist/integration/react'
import PublicLayout from "./layouts/PublicLayout";
import ReactGA from 'react-ga';
import {isAuth as Auth} from './isAuth';
import BookingRedirect from 'views/Booking/BookingRedirect';
import VirtualQueueLayout from "./layouts/VirtualQueueLayout";
import OAuthCallback from "views/Auth/OAuthCallback";

if (config('social.google_analytics_id')) {
    ReactGA.initialize(config('social.google_analytics_id'));
}

function App() {

    return (
        <Provider store={store}>
            <PersistGate loading={null} persistor={persistor}>
                <RouterConfigurator/>
            </PersistGate>
        </Provider>
    );

}

// Used to configure listener on React Router history to log Google Analytics Data
function AppContainer(props) {
    const history = useHistory();

    useEffect(() => {
        if (config('social.google_analytics_id')) {
            // Log page view on Google Analytics
            const unregister = history.listen(location => ReactGA.pageview(location.pathname));

            return unregister;
        }
    }, [history])

    return props.children

}

const initAction = new InitAction();

function RouterConfigurator() {
    (!initAction.running) && store.dispatch(initAction.init());
    const isAuth = useSelector(state => Auth(state));
    return (
        <Router>
            <AppContainer>
                <Switch>
                    <Route exact path="/" render={() => {
                        window.scrollTo(0, 0)
                        return isAuth ? <Redirect to={store.getState().SettingsReducer.dashboardRoute}/> :
                            <PublicLayout showHeader={false} showFooter={false}><PublicHomeView/></PublicLayout>
                    }}>
                    </Route>
                    <Route exact path={config('app.social_login_callback')}>
                        <PublicLayout showHeader={false} showFooter={false}><OAuthCallback/></PublicLayout>
                    </Route>
                    {appRoutes.filter(r => r.path).map((_route, i) => {
                            let {
                                visibility,
                                access,
                                label,
                                component,
                                title,
                                showFooter,
                                showHeader,
                                layout,
                                showStore,
                                ...r
                            } = _route;
                            let showComponent;
                            const {showBackButton} = r;
                            const shouldShowFooter = showFooter()
                            switch (true) {
                                case (visibility === 'public'):
                                case (visibility === "private") && isAuth && access() === 'granted':
                                case (r.path === '/logout') && isAuth:
                                    showComponent = component;
                                    break;
                                case (visibility === "private") && isAuth && access() === 'deny':
                                    showComponent = <Error404View/>
                                    break;
                                default:
                                    showComponent = <Redirect to={config('app.after_logout')}/>;
                            }

                            let c;
                            switch (layout) {
                                case 'public':
                                    c = <PublicLayout
                                        showFooter={shouldShowFooter}
                                        showBackButton={showBackButton}
                                        showHeader={showHeader}
                                        title={title || label}
                                        showStore={showStore}
                                    >{showComponent}</PublicLayout>
                                    break;
                                case 'VirtualQueueLayout':
                                    c = <VirtualQueueLayout
                                        showFooter={shouldShowFooter}
                                        showBackButton={showBackButton}
                                        showHeader={showHeader}
                                        title={title || label}
                                        showStore={showStore}
                                    >{showComponent}</VirtualQueueLayout>
                                    break;
                                default:
                                    c = <DefaultLayout
                                        showFooter={shouldShowFooter}
                                        showBackButton={showBackButton}
                                        showHeader={showHeader}
                                        title={title || label}
                                        showStore={showStore}
                                    >{showComponent}</DefaultLayout>
                            }
                            return <Route key={i} {...r}>{c}</Route>
                        }
                    )}
                    <Route exact path="/:storeId" render={({match}) => {
                        const {storeId} = match.params;
                        return <BookingRedirect storeId={storeId}/>;
                    }}>
                    </Route>
                    <Route path="*"><DefaultLayout showFooter={false}
                                                   showHeader={true}
                                                   title="No existe">
                        <Error404View/>
                    </DefaultLayout>
                    </Route>
                </Switch>
            </AppContainer>
        </Router>
    )
}


export default App;

