import ApiIdentity from '../api/ApiIdentity'
import * as Actions from '../constants/ReducerActions/Identity'

const IdentityAction = {
    initAction: () => {
        return {type: Actions.LIST}
    },
    listSuccess: (response) => {
        return {type: Actions.LIST_DONE, payload: response || null}
    },
    listErrors: (response) => {
        return {type: Actions.LIST_ERROR, payload: response || null}
    },
    all: () => (dispatch, getState) => (new Promise(function (resolve, reject) {
        dispatch({type: Actions.SCREEN_LOAD});
        ApiIdentity
            .all()
            .then(response => {
                dispatch(IdentityAction.listSuccess(response.data || {}))
                resolve(response.data)
            })
            .catch(error => {
                dispatch(IdentityAction.listErrors(error))
                reject(error)
            });
        dispatch(IdentityAction.initAction());
    })),


    // Personal
    initActionPersonal: () => {
        return {type: Actions.LIST_PERSONAL}
    },
    listSuccessPersonal: (response) => {
        return {type: Actions.LIST_PERSONAL_DONE, payload: response || null}
    },
    listErrorsPersonal: (response) => {
        return {type: Actions.LIST_PERSONAL_ERROR, payload: response || null}
    },
    personal: () => (dispatch, getState) => (new Promise(function (resolve, reject) {
        ApiIdentity
            .personal()
            .then(response => {
                dispatch(IdentityAction.listSuccessPersonal(response.data || {}))
                resolve(response.data)
            })
            .catch(error => {
                dispatch(IdentityAction.listErrorsPersonal(error))
                reject(error)
            })
        ;
        dispatch(IdentityAction.initActionPersonal())
    })),


    // Social
    initActionSocial: () => {
        return {type: Actions.LIST_SOCIAL}
    },
    listSuccessSocial: (response) => {
        return {type: Actions.LIST_SOCIAL_DONE, payload: response || null}
    },
    listErrorsSocial: (response) => {
        return {type: Actions.LIST_SOCIAL_ERROR, payload: response || null}
    },
    socials: () => (dispatch, getState) => (new Promise(function (resolve, reject) {
        ApiIdentity
            .socials()
            .then(response => {
                dispatch(IdentityAction.listSuccessSocial(response.data || {}))
                resolve(response.data)
            })
            .catch(error => {
                dispatch(IdentityAction.listErrorsSocial(error))
                reject(error)
            })
        ;
        dispatch(IdentityAction.initActionSocial())
    }))

};

export default IdentityAction
