import React, { Component } from 'react';
import {withRouter} from "react-router-dom";
import config from 'configReactApp';
import {connect} from 'react-redux';

import BookingAction from "../../actions/Booking";
import Waiting from "../../components/Waiting/Waiting";
import {Toast, ToastBody, ToastHeader, Button, Container} from "reactstrap";
import ErrorAlert from "components/Alert/ErrorAlert";
import { CLEAR_ERRORS } from 'constants/ReducerActions/Booking';

class BookingConfirmCancel extends Component{
    back = () => window.history.back();

    componentDidMount(){
        this.props.onInit();
    }

    cancelBooking = () => {
        const {booking} = this.props.match.params;
        
        this.props.onConfirm()
            .then(response => {
                // if (response.status === 201) {
                this.props.history.push(config('app.booking.after_cancel').replace(':booking', booking))
                // }
            });
    }

    render(){
        const {bookingId, errors} = this.props;

        const alertErrors = errors ? <ErrorAlert text={errors.message}/> : '';

        return (
            <Waiting waiting={this.props.waiting} waiting_alt={this.props.waiting_alt}>
                {alertErrors}
                <Toast className="mx-3">
                    <ToastHeader>
                        <h4 className="font-weight-bold text-dark my-2">¿Esta seguro que desea cancelar la cita?</h4>
                    </ToastHeader>
                    <ToastBody>
                        <Container className="my-5">
                            <Button color="primary" block className="mt-3" onClick={() => this.cancelBooking(bookingId)}>Confirmar</Button>
                            <Button color="secondary" block outline className="mt-3" onClick={this.back}>Volver</Button>
                        </Container>
                    </ToastBody>
                </Toast>
            </Waiting>
        )
    }
}

const mapStateToProps = state => {

    return {
        waiting: state.StoreTimeReducer.waiting || null,
        waiting_alt: state.BookingReducer.waiting || null,
        errors: state.BookingReducer.errors || null
    }
};

const mapDispatchToProps = (dispatch, getState) => {
    return {
        onInit: () => dispatch({type: CLEAR_ERRORS}),
        onConfirm: () => dispatch(BookingAction.cancel(dispatch, getState))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(BookingConfirmCancel));