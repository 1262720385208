import {getenv} from 'configReactApp'

const _ = {
    homesite: '/',
    guest_home: '/guest',
    forgot_password: '/forgotpassword',
    forgot_password_succeed: '/forgotpassword/sent',
    reset_password: '/password/reset/:token',
    reset_password_done: '/password/reset-sent',
    login: '/login',
    login_fulfillment: '/login/fulfillment',
    social_login_callback: '/login/callback',
    after_logout: '/',
    after_login: '/',
    register: '/register',
    register_after_create: '/register/done',
    register_after_activate: '/register/activate',
    guest_register: '/guest/register',
    succeed_logout: '/succedLogout',
    succeed_change_password: '/succedChangePassword',
    search: '/search',
    bookings: '/bookings',
    succeed_entry: '/succeedEntry',
    succeed_check_in: '/succeedCheckIn',
    queue: '/queue',
    guest: {
        booking: {detail: '/guest/bookings/:booking'},
        queue: {detail: '/guest/queue/:queue_id'}
    },
    booking: {
        new: '/bookings/new/:store_id',
        detail: '/bookings/:booking',
        after_create: '/bookings/:booking/done/',
        cancel: '/bookings/:booking/cancel/',
        after_cancel: '/bookings/:booking/cancel/done',
        checkin: '/bookings/:booking/checkin/',
        success_checkin: '/bookings/:booking/ok/',
        failed_checkin: '/bookings/:booking/error/',
        bookings: '/bookings',
        edit: '/bookings/edit/:booking',
    },
    virtualQueue: {
        new: '/booking/queue/:store_id',
        search: '/search/virtual',
        detail: '/queue/:queue_id'
    },
    static_images_prefix_url: '/img/',
    intern: getenv('INTERN', 'false'),
    profile: {
        root: '/profile'
    }
}
export default _;
