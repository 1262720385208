import React from 'react'
import QrReader from 'react-qr-reader'
 
const QRScan = (props) => {
    return (
        <QrReader
          onError={props.handleError}
          onScan={props.handleScan}
          style={{ width: '100%' }}
        />
    )
}
export default QRScan