import ApiCity from '../api/ApiCity'
import * as Actions from '../constants/ReducerActions/City'

const CityAction = {
    initAction: () => {
        return {type: Actions.LIST}
    },
    listSuccess: (response) => {
        return {type: Actions.LIST_DONE, payload: response || null}
    },
    allSuccess: (response) => {
        return {type: Actions.ALL, payload: response || null}
    },
    listErrors: (response) => {
        return {type: Actions.LIST_ERROR, payload: response || null}
    },
    all: () => (dispatch, getState) => (new Promise(function (resolve, reject) {
        ApiCity
            .all()
            .then(response => {
                dispatch(CityAction.allSuccess(response.data || {}))
                resolve(response.data)
            })
            .catch(error => {
                dispatch(CityAction.listErrors(error))
                reject(error)
            })
        ;
        dispatch(CityAction.initAction())
    }))
};

export default CityAction
