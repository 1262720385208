import {combineReducers} from 'redux';
import AppReducer from './App';
import BookingDetailReducer from './BookingDetail';
import BookingListReducer from './BookingList';
import BookingReducer from './Booking';
import BrandReducer from './Brand';
import CityReducer from './City';
import CountryReducer from './Country';
import ForgotPasswordReducer from './ForgotPassword';
import IdentityReducer from './Identity';
import LocationsReducer from './Locations';
import LoginReducer from './Login';
import RegisterReducer from './Register';
import StoreReducer from './StoreReducer';
import StoreTimeReducer from "./StoreTime";
import ProfileReducer from "./Profile";
import AuthReducer from "./Auth";
import ChangePasswordReducer from "./ChangePassword";
import LoginFulfillmentReducer from "./LoginFulfillment";
import ResetPasswordReducer from "./ResetPassword";
import EditProfileReducer from "./EditProfile";
import EditLocationReducer from "./EditLocation";
import VirtualQueueReducer from "./VirtualQueue";
import VirtualQueueDetailReducer from "./VirtualQueueDetail";
import VirtualQueueListReducer from "./VirtualQueueList";
import ProgressReducer from './Progress';
import SettingsReducer from './Settings';
import OAuthReducer from './OAuth';


const rootReducer = combineReducers({
    AppReducer,
    AuthReducer,
    BookingDetailReducer,
    BookingListReducer,
    BookingReducer,
    BrandReducer,
    ChangePasswordReducer,
    CityReducer,
    CountryReducer,
    EditLocationReducer,
    EditProfileReducer,
    ForgotPasswordReducer,
    IdentityReducer,
    LocationsReducer,
    LoginFulfillmentReducer,
    LoginReducer,
    OAuthReducer,
    ProfileReducer,
    ProgressReducer,
    RegisterReducer,
    ResetPasswordReducer,
    SettingsReducer,
    StoreReducer,
    StoreTimeReducer,
    VirtualQueueDetailReducer,
    VirtualQueueListReducer,
    VirtualQueueReducer
});
export default rootReducer;
