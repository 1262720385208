import React from "react";
import { Label, FormGroup, Input } from 'reactstrap';

export default (props) => <FormGroup style={{ marginBottom: '10px' }} check>
    <Label check>
        <Input disabled={props.disabled} value={props.checked} type='checkbox' onChange={(value) => props.onValueChange(value.target.checked)} />
            Entiendo que al cambiar de ciudad Turnera ajustará las preferencias y no desplegará información de la ciudad actual.
            No perderás tus turnos actuales.
    </Label>
</FormGroup>
