import * as Action from '../constants/ReducerActions/Country'
import InitialState from '../constants/InitialState/Country'

const CountryReducer = (state = InitialState, {type, payload}) => {
    switch (type) {
        case Action.SCREEN_LOAD:
            return {...InitialState, ...payload};
        case Action.LIST :
            return {...state, waiting: true};
        case Action.LIST_DONE    :
            return {...state, waiting: false, list: [...payload]};
        case Action.LIST_ERROR   :
            return {...state, waiting: false, errors: {...payload}};
        default:
            return state;
    }
};

export default CountryReducer;
