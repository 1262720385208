import React, {Component} from 'react';
import {connect} from 'react-redux';
import {withRouter} from "react-router-dom"
import BookingDetail from "components/Booking/BookingDetail"
import {GET, SCREEN_LOAD, SET_BOOKING} from 'constants/ReducerActions/BookingDetail'
import BookingDetailAction from 'actions/BookingDetail';
import Waiting from "../../components/Waiting/Waiting";
import LoginAction from 'actions/Login';
import queryString from 'query-string';
import {isAuth} from "../../isAuth";


class BookingDetailView extends Component {
    args = {};


    componentDidMount() {
        const {booking} = this.props.match.params
        if (isAuth()) {
            this.props.onInit(booking);
        } else {
            const {hash, passtemp} = queryString.parse(this.props.location.search)
            this.props.onInit(booking, {hash, passtemp});
        }

    }

    prepare() {
        this.args = {
            name: 'BookingDetail',
            data: this.props.data
        };
    }

    render() {
        this.prepare();

        return <>
            <h2 className="d-none d-sm-block">{this.props.title || "Mi Reserva"}</h2>
            <Waiting waiting={this.props.waiting}><BookingDetail {...this.args}/> </Waiting>
        </>;
    }
}

const mapStateToProps = state => {

    return {
        data: state.BookingDetailReducer.data || null,
        waiting: state.BookingDetailReducer.waiting
    }
};

const mapDispatchToProps = (dispatch, getState) => {
    return {
        onInit: (booking_id, guestLogin) => {
            dispatch({type: SCREEN_LOAD})
            dispatch({
                type: SET_BOOKING,
                payload: booking_id
            });
            dispatch({type:GET});
            if (guestLogin) {
                dispatch(LoginAction.guestLogin({
                    username: guestLogin?.hash,
                    password: guestLogin?.passtemp
                })).then(() => {
                    dispatch(BookingDetailAction.get(dispatch, getState))
                })
            } else {
                dispatch(BookingDetailAction.get(dispatch, getState));
            }
        }
    };
};

export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(BookingDetailView));

