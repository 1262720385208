import React, { Component } from 'react';
import Feedback from "../../components/Feedback/Feedback";
import { faDoorOpen } from "@fortawesome/free-solid-svg-icons";
import { withRouter } from "react-router-dom"
import config from 'configReactApp'

class SucceedLogoutView extends Component {

    componentDidMount() {
        setTimeout(() => {
            this.props.history.replace(config('app.after_logout'))
        }, 3000);
    }

    render = () => <Feedback icon={faDoorOpen} title="Sesión Finalizada" description="Esperamos verte pronto" />
}

export default withRouter(SucceedLogoutView);