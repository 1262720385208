import Feedback from "./Feedback"
import {faExclamationTriangle} from '@fortawesome/free-solid-svg-icons'

class Warning extends Feedback {}
Warning.defaultProps = {
    ...Feedback.defaultProps,
    icon: faExclamationTriangle,
    icon_color:"yellow",
}
export default Warning
