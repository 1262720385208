import * as Action from '../constants/ReducerActions/BookingDetail'
import InitialState from '../constants/InitialState/BookingDetail'

const BookingDetailReducer = (state = InitialState, {type, payload}) => {
    switch (type) {
        case Action.SCREEN_LOAD:
            return {...InitialState, ...payload};
        case Action.SET_BOOKING:
            return {...InitialState, id: payload};
        case Action.LIST:
        case Action.GET:
            return {...state, waiting: true};
        case Action.GET_DONE:
            return {...state, waiting: false, data: {...payload}};
        case Action.LIST_DONE:
            return {...state, waiting: false, list: {...payload}};
        case Action.GET_ERROR:
        case Action.LIST_ERROR:
            return {...state, waiting: false, errors: {...payload}};
        default:
            return state;
    }
};

export default BookingDetailReducer;
