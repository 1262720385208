import {applyMiddleware, compose, createStore} from 'redux'
import thunk from 'redux-thunk'
import rootReducer from './reducers/index';
import {persistReducer, persistStore} from 'redux-persist'
import storage from 'redux-persist/lib/storage' // defaults to localStorage

// const customLogger = (store) => (next) => (action) => {
//     let previous = {...store.getState()}
//     next(action)
//
//     console.log('action: ', action);
//     console.log('previous: ', previous);
//     console.log('current: ', store.getState());
//
//
// }

const persistConfig = {
    key: 'root',
    storage,
    whitelist: [
        'AuthReducer',
        'BookingReducer',
        'CityReducer',
        'CountryReducer',
        'IdentityReducer',
        'LocationsReducer',
        'LoginFulfillmentReducer',
        // 'OAuthReducer',
        'ProfileReducer',
        'StoreReducer',
        'SettingsReducer',
        // 'VirtualQueueReducer',

    ]
    // blacklist: [
    //     'LoginReducer',
    //     'AppReducer',
    //     'EditProfileReducer',
    //     'EditLocationReducer',
    //     'ChangePasswordReducer',
    //     'ProgressBarReducer',
    //     'RegisterReducer',
    // ]
}

const persistedReducer = persistReducer(persistConfig, rootReducer)

function configureStore(preloadedState) {
    console.log(process.env.NODE_ENV);
    let _compose;
    switch (process.env.NODE_ENV) {
        case 'production':
            _compose = compose;
            break;

        default:
            _compose = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

    }

    const middlewares = [thunk]
    const middlewareEnhancer = applyMiddleware(...middlewares)

    const enhancers = [middlewareEnhancer]
    const composedEnhancers = _compose(...enhancers)

    const store = createStore(persistedReducer, preloadedState, composedEnhancers)

    return store


}

const store = configureStore();
const persistor = persistStore(store)
export {store, persistor}

