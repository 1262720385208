import {Col, Container, Media, Row} from "reactstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faUserCircle} from "@fortawesome/free-solid-svg-icons";
import React from "react";
import moment from 'moment';
import 'moment/locale/es';

moment.locale('es');

const ProfileResume = (profile) => {
    return (
        <Media style={{paddingLeft: '0', paddingRight: '0'}}>
            <Container>
                <Row>
                    <Col style={{paddingLeft: '-1rem'}} lg="3" className="text-center">
                        <Media left top>
                            <FontAwesomeIcon icon={faUserCircle} size="4x" className="mr-2"/>
                        </Media>
                    </Col>
                    <Col lg="9">
                        <Media body>
                            <Media heading className="text-center text-lg-left">
                                {profile.first_name}
                            </Media>
                            <span style={{
                                'textOverflow': 'ellipsis',
                                'display': 'inline-block',
                                'width': '100%',
                                'overflow': 'hidden',
                                'whiteSpace': 'nowrap'
                            }} className="phone">{profile.email}</span><br/>
                            <small
                                className="since">Registrado {profile.created_at ? moment(profile.created_at).calendar() : ''}</small>
                        </Media>
                    </Col>
                </Row>
            </Container>
        </Media>
    );
};

export default ProfileResume;
