export const SCREEN_LOAD = "STORE_SCREEN_LOAD";

// Listar Tiendas disponibles
export const LIST = "STORE_LIST";
export const LIST_DONE = "STORE_LIST_DONE";
export const LIST_ERROR = "STORE_LIST_ERROR";
export const FILTER_LIST = "STORE_FILTER_LIST";
export const CLEAR_ERRORS = "CLEAR_ERRORS";
export const SET_PARENT = "STORE_SET_PARENT";
export const CLEAR_PARENT = "STORE_CLEAR_PARENT";
export const LIST_APPEND = "STORE_LIST_APPEND";
export const SET_FILTER = "STORE_SET_FILTER";

