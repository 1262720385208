import React, { Component } from 'react'
import { withRouter } from "react-router-dom"
import Succeed from "../../../components/Feedback/Succeed"
import config from 'configReactApp'

class SucceedNewPassPage extends Component {

    componentDidMount() {
        setTimeout(() => {
            this.props.history.push(config('app.after_logout'))
        }, 3000);
    }

    render = () => <Succeed title="¡Se ha cambiado la clave exitosamente!" description="Será redireccionado a la pantalla de inicio" />
}

export default withRouter(SucceedNewPassPage);
