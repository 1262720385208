import FormCheckbox from "./FormCheckbox";
import FormRadio from "./FormRadio";
import FormSelect from "./FormSelect";
import FormInput from "./FormInput";
import {Col} from "reactstrap";
import React from "react";
import FormInputGroupButtonDropdown from './FormInputGroupButtonDropdown';
import FormLabeledInputGroup from './FormLabeledInputGroup';

export const renderOneField = (i, n) => {
    let input, legend;

    i.required = Boolean(i.required);
    const colSize = typeof i.colSize === "object" ? i.colSize : {xs: i.colSize || 12};
    i = {key: i.name + '_' + n, ...i};

    switch (i.type || 'text') {
        case 'legend':
            return <legend>{i.label}</legend>;
        case 'checkbox':
            input = <FormCheckbox {...i} options={i.options}/>;
            break;
        case 'radio':
            input = <FormRadio {...i} options={i.options}/>;
            break;
        case 'select':
        case 'dropdown':
            input = <FormSelect {...i} options={i.options}/>;
            break;
        case 'input-group-dropdown':
            input = <FormInputGroupButtonDropdown {...i} options={i.options}/>;
            break;
        case 'phone':
            input = <FormLabeledInputGroup {...i} options={i.options}/>;
            break;
        default:
            input = <FormInput {...i} />;
    }
    return input && <Col {...colSize}>{input}</Col>;
}


