import React, {Component} from 'react'
import Header from '../components/Header/Header'
import Footer from '../components/Footer/Footer'
import {connect} from "react-redux";
import PropTypes from 'prop-types';
import {Col, Container, Row} from 'reactstrap'
import _ from 'lodash'

class DefaultLayout extends Component {
    getContent = () => this.props.children ?? <></>
    getHeader = () => this.props.showHeader ? <Header title={this.props.title}/> : ''
    getFooter = () => this.props.showFooter ? <Footer title={this.props.title}/> : ''
    getMT = () => "pt-sm-0 mt-sm-0 " + (this.props.showHeader ? "mt-7" : '')

    render() {
        return <div className={"DefaultLayout background "+this.props.country}>
            {this.getHeader()}
            <Container fluid className={this.getMT()}>
                <Row>
                    <Col className="p-0">{this.getContent()}</Col>
                </Row>
            </Container>
            {this.getFooter()}
        </div>

    }
}


const mapStateToProps = state => {
    return {
        data: state.AppReducer || null,
        country: _.get(state.ProfileReducer, 'city.country.iata', 'cencosud').toLowerCase(),
    }
};

DefaultLayout.propTypes = {
    showHeader: PropTypes.bool.isRequired,
    showFooter: PropTypes.bool.isRequired,
    title: PropTypes.string
}
DefaultLayout.defaultProps = {
    showHeader: true,
    showFooter: false,
    title: process.env.REACT_APP_TITLE
}

export default connect(mapStateToProps
)(DefaultLayout);
