import {Redirect, withRouter} from "react-router-dom";
import {connect} from "react-redux";
import {needsFulfillment} from "../../isAuth";

import React, {Component} from 'react';
import Waiting from "../../components/Waiting/Waiting";
import {OAUTH_LOGIN_DONE, OAUTH_SCREEN_LOAD} from "../../constants/ReducerActions/OAuth";
import config from "../../configReactApp";


class OAuthCallback extends Component {

    componentDidMount() {
        this.props.onInit(this.props);
    }

    redirect_to = () => {
        return this.props.needs_fulfillment ? config('app.login_fulfillment') : this.props.redirect_to;


    }

    render = () => {
        return <Waiting ref={this.myRef} waiting={this.props.waiting}>
            <Redirect to={this.redirect_to()}/>
        </Waiting>
    }
}

const mapStateToProps = state => {

    return {
        waiting: state.OAuthReducer.waiting,
        redirect_to: state.OAuthReducer.redirect_to ?? state.LoginFulfillmentReducer.redirect_to,
        oauth_profile: state.OAuthReducer.data,
        user_profile: state.ProfileReducer,
        needs_fulfillment: needsFulfillment(state)

    }
};

const mapDispatchToProps = (dispatch, getState) => {
    return {
        onInit: (props) => {
            dispatch({type: OAUTH_SCREEN_LOAD})
            if (props.oauth_profile.username && props.user_profile.id) {
                dispatch({type: OAUTH_LOGIN_DONE})
            }
        }
    }
}
export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(OAuthCallback));
