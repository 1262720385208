import React, {Component} from 'react';
import ListItem from "../components/Lists/ListItem";
import StoreAction from "../actions/Store";
import {connect} from "react-redux";
import StoreSearchBar from "../components/Search/StoreSearchBar"
import config from "../configReactApp";
import Waiting from "../components/Waiting/Waiting";
import {withRouter} from 'react-router-dom';
import {Progress} from 'reactstrap';
import {APP_RESET} from "../constants/ReducerActions/App";
import {UNSET_VIRTUAL_QUEUE} from '../constants/ReducerActions/Settings';

class SearchView extends Component {

    state = {
        isParentSelected: false
    }

    componentDidMount() {
        this.props.onInit();
    }

    listado = () => {
        const stores = this.props.stores;
        if (stores.length > 0) {
            return <ListItem name="Store" data={stores} link={config('app.booking.new')}/>
        } else {
            return <h4 className="text-center p-5">No se encontró mall</h4>
        }
    }

    render() {
        const progress = this.props.progress;
        const showProgress = progress > 0 && progress < 100;

        return <>
            <Waiting waiting={this.props.waiting}>
                <h2 className="d-none d-sm-block">Elige tu mall</h2>
                <StoreSearchBar/>
                {/*<div className={"text-center " + showProgress ? 'd-block' : 'hide'}>{progress}</div>*/}
                <Progress value={progress} style={{
                    margin: '10px',
                    display: showProgress ? 'block' : 'none',
                    opacity: showProgress ? '100%' : '0%'
                }}/>
                {this.listado()}
            </Waiting>


        </>
    }
}

const mapStateToProps = (state) => {

    return {
        stores: state.StoreReducer.list || null,
        waiting: state.StoreReducer.waiting,
        store: state.StoreReducer.parent || {
            id: 0, name: "",
            street: "",
            street_number: "",
            suburb: "",
            state: "",
        },
        progress: state.ProgressReducer.percentage.value,
    }
};

const mapDispatchToProps = (dispatch, getState) => {
    return {
        onInit: () => {
            dispatch({type: APP_RESET});
            dispatch({ type: UNSET_VIRTUAL_QUEUE });
            dispatch(StoreAction.all(dispatch, getState))
        },
    };
};


export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(SearchView));
