export const SCREEN_LOAD = "EDITPROFILE_SCREEN_LOAD";

// CREAR REGISTRO
export const EDIT_FORM = "EDITPROFILE_EDIT_FORM";
export const SUBMIT = "EDITPROFILE_SUBMIT";
export const SUBMIT_DONE = "EDITPROFILE_SUBMIT_DONE";
export const SUBMIT_ERROR = "EDITPROFILE_SUBMIT_ERROR";

// ANULAR REGISTRO
export const CANCEL = "EDITPROFILE_CANCEL";
export const CANCEL_DONE = "EDITPROFILE_CANCEL_DONE";
export const CANCEL_ERROR = "EDITPROFILE_CANCEL_ERROR";

// MODIFICAR REGISTRO
export const UPDATE = "EDITPROFILE_UPDATE";
export const UPDATE_DONE = "EDITPROFILE_UPDATE_DONE";
export const UPDATE_ERROR = "EDITPROFILE_UPDATE_ERROR";