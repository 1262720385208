import React, {Component} from 'react';
import FormTag from "components/Forms/FormTag";
import {connect} from 'react-redux';
import {EDIT_FORM, SCREEN_LOAD, SUBMIT} from 'constants/ReducerActions/Register'
import GuestRegisterAction from "../../actions/GuestRegister";
import GuestRegisterForm from 'constants/Forms/GuestRegister'
import {InputEvts} from 'components/Forms/Utils'
import {Container, Row} from 'reactstrap'
import {withRouter} from "react-router-dom"
import {GoBackButton, SocialLoginButtons} from "components/Buttons";
// import config from 'configReactApp'
import TermsAndConditionsCheck from "../../components/Forms/FormTermsAndConditions";
import ErrorAlert from 'components/Alert/ErrorAlert';
import Waiting from "components/Waiting/Waiting";
import {redirectToUrl} from "../../helper";

// copiado de RegisterView
class GuestRegisterView extends Component {
    args = {};

    socialLoginErrors

    constructor(props) {
        super(props);
        props.onInit(props);
        this.state = {
            missing_terms: false
        }
        this.socialLoginErrors = this.props.location.state?.error;
        // Clears navigation state. To delete errors persisted in navigation state
        window.history.replaceState(null, '')
    }

    setPhonePrefix = (selectedCountry, countries) => {
        let selectedCountryPhonePrefix = null;
        for (const countryIndex in countries) {
            const country = countries[countryIndex];
            if (country.id === parseInt(selectedCountry)) {
                selectedCountryPhonePrefix = country.phone_code;
                break;
            }
        }
        this.props.setPhonePrefix(selectedCountryPhonePrefix);
    }

    handleRegisterSubmit = (e) => {
        e.preventDefault();
        if (!this.props.data.accept_terms_and_conditions) {
            this.setState({missing_terms: true});
        } else {
            this.setState({missing_terms: false});
            // const referer_url = this.props.location.pathname;
            // this.props.history.replace(config('app.social_login_callback'));
            this.props.onSubmit(e)
                .then(response => {
                    const {redirectTo, params} = redirectToUrl(response);
                    this.props.history.replace(redirectTo, params);
                })
                .catch(err => {
                    console.error(err);
                    // this.props.history.replace(referer_url);
                })
        }
    }


    prepare() {
        this.args = {
            ...GuestRegisterForm,

            onChange: this.props.onChange,
            onSubmit: this.handleRegisterSubmit,

            inline: false,

        };
        const country_on_events = new InputEvts();
        country_on_events.add('change', this.props.onChange);
        country_on_events.add('change', this.handleOnCountryChange);


        this.args.fields.phone.prefix = this.props.data.prefix;
    }

    render() {
        this.prepare();

        const form = <FormTag name='NewUser' {...this.args} data={this.props.data} footer={
            <>
                {this.state.missing_terms && <ErrorAlert
                    text="Debe aceptar los términos y condiciones"/>}
                <TermsAndConditionsCheck checked={this.props.data.accept_terms_and_conditions}
                                         country={this.props.data.country_id}
                                         onValueChange={this.props.onTermsCheckChange}/>
            </>

        }/>
        return <Waiting waiting={this.props.waiting}>
            <div className="my-3">
                {this.socialLoginErrors && <Container>
                    <Row>
                        <ErrorAlert text={this.socialLoginErrors}/>
                    </Row>
                </Container>}
                {form}
                <Container className="my-5">
                </Container>
                <SocialLoginButtons/>
                <Container className="my-4"><GoBackButton/></Container>
            </div>
        </Waiting>
    }
}

const mapStateToProps = state => {

    return {
        countries: state.CountryReducer.list || null,
        cities: state.CityReducer.list || null,
        store: state.StoreReducer.parent || {},
        data: state.RegisterReducer || null,
        waiting: state.RegisterReducer.waiting || state.CountryReducer.waiting || state.StoreReducer.waiting,
    }
};

const mapDispatchToProps = (dispatch, getState) => {
    return {
        onInit: (props) => {
            dispatch({type: SCREEN_LOAD});
            props.setDefaultCountry(props.store.city.country_id);
            props.setDefaultCity(props.store.city.id);
            props.setPhonePrefix(props.store.city.country.phone_code);
        },
        onChange: e => dispatch({
            type: EDIT_FORM,
            payload: {
                name: e.target.name,
                value: e.target.value,
                type: e.target.type,
                files: e.target.files
            }
        }),
        setDefaultCountry(id) {
            dispatch({
                type: EDIT_FORM,
                payload: {
                    name: "country_id",
                    value: id,
                    type: "change",
                }
            })
        },
        setDefaultCity(id) {
            dispatch({
                type: EDIT_FORM,
                payload: {
                    name: "city_id",
                    value: id,
                    type: "change",
                }
            })
        },
        setPhonePrefix(value) {
            dispatch({
                type: EDIT_FORM,
                payload: {
                    name: "prefix",
                    value,
                    type: "change",
                }
            })
        },
        onTermsCheckChange: value => dispatch({
            type: EDIT_FORM,
            payload: {
                name: 'accept_terms_and_conditions',
                value,
                type: 'change',
            }
        }),
        onSubmit: e => {
            e.preventDefault();
            GuestRegisterAction.FetchRequest = SUBMIT;
            return dispatch(GuestRegisterAction.save(dispatch, getState, e));
        },
    };
};


export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(GuestRegisterView));
