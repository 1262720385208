import * as ReducerActions from '../constants/ReducerActions/Booking'
import * as VirtualQueueActions from '../constants/ReducerActions/VirtualQueue'
import * as VirtualQueueDetailActions from '../constants/ReducerActions/VirtualQueueDetail'
import * as VirtualQueueListActions from '../constants/ReducerActions/VirtualQueueList'
import ApiBooking from "../api/ApiBooking";
import BookingDetailAction from '../actions/BookingDetail';
import StoreTimeAction from "../actions/StoreTime";
import {EDIT_FORM} from 'constants/ReducerActions/Booking'
import ApiQueue from 'api/ApiQueue';
import _ from "lodash";

const BookingAction = {
    FetchRequest: '',
    initAction: () => {
        return {type: BookingAction.FetchRequest}
    },
    getVirtualQueue: (id) => (dispatch, getState) => {
        dispatch({type: VirtualQueueDetailActions.LOAD_VIRTUAL_QUEUE_START});
        ApiQueue.getById(id).then((response) => {
            // let t = _.get(response, 'data.queue.store.parent.name');
            // let l = _.get(response, 'data.queue.store.parent.logo');
            // dispatch({type: APP_TITLE, payload: t});
            // dispatch({type: APP_FORCE_HEADER, payload: !_.isEmpty(t) || t});
            dispatch({type: VirtualQueueDetailActions.LOAD_VIRTUAL_QUEUE_DATA, payload: response.data});
        }).catch((err) => dispatch({type: VirtualQueueDetailActions.ERROR_VIRTUAL_QUEUE_DATA, payload: err}))
    },
    getAllvirtualQueues: () => (dispatch, getState) => {
        dispatch({type: VirtualQueueListActions.LIST});
        ApiQueue.getAll().then((resp) => dispatch({
            type: VirtualQueueListActions.LIST_DONE,
            payload: resp.data
        })).catch(err => dispatch({type: VirtualQueueListActions.LIST_ERROR, payload: err}))
    },
    newVirtualQueue: () => (dispatch, getState) => (new Promise(function (resolve, reject) {
        const queues = Object.values(getState().VirtualQueueReducer.list);
        const defaultQueue = queues.find(q => q.name === "default")
        const queue_id = _.get(defaultQueue, "id");
        console.log("id", queue_id);
        dispatch({type: VirtualQueueActions.LIST});
        ApiQueue.save(queue_id).then((res) => {
            dispatch({type: VirtualQueueDetailActions.LOAD_VIRTUAL_QUEUE_DATA, payload: res.data})
            resolve(res)
        }).catch(err => {
            dispatch({type: VirtualQueueDetailActions.ERROR_VIRTUAL_QUEUE_DATA, payload: err})
            reject(err)
        })
        if (getState().VirtualQueueReducer.pendingSave) dispatch({type: VirtualQueueActions.UNSET_PENDING_QUEUE_SAVE})
        // if (getState().BookingReducer.pendingSave) dispatch({type: ReducerActions.UNSET_PENDING_BOOKING_SAVE})
    })),
    save: () => (dispatch, getState) => (new Promise(function (resolve, reject) {
        const user_id = getState().ProfileReducer.id;
        let {data} = {...getState().BookingReducer};
        data.user_id = user_id;
        let {id} = data;
        dispatch(BookingAction.initAction())
        ApiBooking
            .store(id, data)
            .then(response => {
                dispatch(BookingAction.success(response))
                resolve(response)
            })
            .catch(error => {
                console.log("El error es", error.response);
                if (error.response) {
                    dispatch(BookingAction.showErrors(error.response.data))
                    resolve(error.response.data)
                } else {
                    dispatch(BookingAction.showErrors(error))
                    resolve(error)
                }
            })
        ;
        if (getState().BookingReducer.pendingSave) dispatch({type: ReducerActions.UNSET_PENDING_BOOKING_SAVE})
        // if (getState().VirtualQueueReducer.pendingSave) dispatch({type: VirtualQueueActions.UNSET_PENDING_QUEUE_SAVE})

    })),

    cancel: () => (dispatch, getState) => (new Promise(function (resolve, reject) {
        let {id} = {...getState().BookingDetailReducer.data};
        dispatch({type: ReducerActions.SUBMIT});
        ApiBooking
            .delete(id)
            .then(response => {
                dispatch(BookingAction.success(response))
                resolve(response)
            })
            .catch(error => {
                if (error.response) {
                    dispatch(BookingAction.showErrors(error.response.data))
                    reject(error.response.data)
                } else {
                    dispatch(BookingAction.showErrors(error))
                    reject(error)
                }
            })
        ;
        dispatch(BookingAction.initAction())
    })),

    setPendingSaveOnLogin: () => (dispatch, getState) => {
        dispatch({type: ReducerActions.SET_PENDING_BOOKING_SAVE})
    },

    setPendingQueueSaveOnLogin: () => (dispatch, getState) => {
        dispatch({type: VirtualQueueActions.SET_PENDING_QUEUE_SAVE})
    },

    showErrors: (response) => {
        return {type: ReducerActions.SUBMIT_ERROR, payload: response || null}
    },

    success: (response) => {
        return {type: ReducerActions.SUBMIT_DONE, payload: response.data || null};
    },

    edit: (booking) => (dispatch, getState) => new Promise(function (resolve, reject) {
        const user_id = getState().ProfileReducer.id;
        let {data} = {...getState().BookingReducer};
        data.user_id = user_id;
        dispatch({type: ReducerActions.SUBMIT});
        ApiBooking.put(booking, data).then((response => {
            dispatch(BookingAction.success(response))
            resolve(response)
        })).catch(error => {
            if (error.response) {
                dispatch(BookingAction.showErrors(error.response.data))
                reject(error.response.data)
            } else {
                dispatch(BookingAction.showErrors(error))
                reject(error)
            }
        })
    }),

    initEdit: (calendar_id) => (dispatch, getState) => new Promise(function (resolve, reject) {
        dispatch(BookingDetailAction.get())
            .then(resp => {
                    return dispatch(StoreTimeAction.all(false, calendar_id))
                }
            )
            .then(resp => {
                    return dispatch({
                        type: EDIT_FORM,
                        payload: {
                            name: 'store_time_id',
                            value: getState().BookingDetailReducer.data.store_time_id,
                            type: 'change',
                        }
                    })
                }
            )
            .then(resp => {
                const selectedDate = Object.entries(getState().StoreTimeReducer.all).find(([key, value]) => value?.find(v => v.key.toString() === getState().BookingDetailReducer.data.store_time_id?.toString()));
                if (selectedDate) {
                    dispatch(StoreTimeAction.selectDate(selectedDate[0]))
                }
                resolve(resp)
            })
            .catch(err => {
                console.log(err)
                reject(err);
            });
    })

};

export default BookingAction
