import {
    CityId,
    CountryId,
    EmailField,
    FirstName,
    LastName,
    PasswordConfirmationField,
    PasswordField,
    PhoneField
} from './Fields'
import {DNI_DISPLAY_NAME} from '../../helper';

const RegisterForm = {
    'name': 'User',
    'title': {'text': 'Registrate'},
    'description': 'Agenda tu visita al mall y salta la fila de tus tiendas favoritas con Turnos Cencosud',
    'fields': {
        'country_id': CountryId,
        'city_id': CityId,
        'first_name': FirstName,
        'last_name': LastName,
        'identity_value': {
            'label': 'Documento de Identidad',
            'placeholder': 'Ingrese el número aquí',
            'type': 'input-group-dropdown',
            'selectedValue': DNI_DISPLAY_NAME,
            'required': true,
            'dropdown': {
                'identity_id': {
                    'label': 'Tipo de documento',
                }
            }
        },
        'email': EmailField,
        'phone': PhoneField,
        'password': PasswordField,
        'password_confirmation': PasswordConfirmationField,
    },
    'buttons': {
        'submit': {
            'type': 'submit',
            'block': true,
            'color': 'success',
            'label': 'Crear Cuenta',
        }
    }

}

export default RegisterForm
