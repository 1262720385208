import {Row} from "reactstrap";
import React from "react";
import {renderOneField} from './RenderInput'
import {combineValues} from './Utils'
import Inflector from 'Inflector'


const renderRow = (formName, _fieldset) => {
    let legend;

    if (_fieldset.hasOwnProperty('fields')) {
        let fields = _fieldset.fields.map((field, n) => renderOneField(field, n));
        // for (let i in ) {
        //     let field = _fieldset.fields[i];
        //     let key = Inflector.classify();
        //     fields.push(renderOneField({key, ...field}));
        // }
        const rowTag = <Row key={Inflector.classify(formName + '_Row_' + _fieldset.name)}>{fields}</Row>;
        return legend ? <fieldset>{legend}{rowTag}</fieldset> : rowTag;
    } else {
        const field = renderOneField(_fieldset);
        return <Row key={Inflector.classify(formName + '_Row_' + _fieldset.name)}>{field}</Row>;

    }
}

const prepareFieldProperties = (fields, data, errors, inline, onChange) => {
    let fields_with_properties = {};
    // console.log(errors);
    for (let field in fields) {
        let field_error = errors && errors.hasOwnProperty(field) ? errors[field] : [];
        let field_props = fields[field] || {};
        let field_data = data[field] || '';
        fields_with_properties[field] = combineValues(field, field_props, field_data, field_error, inline, onChange);
    }
    return fields_with_properties;
}

const renderRows = (formName, fieldValues) => {
    let renderFields = [];
    for (let field in fieldValues) {
        let renderField = renderRow(formName, fieldValues[field]);
        renderFields.push(renderField);
    }
    return renderFields;

}

const renderAllFieldsets = (props) => {
    const {
        inline,
        data,
        onChange,
        fields,
        name
    } = props;
    const errors = (data && data.hasOwnProperty('errors') && data.errors.hasOwnProperty('errors')) ? data.errors.errors : {};
    const fieldValues = prepareFieldProperties(fields, data, errors, inline, onChange);
    const renderedFields = renderRows(name, fieldValues);

    return <>{renderedFields}</>
};

const renderInputs = renderAllFieldsets;

export default renderInputs;
