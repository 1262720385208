import React from 'react'
import {Col, FormGroup, Input, Label, FormFeedback} from 'reactstrap'
import PropTypes from 'prop-types';
import Inflector from 'Inflector'
import {InputEvts} from './Utils'

class FormInput extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            errors: props.errors || [],
            value: props.value,
            name: Inflector.dotToName(props.name),
        };
        this.handleChange = this.handleChange.bind(this);
        this.handleEvents = this.handleEvents.bind(this);
    }


    renderErrors() {

        if (this.props.errors) {
            const message = this.props.errors.map(e => <div>{e}<br /></div>);
            return <FormFeedback>{message}</FormFeedback>;
        }
    }

    handleEvents(event) {
        // console.log(event.type);
        // console.log(this.props.e.processEvent(event.type,event));
        this.props.e.processEvent(event.type, event)
    }

    handleChange(event) {
        return this.handleEvents(event);
    }

    getIdentification() {
        const i = this.state.id || this.props.id || Inflector.classify(this.props.name);
        return i;
    }

    renderInput() {
        return this._renderInput()
    }

    prepareProperties(prop) {
        let p = {
            id: this.getIdentification(),
            placeholder: this.props.placeholder,
            name: this.props.name,
            className: this.props.className,
            type: this.props.type,
            valid: (!this.props.errors.length > 0) && this.props.value !== '',
            invalid: (this.props.errors.length > 0),
            value: this.props.value,
            ...prop
        };

        if (p.label) {
            delete p.label;
        }
        // console.log(p.name)
        // console.log(p.valid)
        // console.log(p.invalid)
        return p;
    }

    _renderInput() {

        const p = this.prepareProperties();
        return <Input {...p} onChange={this.handleEvents}/>

    }

    renderLabel() {
        if (this.props.type!=='hidden' && this.props.label!==false) {
        const l = this.props.label ? this.props.label : Inflector.humanize(this.props.name);
        const args = {for: this.getIdentification()};
        const sm = (this.props.inline) ? 2 : 12;
        const isRequired = this.props.required ? <span className="text-danger"> *</span> : '';
        const label = <Label {...args}>{l}{isRequired}</Label>;
        if (this.props.inline) {
            return <Col sm={sm}>{label}</Col>
        } else {
            return label
        }
        }

    }

    renderLabelCheck(input) {
        const l = this.props.label ? this.props.label : Inflector.humanize(this.props.name);
        return <Label check>{input}{l}</Label>

    }

    _render(options) {
        const {row, className, renderLabel, renderInput, renderErrors, description} = options;

        return <FormGroup row={row} className={className} inline={this.props.inline}>
            {renderLabel}{renderInput}{this.props.after}{renderErrors}{description}
        </FormGroup>
    }

    _renderCheck() {
        const input = this._renderInput();
        const label = this.renderLabelCheck(input);
        return label
    }

    render() {
        const renderLabel = this.renderLabel();
        const renderInput = this.renderInput();
        const renderErrors = this.renderErrors();

        return this.props.check ? this._renderCheck() : this._render({
            row: this.props.row,
            renderLabel,
            renderInput,
            renderErrors
        });
    }
}

FormInput.propTypes = {
    events: PropTypes.arrayOf(PropTypes.objectOf(InputEvts)),
    row: PropTypes.bool,
    required: PropTypes.bool,
    name: PropTypes.string.isRequired,
    label: PropTypes.string,
    value: PropTypes.any,
    default: PropTypes.any,
    before: PropTypes.string,
    between: PropTypes.string,
    after: PropTypes.string,
    errors: PropTypes.array,

};
FormInput.defaultProps = {

    label: '',
    default: '',
    value: '',
    name: '',
    row: true,
    className: '',
    required: false,
    type: 'text',
    before: '',
    after: '',
    between: '',
    errors: []
};
export default FormInput
