import React, {Component} from 'react';
import {Button, Col, Container, Media, Row} from 'reactstrap';
import QR from "../QR/QR";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faClock, faEdit, faExpand, faLessThan, faMapMarkerAlt, faTimes, faUser} from '@fortawesome/free-solid-svg-icons'
import config from 'configReactApp'
import LoadImage from "../Branding/Logo";
import {withRouter} from "react-router-dom";
import moment from 'moment';
import Inflector from "../../Inflector";
import 'moment/locale/es';
import {
    canDeleteBooking,
    canInviteBooking,
    isBookingCheckedIn,
    isBookingEnabled,
    isBookingEntryTimeFrame,
    isBookingExpired,
    hasChildren,
} from "../../helper";
import ErrorAlert from 'components/Alert/ErrorAlert';
import WarningAlert from 'components/Alert/WarningAlert';

moment.locale('es');

class BookingDetail extends Component {


    render() {
        const imgStyle = {
            maxHeight: '48px',
            maxWidth: '48px',
        }
        const icon_size = "1x";
        const icon_color = "white";

        const item = this.props.data;

        const store = item && item.store_time ? item.store_time.store : null;
        const store_time = item && item.store_time ? item.store_time : null;
        const brand = store ? store.brand : null;
        const logo = brand ? brand.logo : null;

        const invites = item?.invites || '0';

        const qr = item.code ? <QR code={item.code} size={150}/> : null;

        let address = [];
        if (store) {
            address.push(store.street + " " + store.street_number);
            address.push(store.suburb);
            address.push(store.state);

        }

        const booking_date_to_show = moment(store_time?.start_time).format("dddd DD [de] MMMM");
        const booking_start_to_show = moment(store_time?.start_time).format("h:mm a");
        const booking_end_to_show = moment(store_time?.end_time).format("h:mm a");

        const toCancelView = config('app.booking.cancel').replace(':booking', item.id);
        const toCheckinView = config('app.booking.checkin').replace(':booking', item.id);
        const toEditBookingView = config('app.booking.edit').replace(':booking', item.id) + "?store_id=" + item.store_time?.store?.id;
        const toBookingList = config('app.booking.bookings');
        const toVirtualQueue = config('app.virtualQueue.search') + "?parent_id=" + store?.id;

        // Build booking details from backend information that contains htmlq tag type and content

        let detalle = [];
        if (item.description_json?.length > 0) {
            item.description_json.forEach(product => {
                for (let d in product) {
                    let value = product[d];
                    if (typeof (value) === "object") {
                        let _value = [];
                        for (let i in value) {
                            let tag = value[i].tag;
                            let Tag = `${tag}`;
                            _value = [..._value, <Tag>{value[i].content}</Tag>]
                        }
                        detalle = [...detalle, {label: d, value: _value}]
                    } else {
                        detalle = [...detalle, {label: d, value: value}]
                    }
                }
            });
        }

        return (
            <Container>
                {item.code ? <>
                    {isBookingCheckedIn(item) ? <Row>
                        <Col className="d-flex justify-content-center text-secondary">
                            <WarningAlert text={`Su reserva ya ha sido utilizada el ${item.checkin_at}`}/>
                        </Col>
                    </Row> : isBookingExpired(item) ? <Row>
                        <Col className="d-flex justify-content-center text-secondary">
                            <WarningAlert text="Su reserva ha caducado"/>
                        </Col>
                    </Row> : null}
                    <Row>
                        <Col id="BookingStoreResume" className="d-flex justify-content-center text-secondary">
                            <Media>
                                <Media left middle>
                                    {logo ? <Media object
                                                   className="mr-2 mt-2"
                                                   style={imgStyle}
                                                   src={LoadImage(logo)}
                                    /> : ''}
                                </Media>
                                <Media body>
                                    <Media heading style={{height: '48px', lineHeight: '48px'}}>{store.name}</Media>
                                </Media>
                            </Media>
                        </Col>
                    </Row>

                    <Row>
                        <Col id="BookingCode" className="p-2 d-flex justify-content-center">
                            <h4 className="mb-0">#{item.code}</h4>
                        </Col>
                    </Row>
                    <Row>
                        <Col id="BookingQR" className="p-2 my-3 d-flex justify-content-center">
                            {qr}
                        </Col>
                    </Row>

                    <Row>
                        <Col className="mt-4 offset-1">
                            <h2>Horario</h2>
                        </Col>
                    </Row>
                    <Row className="mb-2">
                        <Col xs="1"><FontAwesomeIcon icon={faClock} size="2x" className="text-primary"/></Col>
                        <Col>
                            <h6 className="text-secondary">{Inflector.ucWords(booking_date_to_show)}</h6>
                            <h6 className="text-secondary">{booking_start_to_show} a {booking_end_to_show}</h6>

                        </Col>
                    </Row>
                    <Row>
                        <Col className="mt-2 offset-1">
                            <h2>Lugar</h2>
                        </Col>
                    </Row>
                    <Row className="mb-2">
                        <Col xs="1"><FontAwesomeIcon icon={faMapMarkerAlt} size="2x" className="text-danger"/></Col>
                        <Col>
                            <h6 className="text-secondary">{address.map(a => <>{a}, </>)}</h6>

                        </Col>
                    </Row>
                    {canInviteBooking(item) && invites && <>
                        <Row>
                            <Col className="mt-2 offset-1">
                                <h1>Invitados</h1>
                            </Col>
                        </Row>
                        <Row className="mb-2">
                            <Col xs="1"><FontAwesomeIcon icon={faUser} size="2x"/></Col>
                            <Col>
                                <h5 className="text-secondary">{invites}</h5>
                            </Col>
                        </Row>
                    </>}
                    {detalle.length > 0 && <>
                        <Row>
                            <Col className="mt-4 offset-1">
                                <h1>Información Adicional</h1>
                            </Col>
                        </Row>
                        <Row className="mb-2"><Col>
                            {detalle.map(row => <Row><Col><h5>{row.label}</h5></Col><Col>
                                <div>{row.value}</div>
                            </Col></Row>)}
                        </Col></Row> </>
                    }

                    {isBookingEntryTimeFrame(item) &&
                    <Row>
                        <Col className="p-2 mb-lg-2 d-flex justify-content-center">
                            <Button color="primary" className="w-75 btn primary btn-lg"
                                    onClick={() => this.props.history.push(toCheckinView)}
                            >Escanear QR &nbsp;<FontAwesomeIcon size={icon_size}
                                                                icon={faExpand}
                                                                color={icon_color}/>
                            </Button>
                        </Col>
                    </Row>}

                    {isBookingEnabled(item)  &&
                    <Row>
                        <Col className="p-2 mb-lg-2 d-flex justify-content-center">
                            <Button color="primary" className="w-75 btn primary btn-lg"
                                    onClick={() => this.props.history.push(toEditBookingView)}
                            >Modificar reserva &nbsp;<FontAwesomeIcon size={icon_size}
                                                                      icon={faEdit}
                                                                      color={icon_color}/>
                            </Button>
                        </Col>
                    </Row>}

                    {isBookingEntryTimeFrame(item) && hasChildren(store) &&
                    <Row>
                        <Col className="p-2 mb-lg-2 d-flex justify-content-center">
                            <Button color="primary" className="w-75 btn primary btn-lg"
                                    onClick={() => this.props.history.push(toVirtualQueue)}
                            >Ir a fila virtual &nbsp;<FontAwesomeIcon size={icon_size}
                                                                icon={faClock}
                                                                color={icon_color}/>
                            </Button>
                        </Col>
                    </Row>}

                    {isBookingEnabled(item) && canDeleteBooking(item) &&
                    <Row>
                        <Col className="p-2 mb-lg-2 d-flex justify-content-center">
                            <Button color="secondary" className="w-75 btn primary btn-lg"
                                    onClick={() => this.props.history.push(toCancelView)}
                            >Cancelar cita  &nbsp;<FontAwesomeIcon
                                size={icon_size}
                                icon={faTimes}
                                color={icon_color}
                            /></Button>
                        </Col>
                    </Row>
                    }

                    <Row>
                        <Col className="p-2 mb-lg-2 d-flex justify-content-center">
                            <Button id="GoBookingListButton" style={{marginLeft: '2rem'}}
                                    className='text-left'
                                    color="default"
                                    onClick={() => this.props.history.push(toBookingList)}
                            ><FontAwesomeIcon
                                icon={faLessThan}/> Ir a Mis Reservas</Button>
                        </Col>
                    </Row>

                </> : <ErrorAlert text="Reserva inexistente"/>}

            </Container>
        );
    }
}

export default withRouter(BookingDetail);
