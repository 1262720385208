import * as Actions from '../constants/ReducerActions/Auth'
import * as AppActions from "../constants/ReducerActions/App";
import * as BookingDetail from '../constants/ReducerActions/BookingDetail'
import * as BookingList from '../constants/ReducerActions/BookingList'
import * as Booking from '../constants/ReducerActions/Booking'
import * as ForgotPassword from '../constants/ReducerActions/ForgotPassword'
import * as Login from '../constants/ReducerActions/Login'
import * as LoginFulfillmentActions  from "../constants/ReducerActions/LoginFulfillment";
import * as OAuthActions from "../constants/ReducerActions/OAuth";
import * as SettingsActions from "../constants/ReducerActions/Settings";
import * as StoreActions from "../constants/ReducerActions/Store";
import * as ReducerActions from "../constants/ReducerActions/Profile";
import * as VirtualQueue from '../constants/ReducerActions/VirtualQueue'
import * as VirtualQueueList from '../constants/ReducerActions/VirtualQueueList'
import * as VirtualQueueDetail from '../constants/ReducerActions/VirtualQueueDetail'

const AuthAction = {
    login: (access_token, refresh_token) => {
        return {type: Actions.LOGIN, payload: {access_token: access_token, refresh_token: refresh_token}}
    },
    logout: () => (dispatch, getState) => {
        dispatch({type: ReducerActions.CLEAN_PROFILE_DATA});
        dispatch({type: StoreActions.SCREEN_LOAD});
        dispatch({type: BookingDetail.SCREEN_LOAD});
        dispatch({type: Login.SCREEN_LOAD});
        dispatch({type: BookingList.SCREEN_LOAD});
        dispatch({type: Booking.SCREEN_LOAD});
        dispatch({type: ForgotPassword.SCREEN_LOAD});
        dispatch({type: VirtualQueue.SCREEN_LOAD});
        dispatch({type: VirtualQueueList.SCREEN_LOAD});
        dispatch({type: VirtualQueueDetail.CLEAN_VIRTUAL_QUEUE_DATA});
        dispatch({type: Actions.LOGOUT});
        dispatch({type: AppActions.APP_RESET});
        dispatch({type: SettingsActions.UNSET_VIRTUAL_QUEUE})
        dispatch({type: LoginFulfillmentActions.RESET})
        dispatch({type: OAuthActions.OAUTH_RESET})
    }
};

export default AuthAction
