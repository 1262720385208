import * as Action from '../constants/ReducerActions/Auth'

const AuthReducer = (state = {access_token: null, refresh_token: null}, {type, payload}) => {
    switch (type) {
        case Action.LOGIN_START:
            return {waiting: true, access_token: null, refresh_token: null};
        case Action.LOGIN:
            return {access_token: payload.access_token, refresh_token: payload.refresh_token};
        case Action.LOGOUT:
            return {access_token: null, refresh_token: null};
        default:
            return state;
    }
}
export default AuthReducer;
