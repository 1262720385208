import * as Action from '../constants/ReducerActions/Identity'
import InitialState from '../constants/InitialState/Identity'

const IdentityReducer = (state = InitialState, {type, payload}) => {
    switch (type) {
        case Action.SCREEN_LOAD:
            return {...InitialState, ...payload};
        case Action.LIST :
            return {...state, waiting: true};
        case Action.LIST_DONE    :
            return {...state, waiting: false, list: [...payload]};
        case Action.LIST_ERROR   :
            return {...state, waiting: false, errors: {...payload}};
        case Action.LIST_PERSONAL :
            return {...state, waitingPersonal: true};
        case Action.LIST_PERSONAL_DONE    :
            return {...state, waitingPersonal: false, personal: [...payload]};
        case Action.LIST_PERSONAL_ERROR   :
            return {...state, waitingPersonal: false, errors: {...payload}};
        case Action.LIST_SOCIAL :
            return {...state, waitingSocial: true};
        case Action.LIST_SOCIAL_DONE    :
            return {...state, waitingSocial: false, social: [...payload]};
        case Action.LIST_SOCIAL_ERROR   :
            return {...state, waitingSocial: false, errors: {...payload}};
        default:
            return state;
    }
};

export default IdentityReducer;
