import React from 'react';
import LoginButtons from "../../components/Buttons/SocialLoginButtons";
import {GoLoginButton, GoSignUpButton} from "../../components/Buttons";
import {useLocation} from "react-router-dom";
import {isNotAppIntern} from 'helper';
import {Container, Row} from 'reactstrap';
import {connect} from "react-redux";
import ErrorAlert from "../../components/Alert/ErrorAlert";
// import {APP_RESET} from "../../constants/ReducerActions/App";
// import {SCREEN_LOAD} from "../../constants/ReducerActions/Store";
// import {UNSET_VIRTUAL_QUEUE} from "../../constants/ReducerActions/Settings";

const PublicHomeView = (props) => {

    const location = useLocation();

    const socialLoginErrors = location.state?.error;

    // Clears navigation state. To delete errors persisted in navigation state
    window.history.replaceState(null, '')
    props.onInit();

    return <>
        <div className="mt-3" id="PublicHomeView">
            {socialLoginErrors && <Container>
                <Row>
                    <ErrorAlert text={socialLoginErrors}/>
                </Row>
            </Container>}
            <h1>Bienvenido a Turnera</h1>
            {isNotAppIntern() && <p>Inicia sesión y podrás consultar tus reservas</p>}
        </div>
        {isNotAppIntern() && <GoLoginButton className="my-sm-5 my-2" navigateState={location.state}/>}
        {isNotAppIntern() && <GoSignUpButton className="my-sm-5 my-2" navigateState={location.state}/>}
        <LoginButtons from="/"/>

    </>;
}

const mapStateToProps = (state) => {

    return {
        waiting: state.OAuthReducer.waiting
            || state.RegisterReducer.waiting
            || state.ProfileReducer.waiting
    }
};

const mapDispatchToProps = (dispatch, getState) => {
    return {
        onInit: () => {
            // dispatch({type: APP_RESET});
            // dispatch({type: UNSET_VIRTUAL_QUEUE});
            // dispatch({type: SCREEN_LOAD});

        }
    };
};


export default connect(mapStateToProps, mapDispatchToProps)(PublicHomeView);
