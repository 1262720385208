import * as Action from '../constants/ReducerActions/VirtualQueueList'

const VirtualQueueListReducer = (state = {list: []}, {type, payload}) => {
    switch (type) {
        case Action.SCREEN_LOAD:
            return {
                list: [], ...payload};
        case Action.LIST :
            return {...state, waiting: true};
        case Action.LIST_DONE    :
            return {...state, waiting: false, list: {...payload}};
        case Action.LIST_ERROR   :
            return {...state, waiting: false, errors: {...payload}};
        default:
            return state;
    }
};

export default VirtualQueueListReducer;
