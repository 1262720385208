import Api from './Api'
import config from 'configReactApp'

class _ApiRegister extends Api {

    register(data, config) {
        return this.create(data, config);
    }

    phone(data, config) {
        return this.post('phone', data, config);
    }

}

const ApiRegister = new _ApiRegister({endpoint: config('api.endpoint.register')});

export default ApiRegister
