import React, {useState} from 'react';
import {
    Carousel,
    CarouselItem,
    CarouselControl,
    CarouselIndicators,
    // CarouselCaption
} from 'reactstrap';

import ImageALC from 'assets/img/alc-large.png';
import ImageCC from 'assets/img/cc-large.png';
import ImagePFC from 'assets/img/pfc-large.png';
// import LogoTurnera from "assets/img/logo-turnera.png";

const items = [
    { altText: 'Alto Las Condes', src: ImageALC },
    { altText: 'Costanera Center', src: ImageCC },
    { altText: 'Portal Florida Center', src: ImagePFC },
];

items.forEach((item, i) => {
    item.id = i + 1;
});

const Slider = (props) => {
    const [activeIndex, setActiveIndex] = useState(0);
    const [animating, setAnimating] = useState(false);

    const next = () => {
        if (animating) return;
        const nextIndex = activeIndex === items.length - 1 ? 0 : activeIndex + 1;
        setActiveIndex(nextIndex);
    }

    const previous = () => {
        if (animating) return;
        const nextIndex = activeIndex === 0 ? items.length - 1 : activeIndex - 1;
        setActiveIndex(nextIndex);
    }

    const goToIndex = (newIndex) => {
        if (animating) return;
        setActiveIndex(newIndex);
    }

    const slides = items.map((item) => {
        const css = {
            backgroundImage: `url(${item.src})`,
            'width': '100%',
            'height': '100%',
            // 'background-size': '50%',
            backgroundPosition: 'center center',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            backgroundAttachment: 'inherit'


        }
        // console.log(css)
        return (
            <CarouselItem
                tag="div"
                key={item.id}
                onExiting={() => setAnimating(true)}
                onExited={() => setAnimating(false)}
            >
                <div style={css}/>
                {/*<CarouselCaption className="text-danger" captionText={item.caption} captionHeader={item.caption}/>*/}
            </CarouselItem>
        );
    });

    return (
        <>
            {/*<style>*/}
            {/*    {*/}
            {/*        `.custom-tag {*/}
            {/*  max-width: 100%;*/}
            {/*  height: 500px;*/}
            {/*  background: black;*/}
            {/*}`*/}
            {/*    }*/}
            {/*</style>*/}
            <Carousel
                activeIndex={activeIndex}
                next={next}
                previous={previous}
            >
                <CarouselIndicators items={items} activeIndex={activeIndex} onClickHandler={goToIndex}/>
                {slides}
                <CarouselControl direction="prev" directionText="Previous" onClickHandler={previous}/>
                <CarouselControl direction="next" directionText="Next" onClickHandler={next}/>
            </Carousel>
        </>
    );
}

export default Slider;
