import React, {Component} from 'react'
import Header from '../components/Header/Header'
import Footer from '../components/Footer/Footer'
import {connect} from "react-redux";
import PropTypes from 'prop-types';
import {Col, Container, Row} from 'reactstrap'
import LogoTurnera from "assets/img/logo-turnera.png";
import {isAuth} from 'isAuth';
import Sidebar from "../components/Menu/Sidebar";
import {Link} from "react-router-dom"
import _ from 'lodash'
import StoreItem from "../components/Lists/StoreItem";
import config from "../configReactApp";
import Waiting from "../components/Waiting/Waiting";

class PublicLayout extends Component {
    constructor(props) {
        super(props)
        this.myRef = React.createRef()   // Create a ref object
    }

    componentDidMount() {
        try {
            this.myRef.current.scrollTo(0, 0);
        } catch (e) {
        }
    }

    toShow = (obj, e) => e.reduce((i, c) => c || ((typeof (i) === "function" && i()) || i === true), false) && obj

    mainContentClassNames = () => {
        let dClassNames = [];
        dClassNames.push("mt-2 mt-md-5 pt-4 box-shadow overflow-y");
        dClassNames.push(isAuth() ? 'is-auth my-sm-5' : 'is-anonymous')
        dClassNames.push(this.props.showHeader ? 'showHeader' : 'noHeader')
        dClassNames.push(this.props.showFooter ? 'showFooter' : 'noFooter')
        dClassNames.push(this.props.store ? 'showStore' : 'noStore')
        return dClassNames.join(' ');
    }

    getAside = () => {
        let aside = [];
        if (isAuth()) {
            aside.push(<Col key="menu" className="bg-gradient-light p-0 d-md-block d-none" align="center" xs={3} xl={2}
                            tag="aside">
                <Sidebar/>
            </Col>)
        } else {
            let cn = "py-2";
            if (this.props.showStore && this.props.store) {
                cn += ' d-md-block d-none';
            }
            aside.push(<Col key="logo_turnera" className={cn} align="center" sm={4} xl={6} md={3} lg={3}>
                <Link to={this.props.homesite}><img src={LogoTurnera} alt="LogoTurnera" id="LogoTurnera"
                                                    className="d-block"/>
                </Link>
            </Col>)
        }
        if (this.props.showStore && this.props.store) {
            aside.push(<Col key="store_item" id="StoreItemResume" className="py-2 bg-white d-block d-sm-none" align="center" sm={4} xl={6} md={3} lg={3}>
                <StoreItem style={{border: 'none'}}
                           key={this.props.store.id}
                           item={this.props.store}
                           disableClick enabled={true}/>
            </Col>);
        }
        return aside;

    }
    getTitle = () => _.get(this, 'props.data.title', this.props.title)
    getContent = () => this.props.children ?? <></>
    getHeader = () => this.toShow(<Header title={this.getTitle()} showBackButton={this.props.showBackButton}/>,
        [this.props.showHeader, this.props.data.forceHeader])
    getFooter = () => this.props.showFooter && <Footer title={this.props.title}/>
    getMT = () => "pt-sm-0 mt-sm-0 pb-7 " // + (this.props.showHeader || isAuth() ? "mt-7" : '')
    getWrap = (c) => <Container fluid className={this.getMT()} tag="section">
        <Row className="justify-content-between">
            {this.getAside()}
            <Col sm={{size: 8, offset: 0}} lg={{size: 6}} xl={{size: 5}}>
                <Container>
                    <Row>
                        <Col
                            className={this.mainContentClassNames()}
                            id="MainContent">
                            {c}
                        </Col>
                    </Row>
                </Container>
            </Col>
        </Row>
    </Container>

    render() {
        // console.log(this.props)
        const cn = ["PublicLayout", "background", this.props.country];
        if (isAuth()) cn.push('is-auth');
        return <div className={cn.join(' ')} ref={this.myRef}>
            {this.getHeader()}
            <Waiting waiting={this.props.loading}>{this.getWrap(this.getContent())}</Waiting>
            {this.getFooter()}
        </div>

    }
}


const mapStateToProps = state => {
    return {
        loading: state.AppReducer.load || false,
        data: state.AppReducer || null,
        store: state.StoreReducer.parent || null,
        homesite: state.SettingsReducer.dashboardRoute || config('app.homesite'),
        country: _.get(state.ProfileReducer, 'city.country.iata', 'cencosud').toLowerCase(),
    }
};

PublicLayout.propTypes = {
    showHeader: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
    showFooter: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
    showStore: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
    title: PropTypes.string
}
PublicLayout.defaultProps = {
    showHeader: true,
    showFooter: false,
    showStore: false,
    title: process.env.REACT_APP_TITLE
}

export default connect(mapStateToProps
)(PublicLayout);
