import * as ReducerActions from '../constants/ReducerActions/EditProfile'
import ProfileAction from '../actions/Profile';
import ApiProfile from "api/ApiProfile";
import EditProfileForm from "constants/Forms/EditProfile";
import { validate, validateIdentityField } from "./FieldValidator";


const EditProfileAction = {
    FetchRequest: '',
    initAction: () => {
        return { type: EditProfileAction.FetchRequest }
    },

    addError: (dispatch) => (errors) => dispatch({ type: ReducerActions.UPDATE_ERROR, payload: errors }),

    onValidateError: (dispatch) => (errors) => dispatch({ type: ReducerActions.SUBMIT_ERROR, payload: errors }),

    validate: (data, dispatch) => {
        // Clean all errors
        EditProfileAction.onValidateError(dispatch)({});
        const generalValidation = validate(data, EditProfileForm.fields, EditProfileAction.onValidateError(dispatch));
        const identityValidation = validateIdentityField(data.identity_id, data.identity_value, EditProfileAction.addError(dispatch));
        return generalValidation && identityValidation;
    },

    save: () => (dispatch, getState) =>
        new Promise(function (resolve, reject) {
            if (EditProfileAction.validate(getState().EditProfileReducer, dispatch)) {
                let phone;
                if (getState().EditProfileReducer.phone !== getState().ProfileReducer.phone){
                    phone = getState().EditProfileReducer.phone?(getState().EditProfileReducer.phone.startsWith('+') ? getState().EditProfileReducer.phone :
                    getState().EditProfileReducer.prefix + getState().EditProfileReducer.phone):"" 
                }
                const data = {
                    id: getState().EditProfileReducer.id,
                    first_name: getState().EditProfileReducer.first_name,
                    last_name: getState().EditProfileReducer.last_name,
                    phone,
                    identity_id: getState().EditProfileReducer.identity_id,
                    identity_value: getState().EditProfileReducer.identity_value,
                }
                ApiProfile
                    .update(data)
                    .then(response => {
                        return dispatch(ProfileAction.loadProfileData())
                    })
                    .then(response => resolve(response))
                    .catch(error => {
                        if (error.response) {
                            dispatch(EditProfileAction.showErrors(error.response.data))
                            reject(error.response.data)
                        } else {
                            dispatch(EditProfileAction.showErrors(error))
                            reject(error)
                        }
                    })
                    ;
            } else {
                reject()
            }
        })
    ,

    showErrors: (response) => {
        return { type: ReducerActions.SUBMIT_ERROR, payload: response || null }
    },

    success: (response) => {
        return { type: ReducerActions.SUBMIT_DONE, payload: response.data || null };
    },

};

export default EditProfileAction
