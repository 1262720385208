import React from 'react';
import {Spinner} from 'reactstrap';

const OverlaySpinner = (props) => {
    const container = {
        'width': '100%',
        'height': '100%',
        'position': 'absolute',
        'z-index': '100',
        'left': '0',
        'top': '0',
        'display': 'flex',
        'flex-direction': 'column',
        'justify-content': 'center',
        'align-items': 'center'
    }

    const backdrop = {
        'width': '100%',
        'height': '100%',
        'position': 'absolute',
        'z-index': '100',
        'left': '0',
        'top': '0',
        'background-color': 'rgba(230,230,230, 0.6)'
    }

    return (
        <div style={container}>
            {props.backdrop ? <div style={backdrop}></div> : null}
            <Spinner style={{ width: '3rem', height: '3rem' }} color="primary" />
        </div>
    );
}

export default OverlaySpinner;
