const InitialState = {
    country_id: null,
    city_id: null,
    identity_id: null,
    identity_value: null,
    accept_terms_and_conditions: false,
    prefix: '+00',
    redirect_to: null,
};
export default InitialState
