import React, {Component} from 'react';
import {Container, Jumbotron} from 'reactstrap';
import PropTypes from "prop-types";
import Inflector from "../../Inflector";

class JumbotronVirtualQueue extends Component {
    render() {
        const id = Inflector.classify(this.props.pre ?? '');
        return <Jumbotron className="JumbotronVirtualQueue py-2 " id={'JumbotronVirtualQueue' + id}>
            <Container>
                {this.props.pre ? <h4 className="text-secondary"><small>{this.props.pre}</small></h4> : ''}
                {this.props.children}
            </Container>
        </Jumbotron>
    }
}

JumbotronVirtualQueue.propTypes = {
    pre: PropTypes.any,
}

JumbotronVirtualQueue.defaultProps = {
    pre: '',
}

export default JumbotronVirtualQueue
