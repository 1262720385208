const InitialState = {
    pair: {
        current: 0,
        max: 0
    },
    percentage: {
        value: 0
    },
};
export default InitialState
