import {
    CityId,
    CountryId,
} from './Fields'

const EditLocationForm = {
    'name': 'User',
    'title': {'text': 'Cambiar ubicación'},
    'description': 'Agenda tu visita al mall y salta la fila de tus tiendas favoritas con Turnos Cencosud',
    'fields': {
        'country_id': CountryId,
        'city_id': CityId,
    },
    'buttons': {
        'submit': {
            'type': 'submit',
            'block': true,
            'color': 'primary',
            'label': 'Guardar'

        }
    }

}

export default EditLocationForm
