import React, {Component} from "react";
import {connect} from "react-redux";
import {toOptions} from '../../components/Forms/Utils'
import {Input} from 'reactstrap';
import Inflector from "Inflector";
import ArgentinaTermsOfService from "./ArgentinaTermsOfService";
import PeruTermsOfService from "./PeruTermsOfService";
import ChileTermsOfService from "./ChileTermsOfService";
import {withRouter} from "react-router-dom";
// import {SCREEN_LOAD} from "../../constants/ReducerActions/Store";
// import {UNSET_VIRTUAL_QUEUE} from "../../constants/ReducerActions/Settings";


const getTerms = (country) => {
    switch (country) {
        case "Argentina":
            return <ArgentinaTermsOfService/>
        case "Peru":
            return <PeruTermsOfService/>
        case "Chile":
            return <ChileTermsOfService/>
        default:
            return <ArgentinaTermsOfService/>
    }
}

class TermsOfService extends Component {


    constructor(props) {
        super(props);
        props.onInit();
        this.state = {
            selectedCountry: Object.values(props.countries).length > 0 ? this.getSelectedCountry(props) : null
        }
    }

    getSelectedCountry = (props) => {
        let countryName = Object.values(props.countries).find(c => c.id.toString() === props.match.params?.country?.toString())?.name;
        if (!countryName) countryName = props.countries[0].name;
        return countryName;
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (!this.state.selectedCountry && Object.values(nextProps.countries).length > 0) {
            this.setState({selectedCountry: this.getSelectedCountry(nextProps)})
        }
    }

    changeCountryTerms = e => {
        this.setState({selectedCountry: Object.values(this.props.countries).find(c => c.id.toString() === e.currentTarget.value.toString())?.name})
    }

    render = () => {
        const elems = [];

        if (this.props.countries.length > 1) {
            if (!('city_id' in this.props.profile)) {
                elems.push(<>
                    <fieldset style={{fontSize: 'large'}}>País</fieldset>
                    <Input style={{marginBottom: '10px'}} onChange={this.changeCountryTerms}
                           type="select">{toOptions(this.props.countries).map(o => {
                        const id = o.value === this.props.placeholder ? 'Placeholder' : Inflector.classify(o.key.toString());
                        const input = {
                            id: Inflector.classify("country-terms") + id,
                            value: o.key,
                            key: o.key,
                            selected: o.value === this.state.selectedCountry
                        };

                        return <option {...input}>{o.value}</option>
                    })}</Input>
                </>);
            }
        }

        elems.push(<>{getTerms(this.state.selectedCountry)}</>);

        return elems;
    }
}

const mapStateToProps = state => {

    return {
        countries: state.CountryReducer.list || null,
        profile: state.ProfileReducer ?? {},
    }
};
const mapDispatchToProps = (dispatch, getState) => {
    return {
        onInit: () => {

            // dispatch({type: UNSET_VIRTUAL_QUEUE})
            // dispatch({type: SCREEN_LOAD});

        },
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(TermsOfService));
