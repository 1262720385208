import React from 'react';
import {Col, Media, Row} from 'reactstrap';
import LoadImage from '../Branding/Logo';
import {withRouter} from "react-router-dom"
import {connect} from "react-redux";
import config from 'configReactApp';
import {hasBookingsAvailable, hasChildren, hasVirtualQueue} from 'helper';
import _ from 'lodash'

const imgStyle = {
    maxHeight: '74px',
    maxWidth: '74px',
}

class StoreItem extends React.Component {

    render() {

        const store = this.props.item;
        const logo = _.get(store, 'logo');

        let to, disabled;
        disabled = false;
        if (this.props.isVirtualQueue) {
            if (hasChildren(store)) {
                to = config('app.virtualQueue.search') + "?parent_id=" + store.id;
            } else if (hasVirtualQueue(store)) {
                to = config('app.virtualQueue.new').replace(':store_id', store.id);
            }
        } else {
            if (hasBookingsAvailable(store)) {
                to = config('app.booking.new').replace(':store_id', store.id);
            } else {
                disabled = true;
            }
        }
        if (this.props.enabled !== null) {
            disabled = !this.props.enabled;
        }
        // console.log(to);
        // console.log(this.props);

        // se preguna por store.id porque este componente se usa en /search/virtual?parent_id
        // a modo de encabezado, y no como item de la lista, en cuyo caso store.id es igual a 0.


        return (
            <Media style={{
                paddingTop: '0rem',
                paddingBottom: '0.5rem',
                opacity: disabled ? '40%' : '100%',
                cursor: disabled ? 'default' : 'pointer',
            }} id={"Store" + store.id} onClick={() => this.props.onClick(this.props, to)}>
                <Media left middle>
                    <Media object src={LoadImage(logo)} alt={store.name} className="mr-2 mt-2" style={imgStyle}/>
                </Media>
                <Media body style={{margin: 'auto auto'}}>
                    <Media heading>{store.name}</Media>
                    {store.location ? store.location.split("\n").map((item, key) =>
                        <Row className="store_address" key={key}>
                            <Col><p style={{marginBottom: 0}}>{item}</p></Col>
                        </Row>
                    ) : <>
                        <Row className="store_address">
                            <Col><p style={{marginBottom: 0}}>{store.street}&nbsp;{store.street_number}</p></Col>
                        </Row>
                        <Row className="store_address">
                            <Col><p style={{marginBottom: 0}}>{store.suburb}, {store.state}</p></Col>
                        </Row></>}
                </Media>
            </Media>
        );

    }
}

StoreItem.defaultProps = {
    enabled: null
}

const mapDispatchToProps = (dispatch, getState) => {
    return {
        onClick: (props, to) => {
            if (!props.disableClick && (to ?? null) !== null) {
                props.history.push(to);
            }
        },
    };
};

export default withRouter(connect(null, mapDispatchToProps)(StoreItem))
