import config from "../../configReactApp";

const LoadImage = (logo_name) => {
    const logo = (logo_name || "logo-turnera.png");
    if (/^(http:\/\/|https:\/\/|\/)/.test(logo)) {
        return logo;
    }
    return process.env.PUBLIC_URL + (config("app.static_images_prefix_url") || '') + logo;
}

export default LoadImage
