import * as ReducerActions from '../constants/ReducerActions/LoginFulfillment'
import ApiProfile from '../api/ApiProfile';
import ProfileAction from './Profile';
import BookingAction from './Booking';
import CountryAction from "./Country";
import {isAuth} from "../isAuth";
import LoginFulfillmentForm from "../constants/Forms/LoginFulfillment";
import {validate, validateIdentityField} from "./FieldValidator";
import {isAppIntern} from 'helper';

const LoginFulfillmentAction = {
    FetchRequest: '',
    initAction: () => {
        return {type: LoginFulfillmentAction.FetchRequest}
    },

    addError: (dispatch) => (errors) => dispatch({type: ReducerActions.UPDATE_ERROR, payload: errors}),

    onValidateError: (dispatch) => (errors) => dispatch({type: ReducerActions.SUBMIT_ERROR, payload: errors}),

    validate: (data, dispatch) => {
        // Clean all errors
        LoginFulfillmentAction.onValidateError(dispatch)({});
        const generalValidation = validate(data, LoginFulfillmentForm.fields, LoginFulfillmentAction.onValidateError(dispatch));
        const identityValidation = validateIdentityField(data.identity_id, data.identity_value, LoginFulfillmentAction.addError(dispatch));
        return generalValidation && identityValidation;
    },

    save: () => (dispatch, getState) =>
        new Promise(function (resolve, reject) {
            let data = getState().LoginFulfillmentReducer;
            console.log(data)
            if (LoginFulfillmentAction.validate(data, dispatch)) {
                if (data.phone) {
                    if (!data.phone.startsWith('+')) {
                        data.phone = data.prefix + data.phone;
                    }
                } else {
                    delete data.phone;
                }
                if (!data.accept_terms_and_conditions && isAppIntern()) {
                    data.accept_terms_and_conditions = true;
                }
                delete data.prefix;
                dispatch({type: ReducerActions.SUBMIT});
                ApiProfile.update(data)
                    .then(response => {
                        return dispatch(ProfileAction.loadProfileData())
                    })
                    .then(response => {
                        if (response?.status === 200) {
                            if (getState().BookingReducer.pendingSave && isAuth()) {
                                return dispatch(BookingAction.save());
                            } else if (getState().VirtualQueueReducer.pendingSave && isAuth()) {
                                return dispatch(BookingAction.newVirtualQueue())
                            } else {
                                dispatch(LoginFulfillmentAction.success(response));
                                resolve(response)
                            }
                        }
                    })
                    .then(response => {
                        if (response?.status === 200 || response?.status === 201) {
                            dispatch(LoginFulfillmentAction.success(response));
                            resolve(response?.data?.queue ? {...response, queueSaved: true} : {
                                ...response,
                                bookingSaved: true
                            })
                        } else {
                            //console.log(response)
                            dispatch(LoginFulfillmentAction.showErrors(response));
                            resolve({...response, bookingError: true, storeId: getState().StoreTimeReducer.store_id})
                        }
                    })
                    .catch(error => {
                            let e;
                            if (error.response) {
                                e = error.response.data
                            } else {
                                e = error
                            }
                            dispatch(LoginFulfillmentAction.showErrors(e));
                            reject(e);
                        }
                    )

            } else {
                reject("Datos no validos");
            }

        })
    ,

    showErrors: (response) => {
        console.log("Showing errors", response)
        return {type: ReducerActions.SUBMIT_ERROR, payload: response || null}
    },

    success: (response) => {
        return {type: ReducerActions.SUBMIT_DONE, payload: response.data || null};
    },

    setDefaultCountryAndCity: (countryId) => (dispatch, getState) => {
        dispatch({
            type: ReducerActions.EDIT_FORM,
            payload: {
                name: "country_id",
                value: countryId,
                type: 'change'
            }
        })
        dispatch(CountryAction.cities(true)).then(() => {
            if (Object.values(getState().CityReducer.list).filter(c => c.country_id.toString() === countryId.toString()).length === 1) {
                dispatch({
                    type: ReducerActions.EDIT_FORM,
                    payload: {
                        name: "city_id",
                        value: Object.values(getState().CityReducer.list).find(c => c.country_id.toString() === countryId.toString())?.id,
                        type: 'change'
                    }
                })
            }
        });
    },

};

export default LoginFulfillmentAction
