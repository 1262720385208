import React, {Component} from 'react';
import {connect} from 'react-redux'
import {Button, Col, Container, Row} from 'reactstrap';
import JumbotronVirtualQueue from "../../components/Jumbotron/JumbotronVirtualQueue";
import {SET_PARENT} from 'constants/ReducerActions/Store'
import BookingAction from "../../actions/Booking";
import {withRouter} from 'react-router-dom'
import Waiting from "../../components/Waiting/Waiting";
import {isAuth} from "../../isAuth";
import StoreAction from '../../actions/Store';
import StoreItem from '../../components/Lists/StoreItem';
import _ from "lodash";
import ErrorAlert from 'components/Alert/ErrorAlert';
import config from "../../configReactApp";


class VirtualQueueNewView extends Component {

    queueInfoTimer = null;

    constructor(props) {
        super(props)
        this.state = {store_id: this.props.match.params.store_id};
        this.props.firstGetQueue(this.state.store_id);
    }


    componentDidMount() {
        const {store_id} = this.state;
        this.queueInfoTimer = setInterval(() => {
            this.props.getQueue(store_id)
            // console.log(this.queueInfoTimer)
        }, 30000)
    }

    componentWillUnmount() {
        // console.clear()

        clearInterval(this.queueInfoTimer);
        this.queueInfoTimer = null;
        // console.log(this.queueInfoTimer)
    }


    submitNewVirtualQueue = (e) => {
        if (isAuth()) {
            this.props.onSubmit().then((resp) => this.props.history.push("/queue/" + _.get(resp, 'data.id')));
        } else {
            this.props.setPending();
            this.props.history.push(`${config('app.guest_home')}`);
        }
    }

    render() {

        const defaultQueue = Object.values(this.props.queues).find(q => q.name === "default");

        const waiting = _.get(defaultQueue, 'waiting', 0) || 0;

        const actual = _.get(defaultQueue, 'actual', 0) || 0;

        return <Waiting id={"VirtualQueueNew" + this.store_id} waiting={this.props.waiting}>
            <h2 className="d-none d-sm-block">{_.get(this, 'props.store.parent.name', 'Fila Virtual')}</h2>
            <Container fluid id="BookingNewVirtualQueueView" className="virtual-queue-view">
                <Row>
                    <Col id="StoreResume"
                         className="my-4 mx-3 pb-xl-3 mb-2 mb-xl-4 pl-2 border-dark bg-gradient-light text-dark">
                        <StoreItem enabled={true} key={this.props.store.id}
                                   item={this.props.store}
                                   disableClick/>
                    </Col>
                </Row>
                <Row><Col className="text-center" xs={6}>

                    <JumbotronVirtualQueue pre="Actual">
                        <h1>{actual}</h1>
                    </JumbotronVirtualQueue>
                </Col><Col className="text-center" xs={6}>
                    <JumbotronVirtualQueue pre="Esperando">
                        <h1>{waiting}</h1>
                    </JumbotronVirtualQueue>
                </Col><Col className="text-center">
                    {this.props.errors && <ErrorAlert text="Ocurrio un error"/>}
                    <Button type="submit" color="primary" block className="my-sm-5 my-2 mt-5" outline={false}
                            onClick={(e) => {
                                e.preventDefault();
                                this.submitNewVirtualQueue()
                            }}>Pedir turno</Button>
                </Col>
                </Row>
            </Container>
        </Waiting>
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        errors: state.VirtualQueueReducer.errors || null,
        waiting: state.VirtualQueueReducer.waiting || state.AppReducer.load || state.StoreReducer.waiting,
        store: Object.values(state.VirtualQueueReducer.list || {})?.find(q => q.name === "default")?.store || {
            id: 0, name: "",
            street: "",
            street_number: "",
            suburb: "",
            state: "",
        },
        queues: state.VirtualQueueReducer.list || []
    }
};

const mapDispatchToProps = (dispatch, getState) => {
    return {
        onInit: (store_id, preserveErrors) => {
            // dispatch({type: SUBMIT});
            // dispatch({
            //     type: SET_STORE,
            //     payload: store_id
            // });


        },
        onSubmit: () => {
            return dispatch(BookingAction.newVirtualQueue());
        },
        setPending: () => {
            dispatch(BookingAction.setPendingQueueSaveOnLogin())
        },
        getQueue: (store) => {
            console.log('getQueue', store);
            dispatch(StoreAction.queues(store))
        },
        firstGetQueue: (store) => {
            console.log('firstGetQueue', store);
            dispatch(StoreAction.queues(store)).then((response) => {
                const s = Object.values(response || {})?.find(q => q.name === "default")?.store || {
                    id: 0, name: "",
                    street: "",
                    street_number: "",
                    suburb: "",
                    state: "",
                }
                // let t = _.get(s, 'parent.name', s.name);
                dispatch({type: SET_PARENT, payload: s.parent});
                // dispatch({type: APP_TITLE, payload: t});
                // dispatch({type: APP_FORCE_HEADER, payload: !_.isEmpty(t) || t});
                // dispatch({type: APP_FORCE_HEADER, payload: true});

            })
        }
    }
};


export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(VirtualQueueNewView));

