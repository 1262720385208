import React, {Component} from 'react';
import {Col, Container, Nav, NavItem, NavLink, Row} from 'reactstrap';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {withRouter} from "react-router";
import {connect} from "react-redux";

import ProfileResume from './ProfileResume';
import {profileMenu as menu} from "../../routes";


class ProfileMenu extends Component {

    constructor(props) {
        super(props);
        this.signOut = this.signOut.bind(this);
    }

    signOut(e) {
        e.preventDefault();

    }

    menuProfile(menu) {
        const menu_default_props = {color: "link", size: "lg", block: true, className: "text-left",}
        return menu.map((menu, i) => {

            if (menu.access() === 'granted') {
                const {label, icon, ...b} = {
                    ...menu_default_props,
                    ...menu,
                    onClick: () => {
                        if (menu.label === "Términos del Servicio") {
                            this.props.history.push(`terms-of-service/${Object.values(this.props.cities).find(c => c.id.toString() === this.props.profile.city_id.toString())?.country_id}`)
                        } else {
                            this.props.history.push(menu.path)
                        }
                    }
                };


                const nav_item = <NavItem key={i}>
                    {label === '_' ? <hr/> : <NavLink style={{cursor: 'pointer'}} {...b}>
                        {icon ? <FontAwesomeIcon icon={icon} className="mr-2"/> : ''}
                        {label}
                    </NavLink>}
                </NavItem>;
                return nav_item
            } else {
                return null
            }
        })

    }

    render() {

        const menu_profile = this.menuProfile(menu)
        return (
            <Container id="ProfileMenu">
                <Row className="d-none d-sm-block"><Col><h2>{this.props.title || "Mi Perfil"}</h2></Col></Row>
                <Row id="ProfileMenuResume"><Col>{ProfileResume(this.props.profile)}<hr/></Col></Row>
                <Row><Col>
                    <Nav vertical className="w-100">
                        {menu_profile}
                    </Nav></Col>
                </Row>
            </Container>

        );
    }
}

const mapStateToProps = state => {
    return {
        profile: state.ProfileReducer || null,
        cities: state.CityReducer.all || null,
    }
};

export default withRouter(connect(mapStateToProps)(ProfileMenu));
