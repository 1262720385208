import * as Action from '../constants/ReducerActions/Booking'
import InitialState from '../constants/InitialState/Booking'

const BookingReducer = (state = InitialState, {type, payload}) => {
    switch (type) {
        case Action.SCREEN_LOAD:
            return {...InitialState, data: {...state.data, ...payload}};
        case Action.SET_STORE_TIME_DATA:
                return {...state, data: {...state.data, ...payload}};
        case Action.EDIT_FORM :
            return {...state, data: {...state.data, [payload.name]: payload.value}};
        case Action.SUBMIT :
            return {...state, waiting: true};
        case Action.SUBMIT_DONE    :
            return {...state, waiting: false, ...payload};
        case Action.SUBMIT_ERROR   :
            return {...state, waiting: false, errors: {...payload}};
        case Action.SET_PENDING_BOOKING_SAVE:
            return {...state, pendingSave: true}
        case Action.UNSET_PENDING_BOOKING_SAVE:
            return {...state, pendingSave: false}
        case Action.CLEAR_ERRORS:
            return {...state, errors: null};
        default:
            return state;
    }
};

export default BookingReducer;
