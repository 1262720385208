import React, { Component } from 'react';
import StoreAction from "../../actions/Store"
import { connect } from "react-redux";
import SearchBar from "./SearchBar"

class StoreSearchBar extends Component {

    handleStoreSearchChange = (text) => {
        this.props.search(text)
    }

    render() {
        return <SearchBar handleSearchChange={this.handleStoreSearchChange} />
    }
}

const mapDispatchToProps = (dispatch, getState) => {
    return {
        search: (text) => {
            dispatch(StoreAction.filter(text))
        },
    };
};


export default connect(
    null,
    mapDispatchToProps
)(StoreSearchBar);
