import React, {Component} from 'react';
import {Col, Container, Row} from 'reactstrap';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowLeft} from "@fortawesome/free-solid-svg-icons";
import {isAuth} from 'isAuth';
import LogoTurnera from "assets/img/logo-turnera.png";
import config from "../../configReactApp";
import {connect} from "react-redux";
// import {APP_RESET} from "../../constants/ReducerActions/App";
// import {UNSET_VIRTUAL_QUEUE} from "../../constants/ReducerActions/Settings";
// import {SCREEN_LOAD} from "../../constants/ReducerActions/Store";

class Header extends Component {
    constructor(props) {
        super(props);

        this.goBack = this.goBack.bind(this);
    }

    goBack(e) {
        e.preventDefault();
        window.history.back()
    }

    checkDisplayBackButton() {
        const {showBackButton} = this.props;

        switch (window.location.pathname) {
            case config('app.homesite'):
            case config('app.after_login'):
            case config('app.bookings'):
            case config('app.booking.bookings'):
            case config('app.profile.root'):
            case config('app.virtualQueue.search'):
                return false;
            default:
                if (showBackButton !== undefined && showBackButton !== null && showBackButton !== false) {
                    return this.props.showBackButton;
                }
        }

    }

    topIcon = () => {
        const backButton = <FontAwesomeIcon icon={faArrowLeft} size="2x" className="float-left text-secondary"
                                            onClick={this.goBack}/>
        const {showBackButton} = this.props;
        const click = {};
        if (showBackButton) {
            click.onClick = this.goBack;
        }
        const homeButton = <img src={LogoTurnera} {...click} alt="LogoTurnera"
                                className="img-fluid float-left" style={{height: '6vh'}}/>
        if (isAuth()) {
            if (this.checkDisplayBackButton()) {
                return backButton;
            }
        }
        return homeButton;
    }

    render() {

        return (
            <Container fluid className="d-block d-sm-none text-dark bg-gradient-light pt-2 pb-2 sticky-top"
                       tag="header">
                {/*{isAuth() ? <Row>
                        <Col className="d-none d-sm-none d-md-none d-lg-block bg-primary text-white p-0">
                            <Menu title={this.props.title}/>
                        </Col>
                    </Row> :*/}
                <Row className="align-middle">
                    <Col className=" align-middle d-lg-none">
                        {this.topIcon()}
                        <h2 className="m-0 float-none text-center">{this.props.title}</h2>
                    </Col>
                </Row>
                {/*}*/}
            </Container>
        );
    }
}

const mapStateToProps = (state) => {

    return {homesite: state.SettingsReducer.dashboardRoute || config('app.after_login')}
};

const mapDispatchToProps = (dispatch, getState) => {
    return {
        onInit: () => {
            // dispatch({type: APP_RESET});
            // dispatch({type: UNSET_VIRTUAL_QUEUE});
            // dispatch({type: SCREEN_LOAD});

        }
    };
};


export default connect(mapStateToProps, mapDispatchToProps)(Header);


