import React from 'react';
import {Button} from 'reactstrap'
import {BootstrapColors, BootstrapButtonsType} from './BootstrapParameters'
import PropTypes from "prop-types";


class FormButton extends React.Component {


    render() {
        let align = this.props.className || '';
        align += this.props.type === BootstrapButtonsType.submit ? ' float-right' : '';
        let {label, children, ...b} = {className: align, onClick: e => e.preventDefault(), ...this.props};
        return <Button {...b}>{children ?? label}</Button>
    }
}

FormButton.propTypes = {
    type: PropTypes.oneOf(BootstrapButtonsType.keys()),
    color: PropTypes.oneOf(BootstrapColors.keys()),
    label: PropTypes.oneOfType([PropTypes.array, PropTypes.object, PropTypes.string]).isRequired
};
FormButton.defaultProps = {
    type: BootstrapButtonsType.submit,
    color: BootstrapColors.secondary,
    label: 'Enviar'
}

export default FormButton
