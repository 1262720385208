export const SCREEN_LOAD = "IDENTITY_SCREEN_LOAD";

// Listar Todas las identidades
export const LIST = "IDENTITY_LIST";
export const LIST_DONE = "IDENTITY_LIST_DONE";
export const LIST_ERROR = "IDENTITY_LIST_ERROR";

// Listar identidades personales
export const LIST_PERSONAL = "IDENTITY_LIST_PERSONAL";
export const LIST_PERSONAL_DONE = "IDENTITY_LIST_PERSONAL_DONE";
export const LIST_PERSONAL_ERROR = "IDENTITY_LIST_PERSONAL_ERROR";


// Listar redes sociales
export const LIST_SOCIAL = "IDENTITY_LIST_SOCIAL";
export const LIST_SOCIAL_DONE = "IDENTITY_LIST_SOCIAL_DONE";
export const LIST_SOCIAL_ERROR = "IDENTITY_LIST_SOCIAL_ERROR";
