import * as Action from '../constants/ReducerActions/VirtualQueueDetail'
import InitialState from "../constants/InitialState/VirtualQueueDetail";

const VirtualQueueDetailReducer = (state = InitialState, {type, payload}) => {
    switch (type) {
        case Action.CLEAN_VIRTUAL_QUEUE_DATA:
            return {...InitialState, ...payload}
        case Action.LOAD_VIRTUAL_QUEUE_START:
            return {...state, waiting: true}
        case Action.LOAD_VIRTUAL_QUEUE_DATA :
            return {...state, waiting: false, data: {...payload}};
        case Action.ERROR_VIRTUAL_QUEUE_DATA:
            return {...state, waiting: false, errors: {...payload}}
        default:
            return state;
    }
};

export default VirtualQueueDetailReducer;
