import React, { Component } from 'react';
import FormTag from "components/Forms/FormTag";
import { connect } from 'react-redux';
import { EDIT_FORM, SCREEN_LOAD, SUBMIT } from 'constants/ReducerActions/EditLocation'
import EditLocationAction from "../actions/EditLocation";
import EditLocationForm from 'constants/Forms/EditLocation'
import { Container } from 'reactstrap'
import { withRouter } from "react-router-dom"
import { GoBackButton } from "components/Buttons";
import TermsAndConditionsCheck from "components/Forms/FormTermsAndConditions";
import CheckChangeLocation from "components/Forms/CheckChangeLocation";
import ErrorAlert from 'components/Alert/ErrorAlert';
import CountryAction from "../actions/Country";
import { toOptions, InputEvts } from 'components/Forms/Utils'
import Waiting from 'components/Waiting/Waiting';
import config from 'configReactApp';

class EditLocationView extends Component {
    args = {};

    state = {
        missing_terms: false,
        missing_location_change_accepted: false,
    }

    constructor(props) {
        super(props);
        props.onInit(props.initialData || {});
        props.onCountryChange(props.initialData?.city?.country_id);
    }

    handleEditLocationSubmit = (e) => {
        e.preventDefault();
        this.setState({ missing_terms: !this.props.data.accept_terms_and_conditions });
        this.setState({ missing_location_change_accepted: !this.props.data.accept_location });
        if (this.props.data.accept_terms_and_conditions && this.props.data.accept_location) {
            this.setState({ missing_terms: false });
            this.setState({ missing_location_change_accepted: false });
            this.props.onSubmit(e)
            .then((response) => {
                if (response.status === 200) {
                    this.props.history.push(config('app.after_login'));
                }
            })
            .catch(err => console.log("error", err))
        }
    }

    handleOnCountryChange = e => {
        console.log('onCountryChange');
        e && e.preventDefault();
        const selectedCountry = e.target.value;
        this.props.onCountryChange(selectedCountry);
    }


    prepare() {
        this.args = {
            ...EditLocationForm,
            onChange: this.props.onChange,
            onSubmit: this.handleEditLocationSubmit,

            inline: false,

        };
        const country_on_events = new InputEvts();
        country_on_events.add('change', this.props.onChange);
        country_on_events.add('change', this.handleOnCountryChange);
        const countryOptions = toOptions(this.props.countries)
        this.args.fields.country_id.items = countryOptions;
        if (countryOptions.length !== 1) {
            this.args.fields.country_id.placeholder = "Seleccione un país";
            this.args.fields.country_id.selectedValue = this.props.data.country_id ?
                countryOptions.find(o => o.key.toString() === this.props.data.country_id.toString())?.value : this.args.fields.country_id.placeholder;
        } else {
            this.args.fields.country_id.placeholder = null;
            this.args.fields.country_id.selectedValue = null;
        }
        this.args.fields.country_id.e = country_on_events;
        const cityOptions = toOptions(this.props.cities);
        this.args.fields.city_id.items = cityOptions;
        if (cityOptions.length !== 1) {
            this.args.fields.city_id.placeholder = "Seleccione una ciudad";
            this.args.fields.city_id.selectedValue = this.props.data.city_id ?
                cityOptions.find(o => o.key.toString() === this.props.data.city_id.toString())?.value : this.args.fields.city_id.placeholder;
        } else {
            this.args.fields.city_id.placeholder = null;
            this.args.fields.city_id.selectedValue = null;
        }
    }

    render() {
        this.prepare();
        const form = <FormTag name='EditLocation' {...this.args} data={this.props.data} footer={
            <>
                {this.state.missing_location_change_accepted && <ErrorAlert text="Debe aceptar el cambio de ciudad" />}
                <CheckChangeLocation checked={this.props.data.accept_location} onValueChange={this.props.onLocationCheckChange} />
                {this.state.missing_terms && <ErrorAlert text="Debe aceptar los términos y condiciones" />}
                <TermsAndConditionsCheck checked={this.props.data.accept_terms_and_conditions} country={this.props.data.country_id} onValueChange={this.props.onTermsCheckChange} />
            </>} />
        return <div className="my-3">
            <Waiting waiting={this.props.waiting} >
                {form}
                <Container className="my-4"><GoBackButton /></Container>
            </Waiting>
        </div>
    }
}

const mapStateToProps = state => {

    return {
        initialData: state.ProfileReducer || null,
        data: state.EditLocationReducer || null,
        countries: state.CountryReducer.list || null,
        cities: state.CityReducer.list || null,
        waiting: state.EditLocationReducer.waiting,
    }
};

const mapDispatchToProps = (dispatch, getState) => {
    return {
        onInit: (data) => {
            dispatch({
                type: SCREEN_LOAD,
                payload: { country_id: data.city.country_id, city_id: data.city_id }
            })
        },
        onChange: e => dispatch({
            type: EDIT_FORM,
            payload: {
                name: e.target.name,
                value: e.target.value,
                type: e.target.type,
                files: e.target.files
            }
        }),
        onSubmit: e => {
            e.preventDefault();
            EditLocationAction.FetchRequest = SUBMIT;
            return dispatch(EditLocationAction.save());
        },
        onCountryChange: (countryId) => {
            dispatch(CountryAction.cities(countryId))
                .then((response) => {
                    dispatch({
                        type: EDIT_FORM,
                        payload: {
                            name: "city_id",
                            value: response.data?.length === 1 ? response.data[0].id : null,
                            type: "change",
                        }
                    })
                });
        },
        onTermsCheckChange: value => dispatch({
            type: EDIT_FORM,
            payload: {
                name: 'accept_terms_and_conditions',
                value,
                type: 'change',
            }
        }),
        onLocationCheckChange: value => dispatch({
            type: EDIT_FORM,
            payload: {
                name: 'accept_location',
                value,
                type: 'change',
            }
        }),
    };
};


export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(EditLocationView));
