import React, { Component } from 'react';
import FormTag from "components/Forms/FormTag";
import { connect } from 'react-redux';
import { EDIT_FORM, SCREEN_LOAD, SUBMIT } from 'constants/ReducerActions/EditProfile'
import EditProfileAction from "../actions/EditProfile";
import EditProfileForm from 'constants/Forms/EditProfile'
import { toOptions } from 'components/Forms/Utils'
import { Container } from 'reactstrap'
import { withRouter } from "react-router-dom"
import { GoBackButton } from "components/Buttons";
import { DNI } from "../helper"
import config from 'configReactApp';

class EditProfileView extends Component {
    args = {};

    constructor(props) {
        super(props);
        props.onInit(props.initialData || {}, props.identities);

    }

    UNSAFE_componentWillReceiveProps(newProps) {
        if (!newProps.data.identity_id && Object.values(newProps.identities).length > 0) {
            this.props.setDefaultIdentity(newProps.identities)
        }
    }

    handleEditProfileSubmit = (e) => {
        this.props.onSubmit(e)
            .then((response) => {
                if (response.status === 200) {
                    this.props.history.push(config('app.after_login'));
                }
            })
            .catch(err => console.log("error", err))
    }


    prepare() {
        this.args = {
            ...EditProfileForm,
            onChange: this.props.onChange,
            onSubmit: this.handleEditProfileSubmit,

            inline: false,

        };
        this.args.fields.identity_value.items = toOptions(this.props.identities);
        this.args.fields.identity_value.selectedValue = Object.values(this.props.identities).find(i => i.id.toString() === this.props.data.identity_id?.toString())?.name;
        this.args.fields.phone.prefix = this.props.data.prefix;
    }

    render() {
        this.prepare();
        const form = <FormTag name='EditUser' {...this.args} data={this.props.data} />
        return <div className="my-3">
            {form}
            <Container className="my-4"><GoBackButton /></Container>
        </div>
    }
}

const mapStateToProps = state => {

    return {
        initialData: state.ProfileReducer || null,
        data: state.EditProfileReducer || null,
        countries: state.CountryReducer.list || null,
        cities: state.CityReducer.list || null,
        identities: state.IdentityReducer.personal || null,

    }
};

const mapDispatchToProps = (dispatch, getState) => {
    return {
        onInit: (data, identities) => {
            const identity = data.identities.find(i => Object.values(identities).find(pi => pi.id.toString() === i.identity_id?.toString()));
            const profile = {
                ...data,
                identity_id: identity?.identity_id,
                identity_value: identity?.identity_value,
            }
            dispatch({
                type: SCREEN_LOAD,
                payload: profile
            })
            dispatch({
                type: EDIT_FORM,
                payload: {
                    name: "prefix",
                    value: data.city?.country?.phone_code,
                    type: "change",
                }
            })
        },
        onChange: e => dispatch({
            type: EDIT_FORM,
            payload: {
                name: e.target.name,
                value: e.target.value,
                type: e.target.type,
                files: e.target.files
            }
        }),
        onSubmit: e => {
            e.preventDefault();
            EditProfileAction.FetchRequest = SUBMIT;
            return dispatch(EditProfileAction.save());
        },
        setDefaultIdentity(identities) {
            dispatch({
                type: EDIT_FORM,
                payload: {
                    name: "identity_id",
                    value: DNI,
                    type: "change",
                }
            })
        }
    };
};


export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(EditProfileView));
