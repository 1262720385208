import ApiStore from '../api/ApiStore'
import {EDIT_FORM, SCREEN_LOAD, SET_STORE_TIME_DATA} from 'constants/ReducerActions/Booking'
import {LIST, LIST_DONE, LIST_ERROR, SET_DATE} from "../constants/ReducerActions/StoreTime";
import {DateTime} from 'luxon';
import {showAvailability} from 'helper';

const StoreTimeAction = (() => {
    let date = null;
    const initAction = () => {
        return {type: LIST}
    };

    const setValueItem = (i, store) => {
        let s = new Date(i.start_time);
        let e = new Date(i.end_time);
        const options = {hour: '2-digit', minute: '2-digit'};

        let date_time = [s.toLocaleTimeString('es-ES', options), "a", e.toLocaleTimeString('es-ES', options)];

        if (showAvailability(store)) {
            date_time = [...date_time, "-", i.booked, 'de', i.amount];
        }
        return date_time.join(' ');
    }

    const setValueDate = (d) => {
        const day = new Date(d);
        const options = {weekday: 'long', month: 'long', day: 'numeric', timeZone: 'UTC'};

        return day.toLocaleDateString("es-ES", options);
    };

    const groupBy = (items, key, store) => items.reduce(
        (result, item) => ({
            ...result,
            [item[key]]: [
                ...(result[item[key]] || []),
                {key: item.id, value: setValueItem(item, store), fully_booked: !(item.booked < item.amount)},
            ],
        }),
        {},
    );

    const listSuccess = (response, store) => {
        let dates = [];
        let all = {};

        /* Las fechas vienen en UTC desde el backend, y necesitamos convertirlas a
        nuestra zona horaria local, pero Date no soporta esto. Por esto usamos luxon
        para convertirlas a formato ISO en zona horaria local */
        response = response.map(st => {
            return {
                ...st,
                start_time: DateTime.fromISO(st.start_time).toISO(),
                start_booking_at: DateTime.fromISO(st.start_booking_at).toISO(),
                end_time: DateTime.fromISO(st.end_time).toISO(),
                date: st.date
            };
        });

        all = groupBy(response, 'date', store);

        console.log(all)

        dates = Object.keys(all);
        if (!date) {
            date = dates[0];
        }

        const payload = {
            list: [],
            dates: dates.map(d => {
                return {id: d, value: setValueDate(d)}
            }),
            all: all,
            date: date,
        }
        return {type: LIST_DONE, payload: payload || null}
    };
    const listErrors = (response) => {
        return {type: LIST_ERROR, payload: response || null}
    };
    const all = (preserveErrors, calendar_id) => (dispatch, getState) => new Promise(function (resolve, reject) {
        const {store_id} = getState().StoreTimeReducer;
        console.log(calendar_id);

        ApiStore
            .times(store_id, calendar_id)
            .then(response => {
                const store = getState().StoreReducer.parent;
                dispatch(listSuccess(response.data || {}, store));
                dispatch({type: SET_DATE});

                let store_time_id = null;
                if (response.data.length > 0) {
                    store_time_id = response.data[0]?.id;
                }

                dispatch({
                    type: preserveErrors ? SET_STORE_TIME_DATA : SCREEN_LOAD,
                    payload: {date: date, store_time_id: store_time_id, invites: 0}
                });
                resolve(response);
            })
            .catch(error => {
                console.log(error)
                dispatch(listErrors(error))
                reject(error);
            })
        ;
        dispatch(initAction())
    });
    const selectDate = (value) => (dispatch, getState) => {
        dispatch({type: SET_DATE, payload: value});
        dispatch({
            type: EDIT_FORM,
            payload: {
                name: "store_time_id",
                value: getState().StoreTimeReducer.list["0"]?.key || null,
                type: "change",
            }
        })
    };
    return {initAction, listSuccess, listErrors, all, selectDate}
})();

export default StoreTimeAction
