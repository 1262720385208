import React from 'react';
import {Nav, NavItem, NavLink} from 'reactstrap';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {useHistory} from "react-router-dom"
import {mainMenu} from "../../routes";
import {filterMenu} from 'helper';
import {connect} from 'react-redux';

const Menu = (props) => {

    const history = useHistory();
    const isCurrentPath = (menu_item) => window.location.pathname === menu_item.path

    const icon_size = "2x"
    // console.log('settings', props.settings);
    return (
        <Nav fill>
            {filterMenu(mainMenu,props.settings.isVirtualQueue).map(menu_item => <NavItem key={menu_item.key} active={isCurrentPath(menu_item)}>
                        <NavLink id={"MainMenu"+menu_item.key} onClick={() => history.push(menu_item.path)}
                                 disabled={isCurrentPath(menu_item)}
                        >
                            {menu_item.icon ?
                                <div><FontAwesomeIcon size={icon_size} icon={menu_item.icon}/></div> : ''}
                            <div>{menu_item.label}</div>
                        </NavLink>
                    </NavItem>
                )}
        </Nav>
    );
}
const mapStateToProps = state => {

    return {
        settings: state.SettingsReducer || {},
    }
};

export default connect(mapStateToProps)(Menu);
