const InitialState = {
    data: {},
    provider: null,
    identity_id: null,
    raw: {},
    waiting: true,
    referer_url: null,
    redirect_to: null
};
export default InitialState
