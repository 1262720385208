import React from 'react'
import Warning from "components/Feedback/Warning"
import { Button, Row } from 'reactstrap';
import { GoBackButton } from "components/Buttons";
import ApiProfile from "api/ApiProfile"
import AuthAction from "actions/Auth";
import ProfileAction from "actions/Profile"
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';

export default function DeleteAccountConfirmationPage() {

    const dispatch = useDispatch();

    const history = useHistory();

    return <Warning description="¿Está seguro que desea dar de baja su cuenta?"
        customKeyPad={
            <>
                <Row>
                    <Button onClick={() => {
                        ApiProfile.deleteAccount().then((response) => {
                            if (response.status === 200 || response.status === 202 || response.status === 204) {
                                dispatch(AuthAction.logout());
                                dispatch(ProfileAction.cleanProfileData());
                                history.push('/succeedDeleteAccount');
                            }
                        })
                    }} style={{ color: 'red', fontSize: 'medium' }} block color='link'>Sí, eliminar mi cuenta</Button>
                </Row>
                <Row>
                    <GoBackButton />
                </Row>
            </>
        } />

}
