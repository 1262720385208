import React, {Component} from 'react';
import FormTag from "components/Forms/FormTag";
import {connect} from 'react-redux';
import {EDIT_FORM, SCREEN_LOAD, SET_IDENTITY_ID, SUBMIT} from 'constants/ReducerActions/LoginFulfillment'
import LoginFulfillmentAction from "../../actions/LoginFulfillment";
import LoginFulfillmentForm from 'constants/Forms/LoginFulfillment'
import {InputEvts, toOptions} from 'components/Forms/Utils'
import CountryAction from "../../actions/Country";
import {withRouter} from "react-router-dom"
import ErrorAlert from "components/Alert/ErrorAlert";
import TermsAndConditionsCheck from "components/Forms/FormTermsAndConditions";
import {CHILE, DNI, isNotAppIntern, redirectToUrl, RUT} from "../../helper";
import Waiting from "../../components/Waiting/Waiting";

class LoginFulfillmentView extends Component {
    args = {};

    constructor(props) {
        super(props);
        this.state = {
            missing_terms: false
        }
    }

    state = {
        missingRequiredFields: false
    }

    componentDidMount() {
        this.props.onInit(this.props.initialData || {}, this.props.identities, this.props);
        // this.props.onCountryChange(this.props.initialData?.city?.country_id);
    }

    // componentDidUpdate(newProps) {
    //     const identities = Object.values(newProps.identities);
    //     if (!newProps.data.identity_id && identities.length > 0) {
    //         this.props.setDefaultIdentity()
    //     }
    // }

    handleLoginFulfillmentSubmit = (e) => {
        e.preventDefault();
        if (!this.props.data.accept_terms_and_conditions && isNotAppIntern()) {
            this.setState({missing_terms: true});
        } else {
            this.setState({missing_terms: false});
            if (this.checkIfAllRequieredFieldsAreCompleted()) {
                this.setState({missingRequiredFields: false})
                this.props.onSubmit(e)
                    .then((response) => {
                        const {redirectTo, params} = redirectToUrl(response);
                        this.props.history.replace(redirectTo, params);
                    })
                    .catch(err => console.log("error", err))
            } else {
                this.setState({missingRequiredFields: true})
            }
        }
    }

    checkIfAllRequieredFieldsAreCompleted = () => {
        let allRequiredFieldsAreCompleted = true;
        Object.entries(LoginFulfillmentForm.fields).forEach(([key, value]) => {
            if (value.required && !this.props.data[key]) {
                allRequiredFieldsAreCompleted = false;
            }
        })
        return allRequiredFieldsAreCompleted;
    }

    handleOnCountryChange = e => {
        e && e.preventDefault();
        const selectedCountry = e.target.value;
        this.props.onCountryChange(selectedCountry);
        this.setPhonePrefix(selectedCountry, this.props.countries)
        this.props.setIdentityField(selectedCountry, this.props);
    }


    setPhonePrefix = (selectedCountry, countries) => {
        let selectedCountryPhonePrefix = null;
        for (const countryIndex in countries) {
            const country = countries[countryIndex];
            if (country.id === parseInt(selectedCountry)) {
                selectedCountryPhonePrefix = country.phone_code;
                break;
            }
        }
        this.props.setPhonePrefix(selectedCountryPhonePrefix);
    }


    prepare() {
        this.args = {
            ...LoginFulfillmentForm,
            onChange: this.props.onChange,
            onSubmit: this.handleLoginFulfillmentSubmit,

            inline: false,

        };
        const country_on_events = new InputEvts();
        country_on_events.add('change', this.props.onChange);
        country_on_events.add('change', this.handleOnCountryChange);
        const countryOptions = toOptions(this.props.countries)
        this.args.fields.country_id.items = countryOptions;
        this.args.fields.country_id.placeholder = "Seleccione un país";
        this.args.fields.country_id.selectedValue = this.props.data.country_id ?
            countryOptions.find(o => o.key.toString() === this.props.data.country_id.toString())?.value : this.args.fields.country_id.placeholder;
        this.args.fields.country_id.e = country_on_events;
        const cityOptions = toOptions(this.props.cities);
        this.args.fields.city_id.items = cityOptions;
        this.args.fields.city_id.placeholder = "Seleccione una ciudad";
        this.args.fields.city_id.selectedValue = this.props.data.city_id ?
            cityOptions.find(o => o.key.toString() === this.props.data.city_id.toString())?.value : this.args.fields.city_id.placeholder;
        this.args.fields.identity_value.items = toOptions(this.props.identities);
        this.args.fields.identity_value.selectedValue = Object.values(this.props.identities).find(i => i.id.toString() === this.props.data.identity_id?.toString())?.name;
        this.args.fields.phone.prefix = this.props.data.prefix;

        if (countryOptions.length === 1 && cityOptions.length === 1) {
            this.args.fields.country_id.type = 'hidden';
            this.args.fields.city_id.type = 'hidden';
        }

    }

    render() {
        this.prepare();

        const form = <FormTag name='LoginFulfillment' {...this.args}
                              data={this.props.data} footer={
            isNotAppIntern() ? <>
                {this.state.missing_terms && <ErrorAlert
                    text="Debe aceptar los términos y condiciones"/>}
                <TermsAndConditionsCheck country={this.props.data.country_id}
                                         checked={this.props.data.accept_terms_and_conditions}
                                         onValueChange={this.props.onTermsCheckChange}/>
            </> : null
        }/>
        return <Waiting waiting={this.props.waiting}>
            <div className="my-3 pb-3">
                {this.state.missingRequiredFields && <ErrorAlert text="Debe completar los campos obligatorios"/>}
                {form}
            </div>
        </Waiting>
    }
}

const mapStateToProps = state => {

    return {
        initialData: state.ProfileReducer || null,
        locations: state.LocationsReducer.list || null,
        countries: state.CountryReducer.list || null,
        cities: state.CityReducer.list || null,
        identities: state.IdentityReducer.personal || null,
        data: state.LoginFulfillmentReducer,
        waiting: (state.LoginFulfillmentReducer.waiting
            || state.CountryReducer.waiting
            || state.AppReducer.load
        )
    }
};

const mapDispatchToProps = (dispatch, getState) => {
    return {
        onInit: (data, identities, props) => {
            // console.clear()
            // console.log(data.identities)
            // const identity = data.identities?.find(i => Object.values(identities).find(pi => pi.id.toString() === i.identity_id?.toString()));
            dispatch({
                type: SCREEN_LOAD,
                payload: {
                    // country_id: data.city?.country_id,
                    // city_id: data.city_id,
                    // identity_id: identity?.identity_id,
                    // identity_value: identity?.identity_value,
                    first_name: data.first_name,
                    last_name: data.last_name,
                    phone: data.phone
                }
            })
            if (props.locations.length === 1) {
                const country = props.locations[0];
                props.setDefaultCountry(country.id);
                props.setDefaultCity(country.cities[0].id);
                props.setPhonePrefix(country.phone_code);
                props.setIdentityField(country.id, props);
            }
            // if (data.city?.country?.phone_code) {
            //     dispatch({
            //         type: EDIT_FORM,
            //         payload: {
            //             name: "prefix",
            //             value: data.city?.country?.phone_code,
            //             type: "change",
            //         }
            //     })
            // }
        },

        onChange: e => dispatch({
            type: EDIT_FORM,
            payload: {
                name: e.target.name,
                value: e.target.value,
                type: e.target.type,
                files: e.target.files
            }
        }),
        onCountryChange: (countryId) => {
            if (!countryId) {
                return;
            }

            dispatch(CountryAction.cities(countryId))
                .then((response) => {
                    dispatch({
                        type: EDIT_FORM,
                        payload: {
                            name: "city_id",
                            value: response.data?.length === 1 ? response.data[0].id : null,
                            type: "change",
                        }
                    })
                });
        },
        onTermsCheckChange: value => dispatch({
            type: EDIT_FORM,
            payload: {
                name: 'accept_terms_and_conditions',
                value,
                type: 'change',
            }
        }),
        onSubmit: (e) => {
            e.preventDefault();
            LoginFulfillmentAction.FetchRequest = SUBMIT;
            return dispatch(LoginFulfillmentAction.save(dispatch, getState, e));
        },
        setDefaultCountry(country_id) {
            // console.log(country_id)
            dispatch({
                type: EDIT_FORM,
                payload: {
                    name: "country_id",
                    value: country_id,
                    type: "change",
                }
            })
        },
        setDefaultCity(id) {
            dispatch({
                type: EDIT_FORM,
                payload: {
                    name: "city_id",
                    value: id,
                    type: "change",
                }
            })
        },

        setDefaultIdentity() {
            dispatch({
                type: EDIT_FORM,
                payload: {
                    name: "identity_id",
                    value: DNI,
                    type: "change",
                }
            })
        },
        setIdentity(value) {
            // console.log(value)
            dispatch({
                type: SET_IDENTITY_ID,
                payload: value + "",
            })
        },
        setPhonePrefix(value) {
            dispatch({
                type: EDIT_FORM,
                payload: {
                    name: "prefix",
                    value,
                    type: "change",
                }
            })
        },
        setDefaultCountryAndCity(country) {
            dispatch(
                LoginFulfillmentAction.setDefaultCountryAndCity(country)
            )
        },
        setIdentityField(country, props) {
            if (country.toString() === CHILE.toString()) {
                // console.log(country.toString(), CHILE.toString(), RUT)
                props.setIdentity(RUT.toString());
            } else {
                props.setIdentity(DNI);
            }
        },
    };
};


export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(LoginFulfillmentView));
