import ApiStore from "../../api/ApiStore";
import React, {Component} from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import Error404View from '../Error404View';
import _ from "lodash";
import Waiting from "../../components/Waiting/Waiting";
import config from 'configReactApp';
import StoreAction from "../../actions/Store";


class BookingRedirect extends Component {

    state = {
        waiting: true,
        response: ''
    }

    componentDidMount() {
        ApiStore.get(this.props.storeId).then(response => {
            this.checkAction(response.data)
        }).catch(response => {
            this.setState({waiting: false, message: response})
        });

    }

    checkAction(store) {
        let path;
        if (_.get(store, 'has_virtual_queue') || _.get(store, 'has_bookings_available')) {
            if (_.get(store, 'has_virtual_queue') && !_.get(store, 'has_bookings_available')) {
                path = 'app.virtualQueue.new';
            } else if (!_.get(store, 'has_virtual_queue') && _.get(store, 'has_bookings_available')) {
                path = 'app.booking.new';
            } else {
                this.setState({waiting: false});
            }
            this.props.history.push(config(path).replace(':store_id', this.props.storeId));
        } else {
            this.setState({waiting: false, message: 'Tienda sin disponibilidad'});
        }

        // const path = _.get(store, 'has_virtual_queue') ? 'app.booking.virtual_queue' : 'app.booking.new';
        console.log(_.get(store, 'has_virtual_queue'));

    }

    render() {
        return <Waiting waiting={this.state.waiting}>
            {this.state.message ? <Error404View/> : <>fd</>}
        </Waiting>;
    }
}

const mapStateToProps = state => {
    return {
        stores: state.StoreReducer.list || {}
    }
}

const mapDispatchToProps = (dispatch, getState) => {
    return {
        onInit: (storeId) => {
            dispatch(StoreAction.get(storeId));
        }
    }
}

export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(BookingRedirect));
