import * as Action from '../constants/ReducerActions/City'
import InitialState from '../constants/InitialState/City'

const CityReducer = (state = InitialState, {type, payload}) => {
    switch (type) {
        case Action.SCREEN_LOAD:
            return {...InitialState, ...payload};
        case Action.LIST :
            return {...state, waiting: true};
        case Action.LIST_DONE    :
            return {...state, waiting: false, list: [...payload]};
        case Action.LIST_ERROR   :
            return {...state, waiting: false, errors: {...payload}};
        case Action.ALL    :
                return {...state, waiting: false, all: {...payload}};
        default:
            return state;
    }
};

export default CityReducer;
