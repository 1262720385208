import Api from './Api'
import configReactApp from 'configReactApp'

class _ApiPhoneLogin extends Api {

    login(data, config) {
        const formdata = new FormData();
        
        data={
            ...data,
            "grant_type": configReactApp('api.grant_type_phone'),
            "client_id": configReactApp('api.client_id'),
            "client_secret": configReactApp('api.client_secret'),
            "scope": configReactApp('api.scopes'),
        };

        Object.keys(data).forEach(k => {
            formdata.append(k, data[k])
        });
        
        return this.post('login', formdata, config);
    }
}

const ApiPhoneLogin = new _ApiPhoneLogin({
    endpoint: configReactApp('api.endpoint.login_phone')
});


export default ApiPhoneLogin
