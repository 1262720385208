import React from 'react'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faSearch} from "@fortawesome/free-solid-svg-icons";
import {Container, Row, Col, InputGroup, InputGroupText, InputGroupAddon, Input} from 'reactstrap';


const SearchBar = (props) => {
    return <Container id="Search" tag="section" className="my-3 sticky-content"><Row><Col><InputGroup>
        <Input placeholder="Search" onChange={(e) => props.handleSearchChange(e.target.value)}/>
        <InputGroupAddon addonType="append">
            <InputGroupText><FontAwesomeIcon icon={faSearch}/></InputGroupText>
        </InputGroupAddon>
    </InputGroup></Col></Row></Container>
}

export default SearchBar
