import React from "react";
import Feedback from "../../components/Feedback/Feedback";

export default () => <Feedback title="TERMINOS Y CONDICIONES DE SISTEMA DE TURNOS DE VISITAS PARA LOS CENTROS COMERCIALES CENCOSUD" icon={false}>
    <h5 className="text-center">Y</h5>
    <h5 className="text-center">POLÍTICA DE PRIVACIDAD Y COOKIES DEL SISTEMA</h5>

    <h5>Declaración</h5>
    <p>CENCOSUD SHOPPING S.A., RUT número 76.433.310-1, domiciliada para estos efectos en Av. Kennedy 9001, piso 4°,
        Comuna de Las Condes, Región Metropolitana, (en adelante “Cencosud”), ha elaborado estos términos y condiciones,
        política de privacidad y cookies para la utilización de la aplicación de solicitud de turnos de ingreso a los
        Shoppings o Centros Comerciales de propiedad de Cencosud (“App”) y así los usuarios tengan conocimiento del
        funcionamiento de la “app” de turnos, también sobre la información personal que Cencosud reúne respecto de los
        mismos, así como la manera en que aquélla se utiliza y el propósito de su tratamiento, conforme a la Ley
        N°19.628.
    </p>

    <h5>Consentimiento del titular de los datos:</h5>
    <p>El Usuario manifiesta conocer que al hacer “click” en la opción “ACEPTO” ubicada al final de este
        documento, reconocerá que ha leído íntegramente estos términos y condiciones y Política de Privacidad y que
        comprende su alcance y las consecuencias de compartir sus datos e información con Cencosud, para su tratamiento
        y finalidad aquí enunciados, aceptando expresamente nuestra política de privacidad.</p>


    <h5>Información personal solicitada</h5>
    <p>Nuestra App de Turnos de visita a Centros Comerciales, es un sistema tecnológico diseñado para que nuestros
        visitantes accedan a turnos específicos en días y horas determinadas ofrecidas en la gráfica de la app y
        aceptadas por nuestros clientes.</p>
    <p>Este sistema no establece ninguna obligación para los usuarios, en el sentido de que, de no concurrir a
        los Centros Comerciales en los días y horas solicitados, no se verán expuestos a sanción alguna. El único
        destino y objeto de este sistema, es facilitar el acceso de nuestros clientes, con un orden determinado y
        solicitado en forma previa según los requisitos de la app.</p>
    <p>El acceso de los clientes a nuestros centros comerciales, utilizando este sistema de reserva de turnos,
        no obliga a garantizar el acceso en momentos en que los centros comerciales presentan aforos completos y por
        legislación vigente y normativa sanitaria, se impida acceder excepcionalmente por la ocurrencia de saturación de
        los recintos.</p>
    <p>Al momento de optar por registrase en la App, el Usuario deberá proporcionar ciertos datos personales para
        propósitos tales como facilitar la comunicación con el Usuario respecto de la asignación de turnos y cambios de
        circunstancias en los aforos de los Centros Comerciales a visitar , chequear el cumplimiento de los requisitos
        de registro a la App, posibilitar el acceso a ciertas secciones o áreas de la App.</p>
    <p>El Usuario siempre tendrá la opción de elegir si desea o no proporcionar su información personal, entendiendo que
        hay datos mínimos que deben ser completados para los efectos de posibilitar su registro en la App. En caso de
        elegir no proporcionar la información personal solicitada, se considerará que el Usuario no desea utilizar la
        App. Al completar el registro de sus datos en la App, el Usuario acepta y se obliga a (a) suministrar datos
        exactos, actuales y completos sobre su persona y (b) mantener actualizados sus datos en la App. Para Cencosud,
        la anterior declaración es un elemento determinante para permitir la utilización de la App. El Usuario
        responderá, en cualquier caso, respecto de la veracidad de los datos facilitados, reservándose Cencosud el
        derecho de excluir a todo Usuario que haya facilitado datos falsos, sin perjuicio de iniciar las acciones
        legales que correspondieren.</p>

    <h5>Uso de la información</h5>
    <p>El Usuario consiente expresamente que, como efecto de la utilización de la APP, la Información que se acumule,
        recolecte, reciba o recoja de los Usuarios , podrá́ ser objeto de: (i) Almacenamiento, entendiendo por tal el
        archivo de la información en lugares especialmente destinados al efecto; (ii) Procesamiento, entendiendo por tal
        los mecanismos y procesos que permitan brindar al Usuario los servicios ofrecidos; (iii) Tratamiento,
        entendiendo por tal cualquier operación o complejo de operaciones o procedimientos técnicos, de carácter
        automatizado o no, que permitan recolectar, almacenar, grabar, organizar, elaborar, agrupar, seleccionar,
        extraer, confrontar, interconectar, disociar o cancelar la información; y/o (iv) Disposición, entendiendo por
        tal comunicar, ceder, transferir, transmitir o cancelar datos de carácter personal. Lo anterior salvo que el
        Usuario, respecto del tratamiento y disposición, expresamente instruya lo contrario mediante comunicación
        escrita, despachada mediante carta certificada al domicilio de Cencosud.</p>
    <p>Respetando las disposiciones legales que regulen la materia, la Información podrá almacenarse, procesarse y
        tratarse en cualquier país del mundo. Sin perjuicio de lo anterior, Cencosud será siempre responsable del debido
        cuidado de la Información debiendo tomar medidas de seguridad razonables para resguardar la Información contra
        su manipulación, pérdida, destrucción, divulgación y acceso no autorizado.</p>
    <p>La Información almacenada podrá ser usada para los fines que, a continuación, se expresan: (i) Coordinar,
        registrar y autorizar previamente el ingreso del Usuario a los Shopping o Centros Comerciales de Cencosud; (ii)
        la realización de estudios individuales y/o colectivos de cualquier tipo como, por ejemplo, estudios en materia
        demográfica, histórica, comportamiento de los Usuarios e investigaciones de mercado; cuidando siempre de la
        reserva y confidencialidad de los datos personales de la manera exigida por la ley. (iii) para “información
        estadística”, entendiendo por ésta aquella información de la cual se han eliminado aquellos campos o señas que
        permiten identificar a un Usuario determinado y, por tanto, sólo dice relación con datos sobre un determinado
        producto, grupo o categoría de productos y/o servicios, o sobre un grupo de Usuarios no identificados o
        identificables.</p>
    <p>Cualquier comunicación cursada de Cencosud a un Usuario se considerará notificada si fue remitida al domicilio o
        al correo electrónico ingresado por el usuario en los registros de Usuarios de la base de datos de la App.</p>
    <p>Cencosud podrá revelar información personal a terceros sin consentimiento de los Usuarios, en conformidad a la
        Ley vigente, para investigar, prevenir o tomar acciones sobre actividades ilegales, delitos, sospechas de
        fraude, lavado de activos, terrorismo y situaciones que involucran riesgos potenciales a la seguridad de
        cualquier persona, cuando sea requerido por ley o por resolución judicial o administrativas en el ejercicio de
        las atribuciones que le son propias,</p>

    <h5>Medidas de Seguridad respecto del tratamiento de la información</h5>
    <p>A fin de prevenir el acceso no autorizado, mantener la seguridad y confidencialidad de los datos personales y
        asegurar el uso correcto de la información, Cencosud ha implementado el uso firewalls y otros medios técnicos
        destinados a este fin. El Usuario también deberá tomar los recaudos necesarios a fin proteger su información
        personal mientras se encuentre utilizando la App. En particular, el Usuario será exclusivamente responsable de
        la filtración de su información en el supuesto de que su clave de acceso o contraseña sea puesta en conocimiento
        de terceros con su autorización.</p>

    <h5>Derechos de acceso a su información personal</h5>
    <p>Cencosud Shopping S.A. reconoce y acepta que los Usuarios tienen el legítimo derecho a solicitar la exclusión de
        la Información. En el marco antes indicado, Cencosud ofrece al Usuario restringir la disposición de su
        información personal a Cencosud.</p>
    <p>El usuario dispondrá siempre de los derechos de información, rectificación y cancelación de los datos personales
        conforme a la Ley Nº 19.268 sobre protección a la vida privada. El Usuario podrá hacer efectivo el derecho
        antes referido accediendo a la app, sección Mi cuenta, procediendo a dar de baja su cuenta. Lo anterior sólo
        serán aplicables para aquella información o datos que el Usuario haya facilitado a Cencosud.</p>
    <p>Cencosud no puede garantizar la privacidad de la información personal de los Usuarios de la App, si estos la
        suministran por su cuenta en reportajes de prensa, publicaciones, zonas de chateo, boletines u otras similares.
        Si el Usuario revela dicha información será bajo su exclusiva responsabilidad.</p>

    <h5>Plazo de conservación de los datos de Usuarios</h5>
    <p>Cencosud conservará la información personal de los Usuarios por el término necesario para el cumplimiento de las
        finalidades descritas en esta Política de Privacidad y/u obligaciones legales. La información personal que se
        recabe de los Usuarios podrá ser eliminada o resultar indisponible cuando haya dejado de ser necesaria o
        pertinente a los fines para los cuales hubiese sido recolectada, salvo que exista una obligación legal de
        conservarla por un término mayor.</p>

    <h5>Cambios a los Términos y Condiciones y la Política de Privacidad</h5>

    <p>Cencosud conservará la información personal de los Usuarios por el término necesario para el cumplimiento de las
        finalidades descritas en esta Política de Privacidad y/u obligaciones legales. La información personal que se
        recabe de los Usuarios podrá ser eliminada o resultar indisponible cuando haya dejado de ser necesaria o
        pertinente a los fines para los cuales hubiese sido recolectada, salvo que exista una obligación legal de
        conservarla por un término mayor.</p>

    <h5>Recopilación automática de información</h5>
    <p>La App puede que recabe automáticamente cierta información concerniente a los Usuarios, tales como dirección del
        Protocolo Internet (IP) de su computadora, dirección del IP de su proveedor de servicio de Internet, fecha y
        hora en que ingresó en la App, dirección de Internet de la página desde la que se conectó directamente a la App,
        sistema operativo que está usando, secciones de la App que el Usuario visitó, y cualquier otro contenido que
        haya enviado a o descargado de la App.</p>

    <h5>Cookies</h5>
    <p>Uno de los mecanismos por los cuales se suele recopilar datos del Usuario de manera automática es a través del
        uso de “cookies” o mecanismos similares. La App utiliza “cookies”. Las cookies son definidas como un paquete de
        datos que un navegador web almacena de forma automática en el ordenador de un Usuario cuando éste visita una
        página web o una aplicación. De esta manera, si el Usuario ingresara nuevamente a la App, la información
        almacenada en su computadora permitirá a Cencosud reconocer futuras visitas por parte del mismo Usuario.</p>

    <p>Cencosud utilizará las cookies y mecanismos similares para las siguientes finalidades, cuya enunciación no es
        taxativa:</p>
    <ol>
        <li>Ofrecer al Usuario un contenido personalizado de la App e información para su uso personal;</li>
        <li>Procesar la actividad del Usuario en la App;</li>
        <li>Implementar procesos automáticas para completar formularios en línea con los datos personales del
            Usuario para agilizar el proceso de consulta; y
        </li>
        <li>Mejorar la experiencia del Usuario en la App.</li>
    </ol>

    <p>El Usuario podrá optar por rechazar las “cookies” si la configuración de su navegador así lo permite, pero
        advertimos que su rechazo podría afectar la utilización de la App y la capacidad del Usuario para acceder a
        ciertas secciones o funcionalidades.</p>


    <h5>Representante legal y Jurisdicción en caso de controversias</h5>
    <p>El representante legal es don Ricardo Alonso González Novoa, Rut.14.292.860-4, con domicilio en Avenida Kennedy
        9001, Piso 4, comuna de Las Condes.</p>

    <p>Las controversias que surjan en relación con lo establecido en estos Términos y Condiciones y/o Politica de
        Privacidad, podrán interponerse, a lección del usuario, ante el Juzgado de Policía Local correspondiente a su
        domicilio o el domicilio del proveedor. Sin perjuicio de la competencia de los tribunales ordinarios de Justicia
        para otro tipo de materias.</p>

</Feedback>
