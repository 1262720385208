import {isAuth, isGuest, isSocial, noPeru} from "./isAuth";
import LoginView from "./views/Auth/LoginView";
import RegisterView from "./views/Auth/RegisterView";
import SucceedRegisterPage from "./views/Register/SucceedRegister";
import ForgotPasswordView from "./views/Auth/ForgotPasswordView";
import ProfileView from "./views/ProfileView";
import SearchView from "./views/SearchView";
import VirtualQueueSearchView from './views/VirtualQueue/VirtualQueueSearchView';
import BookingView from "./views/Booking/BookingNewView";
import BookingConfirmCancel from "./views/Booking/BookingConfirmCancel";
import SucceedBookingCancel from "./views/Booking/SucceedBookingCancel";
import StoreView from "./views/StoreView";
import BookingListView from "./views/Booking/BookingListView";
import Error404View from "./views/Error404View";
import LogoutView from "./views/Auth/LogoutView";
import Inflector from './Inflector'
import ChangePasswordView from "./views/Auth/ChangePasswordView";
import ForgotPasswordSucceed from "views/Auth/ForgotPasswordSucceed"
import React from "react";
import {faAsterisk, faCalendarAlt, faCalendarCheck, faClock, faCog, faHome, faLocationArrow, faLock, faPaperPlane, faSignOutAlt, faUser, faUserEdit, faUserTimes} from '@fortawesome/free-solid-svg-icons'
import SucceedBookingView from "./views/Booking/SucceedBookingView";
import BookingDetailView from "./views/Booking/BookingDetailView";
import config from 'configReactApp'
import SucceedLogoutView from "./views/Auth/SucceedLogoutView"
import SucceedNewPassPage from "./views/Auth/UpdatePassword/SucceedNewPass"
import SucceedEntryPage from "./views/SucceedEntry";
import PrivacyPolicy from "./views/PrivacyPolicy";
import QRScanView from "./views/QRScanView";
import TermsOfService from "./views/Public/TermsOfService";
import ResetPasswordView from "./views/Auth/ResetPasswordView";
import SucceedCheckIn from "views/SucceedCheckIn";
import ResetPasswordSucceedView from "./views/Auth/ResetPasswordSucceed"
import EditProfileView from "./views/EditProfileView";
import DeleteAccountView from "./views/DeleteAccountView";
import SucceedDeleteAccountView from "./views/SucceedDeleteAccountView";
import EditLocationView from "./views/EditLocationView";
import LoginFulfillmentView from "./views/Auth/LoginFulfillmentView";
import BookingEditView from "./views/Booking/BookingEditView";
import {isNotAppIntern} from "helper";
import MustLoginView from './views/Public/MustLoginView';
import GuestRegisterView from "./views/Auth/GuestRegisterView";
import VirtualQueueNewView from "views/VirtualQueue/VirtualQueueNewView";
import VirtualQueueDetailView from "views/VirtualQueue/VirtualQueueDetailView";
import DashboardView from "views/DashboardView";

const dprops = {
    showFooter: () => isAuth(),
    showHeader: () => isAuth(),
    showBackButton: true,
    access: () => 'deny',
    exact: true,
    layout: 'public',
    visible: () => true,
    show_on_virtual_queue: true,
    show_on_booking: true,
    showStore: false,
}
const pub = {
    ...dprops,
    access: () => 'granted',
    visibility: 'public'
};
const sep = {
    ...pub,
    label: '_'
}
const restringed = {
    ...dprops,
    showHeader: true,
    access: () => isAuth() ? 'granted' : 'deny',
    visibility: 'private'
};

const restringed_not_guest = {
    ...restringed,
    access: () => isAuth() && !isGuest() ? 'granted' : 'deny',
    visible: () => !isGuest(),
};

const restringed_guest = {
    ...restringed,
    visible: () => isGuest(),
};

const restringed_not_intern = {
    ...restringed,
    access: () => isAuth() && isNotAppIntern() ? 'granted' : 'deny',
}

const restringed_not_intern_not_social = {
    ...restringed,
    access: () => isAuth() && !isSocial() && isNotAppIntern() ? 'granted' : 'deny',
}

const pub_not_intern = {
    ...pub,
    showHeader: false,
    access: () => isNotAppIntern() ? 'granted' : 'deny',
};

const unavailable = {
    ...restringed,
    access: () => 'deny',
}

const showSearch = {
    ...restringed,
    visible: () => noPeru()
}

const _r = [
    {
        ...pub,
        // layout: 'VirtualQueueLayout',
        showHeader: () => true,
        showStore: true,
        path: config('app.virtualQueue.new'), title: "Fila virtual", component: <VirtualQueueNewView/>
    },
    {...pub_not_intern, path: '/login', component: <LoginView/>, title: true, layout: 'public'},
    {...pub_not_intern, path: '/register', title: 'Registrate', component: <RegisterView/>, layout: 'public'},
    {...pub_not_intern, path: '/succeedRegister', title: 'Registro Exitoso!', component: <SucceedRegisterPage/>},
    {
        ...pub_not_intern, path: config('app.reset_password'), title: 'Cambia tu contraseña',
        component: <ResetPasswordView/>,
        layout: 'public'
    },
    {
        ...pub_not_intern, path: config('app.reset_password_done'), title: '¡Listo!',
        component: <ResetPasswordSucceedView/>,
        layout: 'public'
    },
    {
        ...pub_not_intern,
        path: config('app.forgot_password'),
        title: "Olvidé mi clave",
        component: <ForgotPasswordView/>,
        layout: 'public'
    },
    {
        ...pub_not_intern,
        path: config('app.forgot_password_succeed'),
        title: "¡Listo!",
        component: <ForgotPasswordSucceed/>,
        layout: 'public'
    },
    {...pub, path: '/succedLogout', title: "Sesión finalizada", component: <SucceedLogoutView/>},
    {
        ...pub_not_intern,
        path: '/succedChangePassword',
        title: "Cambio de clave exitoso!",
        component: <SucceedNewPassPage/>
    },

    {...pub, path: config('app.login_fulfillment'), title: "Complete los datos", component: <LoginFulfillmentView/>},
    {...restringed, path: '/succeedEntry', title: "Ingreso confirmado", component: <SucceedEntryPage/>},

    {...pub, path: config('app.booking.new'), title: "Día y Hora", component: <BookingView/>},
    {...pub, path: config('app.guest.booking.detail'), title: "Mi Reserva", component: <BookingDetailView/>},
    {
        ...restringed,
        path: config('app.booking.after_create'),
        title: 'Reserva Confirmada',
        component: <SucceedBookingView/>
    },
    {...restringed, path: config('app.booking.detail'), title: 'Mi Reserva', component: <BookingDetailView/>},
    {...restringed, path: config('app.booking.checkin'), title: 'Check In', component: <QRScanView/>},
    {
        ...pub,
        path: config("app.virtualQueue.detail"),
        title: 'Fila virtual',
        showStore: true,
        component: <VirtualQueueDetailView/>
    },
    {
        ...restringed,
        showBackButton: false,
        path: '/succeedCheckIn',
        title: "Ingreso confirmado",
        component: <SucceedCheckIn/>
    },

    {...restringed, path: '/store/:store', title: 'Local', component: <StoreView/>},
    {
        ...restringed,
        path: config('app.booking.cancel'),
        title: "Confirmar cancelación",
        component: <BookingConfirmCancel/>
    },
    {
        ...restringed,
        path: config('app.booking.after_cancel'),
        title: "Cancelación confirmada",
        component: <SucceedBookingCancel/>
    },
    {
        ...restringed,
        path: config('app.booking.edit'),
        title: "Editar reserva",
        component: <BookingEditView/>
    },
    {
        ...pub_not_intern,
        path: '/succeedDeleteAccount',
        title: "Cuenta eliminada",
        component: <SucceedDeleteAccountView/>
    },
    {
        ...pub_not_intern,
        path: config('app.guest_register'),
        title: 'Registrate',
        component: <GuestRegisterView/>,
        layout: 'public'
    },
    {
        ...pub_not_intern,
        path: config('app.guest_home'),
        title: 'Registrate',
        component: <MustLoginView/>,
        layout: 'public'
    },

// {...pprops,path: '/brand', component: <BrandPage/>},

//
// {...pprops,path: '/country', component: <LocationLayout/>},
// {...pprops,path: '/store', component: <LocationLayout/>},

];
const _mainMenu = [
    {
        ...restringed,
        icon: faHome,
        label: 'Inicio',
        path: '/dashboard',
        exact: true,
        component: <DashboardView/>
    },
    {
        ...showSearch,
        icon: faCalendarCheck,
        label: 'Agendar',
        title: 'Elige tu Mall',
        path: config('app.search'),
        show_on_virtual_queue: false,
        show_on_booking: true,
        //visible: isPeru(),
        component: <SearchView/>
    },
    {
        ...pub,
        showHeader: () => true,
        icon: faClock,
        label: 'Fila virtual',
        title: 'Fila virtual',
        path: config('app.virtualQueue.search'),
        visible: () => true,
        show_on_virtual_queue: true,
        show_on_booking: false,
        showStore: true,
        showBackButton:false,
        component: <VirtualQueueSearchView/>
    },
    {
        ...restringed,
        icon: faCalendarAlt,
        label: 'Mis Reservas',
        path: config('app.bookings'),
        //path: '/bookings',
        title: 'Mis Reservas',
        component: <BookingListView/>
    },
    {
        ...restringed_not_guest,
        icon: faUser,
        title: 'Mi Cuenta',
        label: 'Mi perfil',
        path: '/profile',
        component: <ProfileView/>
    },
    {
        ...restringed_guest,
        label: "Salir",
        icon: faSignOutAlt,
        path: '/logout',
        component: <LogoutView/>,
        showFooter: () => false,
        visible: () => true,
        show_on_virtual_queue: true,
        show_on_booking: true,
    },
];

const _profileMenu = [
    {
        ...restringed_not_intern,
        icon: faUserEdit,
        label: "Editar datos",
        path: "/profile/edit",
        component: <EditProfileView/>
    },
    {
        ...restringed_not_intern,
        icon: faLocationArrow,
        label: "Cambiar ubicación",
        path: "/location/edit",
        component: <EditLocationView/>
    },
    {
        ...restringed_not_intern_not_social,
        icon: faCog,
        label: "Cambiar clave",
        title: "Cambia tu clave",
        path: "/profile/password",
        component: <ChangePasswordView/>
    },
    {
        ...unavailable,
        icon: faPaperPlane,
        label: "Enviar mail activacion"
    },
    {
        ...restringed,
        label: "Cerrar Sesión",
        icon: faSignOutAlt,
        path: '/logout',
        component: <LogoutView/>,
        showFooter: () => false
    },
    {...sep},
    {
        ...unavailable,
        icon: faLock,
        label: "Políticas de Privacidad",
        path: '/privacy-policy',
        component: <PrivacyPolicy/>
    },
    {
        ...pub_not_intern,
        icon: faAsterisk,
        label: "Términos del Servicio",
        path: '/terms-of-service/:country',
        component: <TermsOfService/>
    },
    {...sep},
    {
        ...restringed_not_intern,
        icon: faUserTimes,
        label: "Dar de baja mi cuenta",
        path: '/account/delete',
        title: 'Eliminación de cuenta',
        component: <DeleteAccountView/>,
        className: "text-secondary btn-sm float-right",
    },
];
const toRoutes = (_r) => {
    const flatMap = _r.map(_r => {
        let {icon, routes, label, className, ...r} = _r
        return r;
    }).reduce((a, c) => {
        let {routes, ...cur} = c;
        return [...a, cur, ...((routes) ? toRoutes(routes) : [])];
    }, []);
    return flatMap;
}

const toMenu = (_r) => {
    const defaultOptions = {active: true, disabled: false}
    const flatMap = _r.map(_r => {
        let {path, icon, routes, className, label} = _r
        return {
            ...defaultOptions,
            icon,
            routes,
            label,
            className,
            path,
            key: Inflector.classify(label || path),
            access: _r.access,
            visible: _r.visible,
            show_on_virtual_queue: _r.show_on_virtual_queue,
            show_on_booking: _r.show_on_booking,
            showStore: _r.showStore
        };
    }).reduce((a, c) => {
        let {routes, ...cur} = c;
        return [...a, cur, ...((routes) ? toMenu(routes) : [])];
    }, []);
    return flatMap;
}
const appRoutes = toRoutes([..._r, ..._mainMenu, ..._profileMenu, {...pub, component: <Error404View/>}]);
export default appRoutes;
export const mainMenu = toMenu(_mainMenu);
export const profileMenu = toMenu(_profileMenu);
