const InitialState = {
    country_id: null,
    city_id: null,
    identity_id: null,
    identity_value: null,
    email: null,
    prefix: '+00',
    phone: null,
    accept_terms_and_conditions: false,
    waiting: false,
};
export default InitialState
