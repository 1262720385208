import Api from './Api'
import config from 'configReactApp'


class _ApiIdentity extends Api {

    id = 0;

    personal() {
        return this.get('personal')
    }

    socials() {
        return this.get('socials')
    }
}

const ApiIdentity = new _ApiIdentity({endpoint: config('api.endpoint.identity')});
export default ApiIdentity
