import React from 'react';
import AuthAction from "../../actions/Auth";
import {connect} from 'react-redux';
import {withRouter} from "react-router-dom"
import config from 'configReactApp'

class LogoutView extends React.Component {

    componentDidMount() {
        this.props.logoutAction();
        this.props.history.push(config('app.after'))
    }

    render = () => null
}

const mapDispatchToProps = (dispatch) => {
    return {
        logoutAction: () => dispatch(AuthAction.logout()),
    }
}

export default withRouter(connect(
    null,
    mapDispatchToProps
)(LogoutView));
