import React from 'react';
import {Container, Row, Col} from 'reactstrap';
import StoreItem from "./StoreItem";
import BrandItem from "./BrandItem";
import BookingItem from "./BookingItem";
import QueueItem from './QueueItem';

class ListItem extends React.Component {

    render() {

        const objList = this.props.data;

        const list = objList.map((item,i) => {
            switch (this.props.name) {
                case 'Store':
                    return <StoreItem key={i} item={item} isVirtualQueue={this.props.isVirtualQueue}/>;
                case 'Brand' :
                    return <BrandItem key={i} item={item}/>;
                case 'BookingList' :
                    return <BookingItem key={i} item={item}/>;
                default:
                    return null;
            }


        });

        let queueList = []
        if(this.props.queues){
            queueList = this.props.queues.map((item) => {
                return <QueueItem key={item.id} item={item}/>
            })
        }


        return (
            <Container id="ListItem" tag="section" className="p-0 pt-3 pb-5">
                <Row>
                    <Col xs={12}>
                        {[...queueList,...list]}
                    </Col>
                </Row>
            </Container>
        );

    }
}

ListItem.defaultProps = {
    name: "Store",
    data: {},
}

export default ListItem
