import * as ReducerActions from '../constants/ReducerActions/EditLocation'
import ProfileAction from './Profile';
import ApiProfile from "api/ApiProfile";
import EditLocationForm from "constants/Forms/EditProfile";
import { validate } from "./FieldValidator";


const EditLocationAction = {
    FetchRequest: '',
    initAction: () => {
        return { type: EditLocationAction.FetchRequest }
    },

    addError: (dispatch) => (errors) => dispatch({ type: ReducerActions.UPDATE_ERROR, payload: errors }),

    onValidateError: (dispatch) => (errors) => dispatch({ type: ReducerActions.SUBMIT_ERROR, payload: errors }),

    validate: (data, dispatch) => {
        // Clean all errors
        EditLocationAction.onValidateError(dispatch)({});
        return validate(data, EditLocationForm.fields, EditLocationAction.onValidateError(dispatch));
    },

    save: () => (dispatch, getState) =>
        new Promise(function (resolve, reject) {
            if (EditLocationAction.validate(getState().EditLocationReducer, dispatch)) {
                const data = {
                    city_id: getState().EditLocationReducer.city_id,
                    accept_terms_and_conditions: getState().EditLocationReducer.accept_terms_and_conditions,
                }
                dispatch({type:ReducerActions.SUBMIT});
                ApiProfile
                    .city(data)
                    .then(response => {
                        return dispatch(ProfileAction.loadProfileData())
                    })
                    .then(response => resolve(response))
                    .catch(error => {
                        if (error.response) {
                            dispatch(EditLocationAction.showErrors(error.response.data))
                            reject(error.response.data)
                        } else {
                            dispatch(EditLocationAction.showErrors(error))
                            reject(error)
                        }
                    })
                    ;
            } else {
                reject()
            }
        })
    ,

    showErrors: (response) => {
        return { type: ReducerActions.SUBMIT_ERROR, payload: response || null }
    },

    success: (response) => {
        return { type: ReducerActions.SUBMIT_DONE, payload: response.data || null };
    },

};

export default EditLocationAction
