import React, {Component} from 'react';
import {connect} from 'react-redux'
import {Col, Container, Row} from 'reactstrap';
import BookingAction from "../../actions/Booking";
import {withRouter} from 'react-router-dom'
import Waiting from "../../components/Waiting/Waiting";
import {isAuth} from "../../isAuth";
import StoreItem from '../../components/Lists/StoreItem';
import _ from "lodash";
import ErrorAlert from "../../components/Alert/ErrorAlert";
import JumbotronVirtualQueue from "../../components/Jumbotron/JumbotronVirtualQueue";
import queryString from 'query-string';
import LoginAction from "../../actions/Login";
import {LOAD_VIRTUAL_QUEUE_START} from 'constants/ReducerActions/VirtualQueueDetail'
import {SET_VIRTUAL_QUEUE} from '../../constants/ReducerActions/Settings';

class VirtualQueueDetailView extends Component {


    queueInfoTimer = null;

    startQueueDaemon = (queue_id) => {
        this.props.getQueue(queue_id)
        this.queueInfoTimer = setInterval(() => {
            this.props.getQueue(queue_id)
        }, 30000)
    }

    componentDidMount() {
        const {queue_id} = this.props.match.params

        const {hash, passtemp} = queryString.parse(this.props.location.search)
        if (hash !== null && hash !== undefined && passtemp !== null && passtemp !== undefined) {
            this.props.onInit(queue_id, this.startQueueDaemon, {hash, passtemp});
        } else {
            if (isAuth()) {
                this.props.onInit(queue_id, this.startQueueDaemon);
            } else {
                this.props.onInit(queue_id, this.startQueueDaemon, {hash, passtemp});
            }
        }
    }

    componentWillUnmount() {
        clearInterval(this.queueInfoTimer);
    }


    submitNewVirtualQueue = (e) => {
        if (isAuth()) {
            this.props.onSubmit();
        } else {
            this.props.setPending();
            this.props.history.push('/');
        }
    }

    render() {
        const parentStoreId = _.get(this.props.data, 'queue.store.parent.id', 0);
        this.props.settings.parentStoreId = parentStoreId;

        const turno = _.get(this.props.data, 'iid', 0);

        const actual = _.get(this.props.data, 'queue.actual', 0) ?? 0

        const people_ahead = turno - actual

        return <Waiting waiting={this.props.waiting} waiting_alt={this.props.waiting_alt}>
            <h2 className="d-none d-sm-block">{_.get(this, 'props.store.parent.name', 'Fila Virtual')}</h2>
            <Container fluid id="VirtualQueueNewView" className="virtual-queue-view">
                <Row>
                    <Col className="pb-3 my-4 pl-2 border shadow-lg bg-gradient-light" style={{borderRadius: '.7rem'}}>
                        <StoreItem style={{border: 'none'}}
                                   key={this.props.store.id}
                                   item={this.props.store}
                                   disableClick enabled={true}/></Col>
                </Row>
                <Row>
                    <Col className="text-center" xs={6}>
                        <JumbotronVirtualQueue pre="Actual"><h1>{actual}</h1></JumbotronVirtualQueue>
                    </Col>
                    <Col className="text-center" xs={6}>
                        <JumbotronVirtualQueue pre="Tu turno"><h1>{turno}</h1></JumbotronVirtualQueue>
                    </Col>
                    <Col className="text-center">
                        {people_ahead > 1 ?
                            <JumbotronVirtualQueue pre="Personas esperando"><h1>{people_ahead}</h1>
                            </JumbotronVirtualQueue>
                            : people_ahead === 1 ?
                                <JumbotronVirtualQueue><h1>Eres el próximo</h1></JumbotronVirtualQueue>
                                : people_ahead === 0 ?
                                    <JumbotronVirtualQueue><h1>Es tu turno</h1></JumbotronVirtualQueue>
                                    : null
                        }

                        {this.props.errors && <ErrorAlert text="Ocurrio un error"/>}
                    </Col></Row>
            </Container>
        </Waiting>
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        store: state.VirtualQueueDetailReducer.data?.queue?.store || {
            id: 0, name: "",
            street: "",
            street_number: "",
            suburb: "",
            state: "",
        },
        data: state.VirtualQueueDetailReducer.data || {},
        errors: state.VirtualQueueDetailReducer.errors || null,
        waiting: state.VirtualQueueDetailReducer.waiting || null,
        settings: state.SettingsReducer
    }
};

const mapDispatchToProps = (dispatch, getState) => {
    return {
        onInit: (queue_id, daemon, guestLogin) => {
            dispatch({type: SET_VIRTUAL_QUEUE});
            dispatch({type: LOAD_VIRTUAL_QUEUE_START});
            if (guestLogin) {
                dispatch(LoginAction.guestLogin({
                    username: guestLogin?.hash,
                    password: guestLogin?.passtemp
                })).then(() => {
                    console.log(isAuth(), getState.ProfileReducer)
                    daemon(queue_id);
                }).catch((err) => {
                    console.log(err);
                    daemon(queue_id);
                })
            } else {
                daemon(queue_id);
            }
        },
        onSubmit: () => {
            return dispatch(BookingAction.newVirtualQueue());
        },
        setPending: () => {
            dispatch(BookingAction.setPendingSaveOnLogin())
        },
        getQueue: (id) => {
            return dispatch(BookingAction.getVirtualQueue(id))
        }
    }
};


export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(VirtualQueueDetailView));

