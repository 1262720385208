const ChangePasswordForm = {
    'name': 'ChangePassword',
    'title': 'Cambiar clave',
    'description': 'Agenda tu visita al mall y salta la fila de tus tiendas favoritas con Turnos Cencosud',
    'fields': {
        'current_password': {
            'label': 'Clave actual',
            'type': 'password',
            'required': true,
            'min-length': 8,
            'maxLength': 30,
        },
        'password': {
            'label': 'Nueva clave',
            'type': 'password',
            'required': true,
            'description': '',
            'min-length': 8,
            'maxLength': 30,
        },
        'password_confirmation': {
            'label': 'Repetir nueva clave',
            'type': 'password',
            'required': true,
            'description': '',
            "min-length": 8,
            "match": "password",
            'maxLength': 30,
        }
    },
    'buttons': {
        'submit': {
            'color': 'primary',
            'type': 'submit',
            'label': 'Guardar',
            'block': true,
        }
    }

}

export default ChangePasswordForm
