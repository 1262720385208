import {store} from "./ConfigureStore";
import AuthAction from "./actions/Auth"

const token = () => store.getState().AuthReducer.access_token

const isAuth = (state = store.getState()) => (state.AuthReducer.access_token && state.ProfileReducer.city_id )

const needsFulfillment = (state = store.getState()) => token() && !isAuth(state);

const isSocial = () => store.getState().ProfileReducer.social

const refreshToken = () => store.getState().AuthReducer.refresh_token

const setToken = (access_token, refresh_token) => store.dispatch(AuthAction.login(access_token, refresh_token))

const isGuest = (state = store.getState()) => state.ProfileReducer.role === 'guest'

const isVirtualQueue = () => store.getState().SettingsReducer.isVirtualQueue;

const noPeru = (state = store.getState()) => (state.ProfileReducer.city.country_id !== 3 )

export default token
export {refreshToken, setToken, isAuth, isSocial, isGuest, isVirtualQueue, needsFulfillment, noPeru}

