import React, { Component } from 'react';
import PropTypes from "prop-types";
import { Alert as RSAlert, Container, Col, Row } from 'reactstrap';

class Alert extends Component {

    state = {
        isVisible: true
    }

    render() {
        const content = this.props.header ?
            <><h4 className="alert-heading">{this.props.header}</h4><p>
                {this.props.text}
            </p></> : this.props.text || this.props.children
        return (
            <Container>
                <Row>
                    <Col sm={12}>
                        <RSAlert color={this.props.color} isOpen={this.state.isVisible} toggle={this.props.dismiss ? () => this.setState({ isVisible: false }) : undefined}>
                            {content}
                        </RSAlert>
                    </Col>
                </Row>
            </Container>
        );
    }

}

Alert.propTypes = {
    text: PropTypes.string.isRequired,
    header: PropTypes.string,
    dismiss: PropTypes.bool,
    color: PropTypes.string,
}

Alert.defaultProps = {
    dismiss: false,
    color: "primary",
}

export default Alert;
