import Api from './Api'
import config from 'configReactApp'

class _ApiProfile extends Api {

    password(data, config) {
        return this.put('password', data, config);
    }

    update(data,config) {
        return this.post('',data,config)
    }

    deleteAccount(){
        return this.delete('');
    }

    city(data,config){
        return this.post('city',data,config);
    }

}

const ApiProfile = new _ApiProfile({endpoint: config('api.endpoint.profile')});

export default ApiProfile
