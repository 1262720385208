import React from 'react';
import Menu from 'components/Menu/Menu';
import {Container} from 'reactstrap';

const Footer = (props) => {

    return <Container tag="footer" className="d-block d-md-none fixed-bottom p-0 bg-white" fluid={true}>
        <Menu title={props.title}/>
    </Container>
        ;
}

export default Footer;
