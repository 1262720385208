import Api from './Api'
import configReactApp from 'configReactApp'

class _ApiSocialLogin extends Api {

    login(data, config) {
        data={
            ...data,
            "grant_type": configReactApp('api.grant_type_social'),
            "client_id": configReactApp('api.client_id'),
            "client_secret": configReactApp('api.client_secret'),
            "scope": configReactApp('api.scopes'),
        };
        return this.post('login', data, config);
    }
}

const ApiSocialLogin = new _ApiSocialLogin({
    endpoint: configReactApp('api.endpoint.social_login')
});


export default ApiSocialLogin
