import React, { Component } from 'react';
import {connect} from 'react-redux';
import QrScan from '../components/QR/QRScan';
import {Button} from 'reactstrap';
import {withRouter} from "react-router-dom";
import ErrorAlert from "../components/Alert/ErrorAlert";
import config from "../configReactApp";
import StoreAction from 'actions/Store';
import OverlaySpinner from '../components/OverlaySpinner/OverlaySpinner';

class QRScanView extends Component {
  defaultErrorDescription = "Algo inesperado ocurrió con tu reserva. Por favor consulta con el guardia de la puerta para que pueda ayudarte";
  
  state = {
    errorMessage: '',
    waiting: false
  }

  qrScanQueue = [];

  componentDidUpdate(prevProps){
    const {errors} = this.props;
    if(errors && errors !== prevProps.errors){
      this.qrScanQueue = [];
      this.setState({errorMessage: errors.data.message, waiting: false});
    }
  }

  extractStoreId = (qrData) => {
    // return the las url segment which should be the storeId
    return qrData.substring(qrData.lastIndexOf('/') + 1)
  }

  handleScan = data => {
    // If we already got a pending qr to be processed, don't do anything
    if(this.qrScanQueue.length !== 0){
      return;
    }

    if (!data) {
        return;
    }
    const {bookingCode, history, onHandleScan} = this.props;
    const storeId = this.extractStoreId(data);

    // If parsed storeId is not a number, then show error message
    if (isNaN(storeId)) {
        this.setState({errorMessage: "Invalid store."});
        return;
    }

    this.qrScanQueue.push(data);
    this.setState({waiting: true});
    if (storeId) {
      onHandleScan(storeId, bookingCode)
        .then(response => {
          if (response.status === 204) {
              history.push(config('app.succeed_check_in'));
          }
        });
    }
  }

  handleError = err => {
      this.setState({errorMessage: err.message});
  }

  render() {
    const {errorMessage} = this.state;

    const showErrors = errorMessage !== '';
    const alertErrors = showErrors ? <ErrorAlert header={errorMessage} text={this.defaultErrorDescription}/> : '';

    const QRScanButton = process.env.REACT_APP_SHOW_QR_BUTTON ?
      <Button color="success" onClick={() => this.handleScan("https://turnera.cenco.app/110")}>Enviar QR</Button>
      : null;

    return (
      <div>
        {!showErrors 
          ? 
          <div style={{padding: '40px'}}>
            {this.state.waiting
              ? <OverlaySpinner backdrop={true}/> 
              : null}

                <QrScan
                handleError={this.handleError}
                handleScan={this.handleScan}
                />
          </div>
          : null
        }
        {QRScanButton}
        <br/>
        <br/>
        {alertErrors}
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
      bookingCode: state.BookingDetailReducer.data.code || null,
      errors: state.StoreReducer.errors || null,
  }
};

const mpaDispatchToProps = dispatch => {
  return {
    onHandleScan: (storeId, bookingCode) => {
      return dispatch(StoreAction.checkIn(storeId, bookingCode))
    },
  }
}

export default connect(mapStateToProps, mpaDispatchToProps)(withRouter(QRScanView));
