import React from 'react';
import FormInput from "./FormInput";
import PropTypes from "prop-types";
import { InputGroup, Input, InputGroupAddon, InputGroupText, FormFeedback } from "reactstrap";

class FormLabeledInputGroup extends FormInput {

    state = {}

    renderInput() {
        const p = this.prepareProperties({
            id: this.getIdentification(),
            name: this.props.name,
            className: this.props.className,
            valid: (!this.props.errors.length > 0) && this.props.value !== '',
            invalid: (this.props.errors.length > 0),
            value: this.props.value,

        });
        const renderErrors = this.renderErrors();

        return (
            <InputGroup>
                <InputGroupAddon addonType="prepend">
                    <InputGroupText className="bg-primary text-white">{this.props.prefix}</InputGroupText>
                </InputGroupAddon>
                <Input {...p} onChange={this.handleEvents} placeholder={this.props.placeholder} />
                {renderErrors}
            </InputGroup>
        );
    }

    renderErrors() {
        if (this.props.errors) {
            const message = this.props.errors.map(e => <div>{e}<br /></div>);
            return <FormFeedback>{message}</FormFeedback>;
        }
    }
}

FormLabeledInputGroup.propTypes = {
    ...FormInput.propTypes,
    options: PropTypes.array.isRequired,

};
FormLabeledInputGroup.defaultProps = {
    ...FormInput.defaultProps,
    options: []
};

export default FormLabeledInputGroup;
