import React from "react";
import Feedback from "../../components/Feedback/Feedback";

export default () => <Feedback title="Términos del Servicio" icon={false}>
    <h5>Uso de la información </h5>

<p>La utilización de la aplicación para la solicitud de turnos de ingreso a los Shoppings de propiedad de Cencosud S.A. (“App”) implica que el usuario de la App (“Usuario/s”) autoriza a Cencosud S.A. (“Cencosud”) a utilizar los datos personales del Usuario dentro del marco establecido por la ley 25.326, para que Cencosud efectúe acciones de marketing y promociones comerciales. 

Los Usuarios deberán notificar a Cencosud a la dirección de correo electrónico (info@unicenter.com.ar) cualquier cambio en sus datos personales para la actualización de la base de datos de la App. 

El Usuario expresamente acepta y acuerda: a) proveer la información en la solicitud de registro en la App y autorizar a Cencosud al acceso y tratamiento de la información allí provista. b) Que, frente a un reclamo de un Usuario, Cencosud utilice la información obrante en su base de datos. 

Cualquier comunicación cursada de Cencosud a un Usuario se considerará notificada si fue remitida al domicilio o al correo electrónico de éste obrante en los registros de Usuarios de la base de datos de la App. 

El Usuario reconoce el derecho de Cencosud de publicar, en la forma y por los medios que considere conveniente los datos personales de los Usuarios, sin ningún tipo de compensación y sin que sea necesario requerirles otra autorización adicional, lo que el Usuario comprende y acepta. 

Cencosud podrá analizar toda información actual o futura obtenida por el uso de la App (incluyendo aquella que pueda indicar ciertas preferencias de gastos) y mantenerla en un archivo para el uso que considere pertinente, siempre dentro de lo permitido por las normas legales. Asimismo, podrá intercambiar dicha información en forma confidencial con otras empresas relacionadas societarias o contractualmente con el grupo de empresas de Cencosud, las que se enuncian a continuación: JUMBO, DISCO, VEA, BLAISTEN, TARJETA CENCOSUD y  AVENTURA CENTER, de propiedad de Cencosud, para que cualquiera de ellas pueda, en forma directa o a través de terceros ofrecer otros productos o servicios de interés del Usuario ya sea por teléfono, correo electrónico, Internet u otro medio de comunicación. 

El Usuario acepta y consiente que, en algunos casos, los cesionarios de los datos y de la información, podrían encontrarse o tener operaciones relevantes en jurisdicciones cuyo nivel de protección podría no ser equivalente al otorgado por la legislación argentina. Cencosud garantizará que todos los cesionarios garanticen los mismos estándares de seguridad y confidencialidad requeridos para el tratamiento de datos e información, de acuerdo con lo previsto en el artículo 12 de la ley 25.326 y suscribirá los Acuerdos de Transferencia Internacional necesarios en el marco de tal cumplimiento.

Cencosud podrá almacenar todos los datos recabados del Usuario en una base de datos ubicada en el domicilio legal de Cencosud, donde el Usuario podrá ejercer los derechos de acceso, rectificación o supresión sobre los datos conforme lo dispuesto por la ley 25.326. 

A todos los efectos de esta Política de Privacidad, judiciales y extrajudiciales, Cencosud constituye domicilio en Suipacha 1111, piso 18°, Ciudad Autónoma de Buenos Aires y los Usuarios en el que indiquen al registrarse en la App y para el caso de litigio se someten a los tribunales ordinarios del Poder Judicial de la Nación con asiento en la Ciudad Autónoma de Buenos Aires. El Usuario renuncia expresamente a recusar sin causa al juez que intervenga en los eventuales litigios.

Cencosud podrá revelar información personal a terceros sin consentimiento de los Usuarios, cuando sea requerido por ley o por resolución judicial que así lo autorice, para cooperar con autoridades gubernamentales en investigaciones judiciales o administrativas y para hacer cumplir o proteger la propiedad intelectual o derechos de terceros o de Cencosud.
</p>

<h5>Resguardo de la privacidad de los Usuarios</h5>

<p>
Cencosud cumple con las normas aplicables al resguardo de la privacidad de los Usuarios. Esta política de privacidad (la "Política de Privacidad") describe los datos y la información que Cencosud podrá recopilar sobre el Usuario y la finalidad que podrá dársele en tal carácter. Asimismo, contiene las medidas de seguridad adoptadas por Cencosud para proteger la información del Usuario, su posibilidad de solicitar el acceso, rectificación, modificación o supresión de su información personal, y la información sobre a quién podrá contactar en caso de querer ejercer sus derechos o con relación al alcance de esta Política de Privacidad.

La Política de Privacidad contempla la recopilación y el uso de información personal del Usuario.
</p>

<h5>Consentimiento del titular de los datos</h5>

<p>
Al acceder, utilizar o registrarse en la App, los Usuarios autorizan expresamente a Cencosud a recopilar, usar, tratar, divulgar, ceder, transferir o de cualquier otro modo compartir su información personal de acuerdo con esta Política de Privacidad. Si el Usuario no Usuario no estuvieran de acuerdo con esta Política de Privacidad, no deberán ingresar sus datos ni utilizar la App.

El Usuario manifiesta conocer que al hacer “click” en la opción “Acepto Términos y Condiciones” ubicada al final del formulario de registro, reconocerá que ha leído íntegramente los términos de esta Política de Privacidad y que comprende su alcance y las consecuencias de compartir sus datos e información con Cencosud, para su tratamiento y finalidad aquí enunciados.  
</p>

<h5>Información personal solicitada</h5>

<p>
Al momento de optar por registrase en la App, el Usuario deberá proporcionar ciertos datos personales para propósitos tales como facilitar la comunicación con el Usuario, chequear el cumplimiento de los requisitos de registro a la App, posibilitar el acceso a ciertas secciones o áreas de la App, participar en promociones efectuadas por Cencosud, entre otros. 

El Usuario siempre tendrá la opción de elegir si desea o no proporcionar su información personal, entendiendo que hay datos mínimos que deben ser completados a los efectos de posibilitar el análisis que habilite (o no) su registro en la App. En caso de elegir no proporcionar la información personal solicitada, se considerará que el Usuario no desea utilizar la App. 
Al completar el registro de sus datos en la App, el Usuario acepta y se obliga a (a) suministrar datos exactos, actuales y completos sobre su persona y (b) mantener actualizados sus datos a través del enlace que obra la App a tal efecto o a través del mecanismo que en el futuro se implemente, de manera que la información permanezca exacta, veraz, actualizada y completa. El Usuario responderá, en cualquier caso, respecto de la veracidad de los datos facilitados, reservándose Cencosud el derecho de excluir a todo Usuario que haya facilitado datos falsos, sin perjuicio de iniciar las acciones legales que correspondieren.
</p>

<h5>Solicitud de turnos en Shoppings de Cencosud a través de la App</h5>

<p>
Si el Usuario solicita un turno para concurrir a cualquiera de los shoppings de propiedad de Cencosud en la República Argentina mediante la App, en calidad de Usuario de la App, Cencosud o sus proveedores de servicios podrán recabar información adicional sobre el Usuario, ya sea personal o de otro tipo, tales como su dirección, número de teléfono, información sobre medios de pago o forma de envío, de corresponder. 
</p>

<h5>Medidas de Seguridad respecto del tratamiento de la información</h5>

<p>
A fin de prevenir el acceso no autorizado, mantener la seguridad y confidencialidad de los datos personales y asegurar el uso correcto de la información, Cencosud ha implementado el uso firewalls y otros medios técnicos destinados a este fin. El Usuario también deberá tomar los recaudos necesarios a fin proteger su información personal mientras se encuentre utilizando la App. En particular, el Usuario será exclusivamente responsable de la filtración de su información en el supuesto de que su clave de acceso o contraseña sea puesta en conocimiento de terceros con su autorización.
</p>

<h5>Derechos de acceso a su información personal</h5>

<p>
En cumplimiento del derecho de los titulares de datos, los Usuarios podrán ejercer los derechos de acceso, rectificación, actualización o supresión de datos y oposición mediante el envío de un correo electrónico dirigido a info@unicenter.com.ar. Ante el requerimiento formal realizado por el Usuario y acreditada su identidad, Cencosud procederá a brindar el acceso a su información personal, así como a rectificar, actualizar o suprimir los datos personales del afectado según corresponda, en los términos y plazos legales. 

En cumplimiento de la Disposición de la DIRECCIÓN NACIONAL DE PROTECCIÓN DE DATOS PERSONALES 10/2008, se informa al Usuario que:

“El titular de los datos personales tiene la facultad de ejercer el derecho de acceso a los mismos en forma gratuita a intervalos no inferiores a seis meses, salvo que se acredite un interés legítimo al efecto conforme lo establecido en el artículo 14, inciso 3 de la Ley N.º 25.326.”

“La Agencia de Acceso a la Información Pública, Órgano de Control de la Ley N.º 25.326, tiene la atribución de atender las denuncias y reclamos que se interpongan con relación al incumplimiento de las normas sobre protección de datos personales.”


Para contactar a la Agencia de Acceso a la Información Pública: Av. Presidente Julio A. Roca 710, Piso 2, Ciudad de Buenos Aires Tel: (+5411) 2821-0047 o  https://www.argentina.gob.ar/aaip.
</p>

<h5>Plazo de conservación de los datos de Usuarios</h5>

<p>
Cencosud conservará la información personal de los Usuarios por el término necesario para el cumplimiento de las finalidades descriptas en esta Política de Privacidad y/u obligaciones legales. La información personal que se recabe de los Usuarios podrá ser eliminada o resultar indisponible cuando haya dejado de ser necesaria o pertinente a los fines para los cuales hubiese sido recolectada, salvo que exista una obligación legal de conservarla por un término mayor.
</p>
<h5>Cambios a la Política de Privacidad</h5>

<p>
Cencosud se reserva el derecho de modificar esta Política de Privacidad periódicamente, sin previa notificación del Usuario. Sin perjuicio de esta facultad, modificado el alcance u otros términos de la Política de Privacidad se notificará a los Usuarios de la última modificación introducida. La utilización de la App por parte del Usuario después de realizados los cambios a esta Política de Privacidad presumirá la aceptación de dichas modificaciones. Le sugerimos a los Usuarios la visita periódica y lectura de esta Política de Privacidad para estar informado de la versión más actual.
</p>
<h5>Recopilación automática de información</h5>

<p>
La App puede que recabe automáticamente cierta información concerniente a los Usuarios, tales como dirección del Protocolo Internet (IP) de su computadora, dirección del IP de su proveedor de servicio de Internet, fecha y hora en que ingresó en la App, dirección de Internet de la página desde la que se conectó directamente a la App, sistema operativo que está usando, secciones de la App que el Usuario visitó, páginas leídas e imágenes visualizadas, datos de geolocalización y cualquier otro contenido que haya enviado a o descargado de la App. Cencosud utilizará esta información con propósitos administrativos o estadísticos, comerciales, publicitarios y para mejorar el servicio que brinda la App.
</p>
<h5>Cookies</h5> 

<p>
Uno de los mecanismos por los cuales se suele recopilar datos del Usuario de manera automática es a través del uso de “cookies” o mecanismos similares. La App utiliza “cookies”. Las cookies son definidas como un paquete de datos que un navegador web almacena de forma automática en el ordenador de un Usuario cuando éste visita una página web o una aplicación. De esta manera, si el Usuario ingresara nuevamente a la App, la información almacenada en su computadora permitirá a Cencosud reconocer futuras visitas por parte del mismo Usuario.

Cencosud utilizará las cookies y mecanismos similares para las siguientes finalidades, cuya enunciación no es taxativa: 

1. Ofrecer al Usuario un contenido personalizado de la App e información para su uso personal;
2. Procesar la actividad del Usuario en la App;
3. Implementar procesos automáticas para completar formularios en línea con los datos personales del Usuario para agilizar el proceso de consulta; y
4. Mejorar la experiencia del Usuario en la App.

De conformidad con la normativa vigente en materia de Protección de Datos Personales y toda vez que ningún proceso automático de recolección de datos puede ser llevado a cabo sin el consentimiento previo del Usuario, el Usuario podrá optar por rechazar las “cookies” si la configuración de su navegador así lo permite, pero advertimos que su rechazo podría afectar la utilización de la App y la capacidad del Usuario para acceder a ciertas secciones o funcionalidades. 

La publicidad desplegada en la App, ya sea que se trate de publicidad de Cencosud o que sea publicidad de terceros que eventualmente redirija a sitios Web de terceros, también puede contener cookies u otras tecnologías similares. Dicha publicidad puede provenir de terceras compañías, incluyendo sitios operados por los socios comerciales de Cencosud, sus proveedores de servicios o proveedores de contenido. Cuando accede a estos sitios Web a través de los enlaces o vínculos correspondientes, el Usuario reconoce estar contactándose con otro sitio distinto a la App. En consecuencia, Cencosud no tendrá responsabilidad u obligación con respecto a esas cookies u otras tecnologías similares utilizadas en dicha publicidad o respecto al uso o divulgación de información recolectada a través de las cookies de publicidad, por cuanto no tiene acceso a su administración ni implementación técnica. Ante dicho supuesto, el Usuario se sujetará a los Términos y Condiciones establecidos por el titular del sitio Web de terceros, al que dicha publicidad redirija y por lo tanto deberá efectuar el reclamo mediante el mecanismo previsto y ante el titular del sitio Web de que se trate.
</p>
</Feedback>