import * as ReducerActions from '../constants/ReducerActions/Profile'
import ApiProfile from "api/ApiProfile";

const ProfileAction = {

    loadProfileData: () => (dispatch, getState) =>
        new Promise(function (resolve, reject) {
            dispatch({type:ReducerActions.LOAD_PROFILE_START});
            ApiProfile
                .get()
                .then(response => {
                    console.log("LLAMADNO A PROFILE,", response)
                    dispatch(ProfileAction.success(response))
                    resolve(response)
                })
                .catch(error => {
                    console.log("LLAMADNO A ERROR,", error)
                    if (error.response) {
                        reject(error.response.data)
                    } else {
                        reject(error)
                    }
                })
                ;
        }
        ),

    success: (response) => {
        return { type: ReducerActions.LOAD_PROFILE_DATA, payload: response.data || null };
    },

    cleanProfileData: () => {
        return { type: ReducerActions.CLEAN_PROFILE_DATA }
    }
};

export default ProfileAction
