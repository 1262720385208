import ApiCity from '../api/ApiCity'
import ApiStore from '../api/ApiStore'
import * as Actions from '../constants/ReducerActions/Store'
import * as VirtualQueueActions from '../constants/ReducerActions/VirtualQueue'
import * as ProgressActions from '../constants/ReducerActions/Progress'
import _ from 'lodash';
import {SET_PARENT} from "../constants/ReducerActions/Store";

const StoreAction = {
    initAction: () => {
        return {type: Actions.LIST}
    },
    listAppendOrCreate: (action, cityId, storeId, response) => {
        if (storeId === undefined && response === undefined) {
            response = cityId;
            cityId = _.get(response, '0.city_id');
        }
        if (!storeId) {
            storeId = '0';
        }
        if (cityId && storeId && response) {
            return {type: action, payload: {cityId, storeId, list: response}}
        } else {
            return {type: Actions.LIST_ERROR, payload: 'city store or response invalid'}
        }
    },
    listSuccess: (cityId, storeId, response) => {
        return StoreAction.listAppendOrCreate(Actions.LIST_DONE, cityId, storeId, response);
    },
    listErrors: (response) => {
        return {type: Actions.LIST_ERROR, payload: response || null}
    },
    listAppend: (cityId, storeId, response) => {
        return StoreAction.listAppendOrCreate(Actions.LIST_APPEND, cityId, storeId, response);
    },
    updateFilter: () => {
        return {type: Actions.FILTER_LIST};
    },
    setParent: (parent) => {
        return {type: Actions.SET_PARENT, payload: parent};
    },
    clearParent: () => {
        return {type: Actions.CLEAR_PARENT};
    },
    clearErrors: () => {
        return {type: Actions.CLEAR_ERRORS}
    },
    setProgress: (meta) => {
        return {type: ProgressActions.SET_VALUE, payload: Math.round(meta.current_page / meta.last_page) * 100};
    },
    get: (storeId) => (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            // if (!getState().StoreReducer.all) {
            ApiStore.get(storeId).then(response => {
                dispatch(StoreAction.setParent(response.data))
                resolve(response.data);
            })
                .catch(error => {
                    dispatch(StoreAction.listErrors(error))
                    reject(error);
                })
            // } else {
            //     const store = Object.values(getState().StoreReducer.all).find(o => o.id === parseInt(storeId));
            //     resolve(store);
            // }
        })
    },
    getChildren: (storeId) => (dispatch, getState) => {
        dispatch({type: Actions.LIST});

        ApiStore.children(storeId).then(response => {
            const cityId = response.data.data[0].city_id;

            dispatch(StoreAction.listSuccess(cityId, storeId, response.data.data));
            if (response.data.data[0]?.parent) {
                dispatch({type: SET_PARENT, payload: response.data.data[0].parent});
            }

            const lastPage = response.data.meta.last_page;
            if (lastPage === 1) {
                return;
            }

            dispatch(StoreAction.setProgress(response.data.meta));
            let page = 1;
            do {
                page++;
                ApiStore.children(storeId, page).then(response_page => {

                    dispatch(StoreAction.listAppend(cityId, storeId, response_page.data.data));
                    // }).catch(error => {
                    //     dispatch(StoreAction.listErrors(error));
                    dispatch(StoreAction.setProgress(response_page.data.meta));
                });

            } while (page <= lastPage);


        }).catch(error => {
            dispatch(StoreAction.listErrors(error));
        });
    },
    queues: (storeId) => (dispatch, getState) => new Promise(function (resolve, reject) {
        dispatch({type: VirtualQueueActions.LIST})
        ApiStore.queues(storeId).then(response => {
            dispatch({type: VirtualQueueActions.LIST_DONE, payload: response.data})
            resolve(response.data, dispatch)
        })
            .catch(error => {
                console.error(error)
                dispatch({type: VirtualQueueActions.LIST_ERROR, payload: error})
                reject(error);
            })
    }),
    all: () => (dispatch, getState) => {
        dispatch(StoreAction.initAction());

        const cityId = getState().ProfileReducer.city_id;
        ApiCity.stores(cityId).then(response => {
            dispatch(StoreAction.listSuccess(cityId, null, response.data.data));
            let page = _.get(response, 'data.meta.current_page', 1);
            const lastPage = _.get(response, 'data.meta.last_page', 1);
            if ("" + lastPage === "1") {
                return;
            }
            while (page < lastPage) {
                ApiCity.stores(cityId, page).then(response_page => {
                    dispatch(StoreAction.listAppend(response_page.data.data));
                    dispatch(StoreAction.setProgress(response_page.data.meta));
                }).catch(error => {
                    dispatch(StoreAction.listErrors(error));

                });
                page++;
            }
        }).catch(error => {
            dispatch(StoreAction.listErrors(error));
        });
    },
    filter: (text) => (dispatch, getState) => {
        dispatch({type: Actions.SET_FILTER, payload: {text}});
        dispatch(StoreAction.updateFilter());
    },
    checkIn: (storeId, bookingCode) => (dispatch, getState) => {
        return new Promise(function (resolve, reject) {
            dispatch(StoreAction.clearErrors);

            ApiStore.check_in(storeId, bookingCode)
                .then(response => {
                    resolve(response);
                })
                .catch(error => {
                    //const defaultErrorMessage = "Algo inesperado ocurrió con tu reserva. Por favor consulta con el guardia de la puerta para que pueda ayudarte";
                    //const errorMessage = `${error.response.data.message}
                    //${defaultErrorMessage}`;

                    //error.response.data.message = errorMessage;

                    dispatch(StoreAction.listErrors(error.response));
                    reject(error);
                });
        });
    },
    calendar: (storeId) => (dispatch, getState) => {
        return new Promise(function (resolve, reject) {

            ApiStore.calendar(storeId)
                .then(response => {
                    console.log("Calendar response", response);
                    resolve(response);
                })
                .catch(error => {
                    dispatch(StoreAction.listErrors(error.response));
                    reject(error);
                });
        });
    }

};

export default StoreAction
