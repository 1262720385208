import * as ReducerActions from '../constants/ReducerActions/ForgotPassword'
import ApiForgotPassword from "api/ApiForgotPassword";

const ForgotPasswordAction = {
    FetchRequest: '',
    initAction: () => {
        return {type: ForgotPasswordAction.FetchRequest}
    },

    validate: () => {
        return true;
    },

    save: () => (dispatch, getState) =>
        new Promise(function (resolve, reject) {
                let {errors, waiting, ...data} = {...getState().ForgotPasswordReducer};
                if (ForgotPasswordAction.validate(data)) {
                    ApiForgotPassword
                        .post('', data)
                        .then(response => {
                            dispatch(ForgotPasswordAction.success(response))
                            resolve(response)
                        })
                        .catch(error => {
                            console.log(error)
                            if (error.response) {
                                dispatch(ForgotPasswordAction.showErrors(error.response.data))
                                reject(error.response.data)
                            } else {
                                dispatch(ForgotPasswordAction.showErrors(error))
                                reject(error)
                            }
                        })
                    ;
                    dispatch(ForgotPasswordAction.initAction())
                } else {
                    dispatch(ForgotPasswordAction.showErrors())
                }
            }
        ),

    showErrors: (response) => {
        return {type: ReducerActions.SUBMIT_ERROR, payload: response || null}
    },

    success: (response) => {
        return {type: ReducerActions.SUBMIT_DONE, payload: response.data || null};
    },

};

export default ForgotPasswordAction
