import { validate as validateRut } from 'rut.js';
import { DNI, RUT, PASSPORT } from "../helper";

const isFieldValid = (field, fieldData, key, errors) => {
    let e = []
    if (field.maxLength && fieldData?.length > field.maxLength) {
        e = [...e, `El campo debe contener menos de ${field.maxLength} caracteres`]
    }
    if (field.regex) {
        const reg = new RegExp(field.regex);
        if (!reg.test(fieldData)) e = [...e, field.customValidateMessage || 'Campo con caracteres no válidos']
    }
    if (e.length > 0) { return { ...errors, [key]: e } }
    return errors
}

export const validate = (data, fields, errorAction) => {
    const errors = Object.entries(fields).reduce((errors, [key, value], i, vector) => isFieldValid(value, data[key], key, errors), {})
    if (Object.keys(errors).length > 0) {
        errorAction({ errors })
        return false
    }
    return true;
}

export const validateIdentityField = (identityId, identityValue, onError) => {
    let valid = false;
    switch (Number(identityId)) {
        case DNI:
            valid = isValidDni(identityValue);
            if (!valid) onError({ identity_value: ["El DNI ingresado no es válido"] })
            return valid;
        case PASSPORT:
            valid = isValidPassport(identityValue);
            if (!valid) onError({ identity_value: ["El Pasaporte ingresado no es válido"] })
            return valid;
        case RUT:
            valid = isValidRut(identityValue);
            if (!valid) onError({ identity_value: ["El RUT ingresado no es válido"] })
            return valid;
        default:
            return valid;
    }
}

const isValidDni = (value) => {
    return /^([1-9]){1}\d{6,7}$/.test(value);
}
const isValidPassport = (value) => {
    return new RegExp("^(?!^0+$)[a-zA-Z0-9]{6,9}$").test(value);
}
const isValidRut = (value) => {
    return validateRut(value);
}