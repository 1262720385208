import * as Actions from "../constants/ReducerActions/OAuth";
import {OAUTH_SET_REDIRECT_URL} from "../constants/ReducerActions/OAuth";
import LoginActions from "./Login";
import _ from 'lodash';
import {redirectToUrl} from 'helper'

const OAuthActions = {
    processLogin: () => (dispatch, getState) => new Promise((resolve, reject) => {
        let form = {
            ...(_.get(getState().OAuthReducer, 'data')),
            identity_id: _.get(getState().OAuthReducer, 'identity_id')
        };
        dispatch(LoginActions.socialLogin(form)).then(response => {
            const {redirectTo, params} = redirectToUrl(response)
            dispatch({type: OAUTH_SET_REDIRECT_URL, payload: redirectTo})
            resolve({response, redirectTo, params});
        }).catch(e => reject(e));
    }),
    processGoogle: (provider, identity_id, data) => (dispatch) => new Promise(resolve => {
        // LoginActions.socialLogin
        dispatch({type: Actions.OAUTH_LOGIN, payload: {provider, identity_id: identity_id}});
        dispatch({type: Actions.OAUTH_SET_PROFILE, payload: data});
        dispatch({type: Actions.OAUTH_PARSE_GOOGLE_PROFILE, payload: data});
        resolve(dispatch(OAuthActions.processLogin()));
    }),
    processFacebook: (provider, identity_id, data) => (dispatch) => new Promise((resolve, reject) => {
            // LoginActions.socialLogin
            dispatch({type: Actions.OAUTH_LOGIN, payload: {provider, identity_id: identity_id}});
            dispatch({type: Actions.OAUTH_SET_PROFILE, payload: data});
            dispatch({type: Actions.OAUTH_PARSE_FACEBOOK_PROFILE, payload: data})
            resolve(dispatch(OAuthActions.processLogin()));
        }
    )

}

export default OAuthActions;
