import {
    CityId,
    PhoneField,
} from './Fields'

// copiado de Register
const GuestRegisterForm = {
    'name': 'User',
    'title': {'text': 'Continuar como invitado'},
    'description': 'Ingresa tu teléfono y te avisaremos por Whatsapp cuándo te toque',
    'fields': {
        'city_id': {
            ...CityId,
            type: 'hidden',
            disabled: true,
            label: null,
        },
        'phone': {
            ...PhoneField,
            required: true
        },
    },
    'buttons': {
        'submit': {
            'type': 'submit',
            'block': true,
            'color': 'primary',
            'label': 'Acceder',
        }
    }

}

export default GuestRegisterForm
