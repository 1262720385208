import ApiBooking from '../api/ApiBooking'
import * as Actions from '../constants/ReducerActions/BookingList'

const BookingListAction = {
    initAction: () => {
        return { type: Actions.LIST }
    },
    listSuccess: (response) => {
        return { type: Actions.LIST_DONE, payload: response || null }
    },
    listErrors: (response) => {
        return { type: Actions.LIST_ERROR, payload: response || null }
    },
    all: () => (dispatch, getState) => {
        dispatch({type: Actions.LIST});
        ApiBooking
            .all()
            .then(response => {
                //console.log(response)
                dispatch(BookingListAction.listSuccess(response.data || {}))
            })
            .catch(error => {
                dispatch(BookingListAction.listErrors(error))
            })
            ;
        dispatch(BookingListAction.initAction())
    }
};

export default BookingListAction
