import ApiCountry from '../api/ApiCountry'
import * as Actions from '../constants/ReducerActions/Country'
import CityAction from "./City";
import { FILTER as FILTER_CITY } from "../constants/ReducerActions/City";

const CountryAction = {
    initAction: () => {
        return { type: Actions.LIST }
    },
    listSuccess: (response) => {
        return { type: Actions.LIST_DONE, payload: response || null }
    },
    listErrors: (response) => {
        return { type: Actions.LIST_ERROR, payload: response || null }
    },
    all: () => (dispatch, getState) => (new Promise(function (resolve, reject) {
        ApiCountry
            .all()
            .then(response => {
                if (response.data.length === 1) {
                    dispatch(CountryAction.cities(response.data[0].id))
                }
                dispatch(CountryAction.listSuccess(response.data || {}))
                resolve(response.data);
            })
            .catch(error => {
                dispatch(CountryAction.listErrors(error))
                reject(error)
            })
            ;
        dispatch(CountryAction.initAction())
    })),
    cities: (countryId) => (dispatch, getState) => new Promise((resolve,reject) => {
        // console.log(countryId)
        ApiCountry.setCountry(countryId)
            .cities()
            .then(response => {
                dispatch(CityAction.listSuccess(response.data || {}))
                resolve(response)
            })
            .catch(error => {
                dispatch(CityAction.listErrors(error))
                resolve(error)
            })

            ;
        ApiCountry.setCountry();
        dispatch({ type: FILTER_CITY })
    })
};

export default CountryAction
