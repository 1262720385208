let convert = (o, key, value) => {
    const t = typeof o;
    switch (t) {
        case 'string':
            return {'key': o, 'value': o}
        case 'object':
            const v = o[value] || o.value || o.name || o[0];
            const k = o[key] || o.key || o.id || v;
            return {key: k, value: v}
        default:
            console.warn(o);
    }
}
export const toOptions = (obj, key, value) => {
    const list = [];
    for (let i in obj) {
        if (obj.hasOwnProperty(i)) {
            list.push(convert(obj[i], key, value));
        }
    }
    return list;
}

export class InputEvts {
    list = {};
    add = (e, callback) => {
        let localList = this.list[e] || [];
        localList.push(callback);
        this.list[e] = localList;
        // console.log(this.list);
        return this;
    }
    keys = () => {
        let ev = [];
        for (let e in this.list) {
            if (this.list.hasOwnProperty(e)) {
                ev.push(e);
            }
        }
        return ev;
    }
    processEvent = (type, event) => {
        // console.log(type);
        // console.log(this.list);
        if (this.list.hasOwnProperty(type)) {
            // console.log(this.list[type]);
            this.list[type].forEach(callback => callback(event));
        }
    }
}


export const combineValues = (name, f, data, errors, inline, onChange) => {

    // console.log(name);
    // console.log(f.e);
    let e;
    if (!(f.e)) {
        e = new InputEvts();
        if (onChange) {
            e.add('change', onChange);
        }

    } else {
        e = f.e;
    }

    // console.log(e);
    const c = {
        ...f,
        name: name,
        value: data || '',
        e: e,
        inline: inline,
        errors: errors,
    };
    // console.log(c);
    return c;
}


