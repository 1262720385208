import React from 'react';
import {Container, Row, Col, Button} from 'reactstrap';
import Argentina from 'components/Carousel/Argentina'
import Peru from 'components/Carousel/Peru';
import Chile from 'components/Carousel/Chile';
import {useHistory} from "react-router-dom"
import {useSelector} from "react-redux";

const Slider = (country) => {
    switch(country){
        case "Argentina":
            return <Argentina/>;
        case "Peru":
            return <Peru/>;
        case 'Chile':
            return <Chile/>;
        default:
            return <Argentina/>;
    }
}


const DashboardView = (props) => {
    const history=useHistory();
    const country = useSelector(state => {
        return state.ProfileReducer.city.country.name;
    })
    return (
        <Container>
            <Row>
                <Col>
                    <h1 className="d-none d-sm-block text-blue">Turnera Cencosud</h1>
                    <h6 className="my-3 my-sm-5">Reserva tu turno para hacer tu visita más ágil y segura, evitando demoras</h6>
                    {/*<Alert color="warning" className="font-weight-bold" style={{borderRadius:'0.5rem'}}>Si estás en zona de cuarentena*/}
                    {/*    recuerda solicitar tu permiso para salir de casa</Alert>*/}
                </Col>
            </Row>
            <Row className="mt-3">
                <Col>
                    {Slider(country)}
                </Col>
            </Row>
            {
                (country === 'Peru') ? 
                <>
                </>
                :
                <Row style={{marginBottom: '1rem'}} className="mt-3 text-center">
                    <Col>
                        <Button color="primary" block onClick={()=>history.push('/search')}>Agendar Visita</Button>
                    </Col>
                </Row>
            }

        </Container>
    );
}

export default DashboardView;
