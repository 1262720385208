import axios from 'axios';
import configReactApp from 'configReactApp'
import token, {refreshToken, setToken} from "../isAuth"

export const SERVER_URL = configReactApp('api.server');
export const BASE_URI = configReactApp('api.base_path');

class Api {
    endpoint = '';
    base_url = '';

    axiosInstance = null;

    constructor(config) {
        this.axiosInstance = axios.create()
        this.defaultConfig();
        switch (typeof (config)) {
            case 'object':
                break;
            default:
                config = {endpoint: config};
                break;
        }
        let {url, endpoint, base_url, ...custom_config} = {
            endpoint: '',
            base_url: [
                SERVER_URL,
                BASE_URI
            ],
            ...config
        };

        this.endpoint = endpoint;
        this.base_url = base_url;
        this.config = custom_config;
    }

    prepareConfig(config) {
        config = {...this.config, ...config || {}};
        return config;
    }

    post(url, data, config) {

        const contentType = (data instanceof FormData ? 'multipart/form-data' : this.axiosInstance.defaults.headers['']);
        // const contentType = 'multipart/form-data';
        const ajaxrequest = this.prepareConfig({
            method: 'POST',
            url: this.prepareUrl(url),
            data: data,
            headers: {'Content-Type': contentType},
            ...config
        });
        return this.axiosInstance(ajaxrequest);

    }

    put(url, data, config) {
        const ajaxrequest = this.prepareConfig({
            method: 'PUT',
            url: this.prepareUrl(url),
            data: data,
            headers: {'Content-Type': 'application/json'},
            ...config
        });
        return this.axiosInstance(ajaxrequest);
    }

    all(url, config) {
        if (typeof (url) === 'object') {
            config = url;
            url = '';
        }
        return this.get(url, undefined, config);
    }

    get(url, data, config) {
        if (typeof (url) === 'object' && typeof (data) === 'undefined') {
            data = url;
            url = '';
        }
        config = this.prepareConfig(config);
        url = this.prepareUrl(url);
        return this.axiosInstance.get(url, {...config, params: {...(data || {})}});
    }

    prepareUrl(url) {
        const _url = this.createUrl([this.base_url, this.endpoint, url]);
        return _url;
    }

    createUrl(...path) {
        return path.toString().split(',').filter(e => e).join('/');
    }

    request(config) {
        return this.axiosInstance.request({...this.config, ...config, url: this.prepareUrl(config.url || '')});
    }

    find(data, config) {
        return this.get({...config, data: data});
    }

    create(data, config) {
        return this.post('', data, config);
    }

    update(id, data, config) {
        if (id && data) {
            //return this.post(id, data, config);
            return this.put(id, data, config);
        } else {
            console.error('ID or data missing');
        }
    }

    delete(id, config) {
        return this.axiosInstance.delete(this.prepareUrl(id), config);
    }

    store(id, data, config) {
        if (data) {
            return id ? this.update(id, data, config) : this.create(data, config);
        } else {
            console.error('Cannot save without data');
        }
    }

    save(data, config) {
        return data.id ? this.update(data.id, data, config) : this.create(data, config);
    }

    // Add a request interceptor
    // Este interceptor es para agregar el token antes de llamar a algun metodo de la API si es que el token existe
    addRequestIterceptor() {
        this.axiosInstance.interceptors.request.use(
            config => {
                const access_token = token()
                if (access_token) {
                    config.headers['Authorization'] = 'Bearer ' + access_token;
                }
                config.headers['Content-Type'] = 'application/json';
                return config;
            },
            error => {
                Promise.reject(error)
            });
    }

    // Add a response interceptor
    // Este interceptor es para las respuestas de la API. En caso de que el token haya espirado se solicita uno nuevo con el refresh token.
    // En caso de que se repita que la misma url vuelve a dar error de permiso se rechaza la solicitud
    addResponseInterceptor() {
        this.axiosInstance.interceptors.response.use((response) => {
            return response
        }, (error) => {
            const originalRequest = error.config;
            if (error.response?.status === 401 && !error.response.config._retry) {
                originalRequest._retry = true;
                const refresh_token = refreshToken();
                return ApiOauth.refresh({"refresh_token": refresh_token})
                    .then(res => {
                        if (res.status === 200) {
                            setToken(res.data.access_token, res.data.refresh_token)
                            this.axiosInstance.defaults.headers.common['Authorization'] = 'Bearer ' + token();
                            return this.axiosInstance(originalRequest);
                        }
                    })
            }
            return Promise.reject(error);
        });
    }

    // Configura interceptors para controlar autenticacion por JWT
    defaultConfig() {
        this.addRequestIterceptor()
        this.addResponseInterceptor()
    }


}

class _ApiOauth extends Api {



    login(data, config) {
        data={
            ...data,
            "grant_type": configReactApp('api.grant_type'),
            "client_id": configReactApp('api.client_id'),
            "client_secret": configReactApp('api.client_secret'),
            "scope": configReactApp('api.scopes'),

        };
        // console.log(data);
        return this.post('token', data, config);
    }

    loginGuest(data, config) {
        data={
            ...data,
            "grant_type": configReactApp('api.grant_type_phone'),
            "client_id": configReactApp('api.client_id'),
            "client_secret": configReactApp('api.client_secret'),
            "scope": configReactApp('api.scopes'),

        };
        // console.log(data);
        return this.post('token', data, config);
    }

    refresh(data, config) {
        data={
            ...data,
            "grant_type": 'refresh_token',
            "client_id": configReactApp('api.client_id'),
            "client_secret": configReactApp('api.client_secret'),
            "scope": configReactApp('api.scopes'),
        }
        return this.post('token', data, config);
    }

    addResponseInterceptor() {
        this.axiosInstance.interceptors.response.use((response) => {
            return response
        }, function (error) {
            const originalRequest = error.config;

            const base_url = configReactApp('api.server') + configReactApp('api.base_path');

            if (error.response?.status === 401 && originalRequest?.url === base_url + configReactApp("api.endpoint.refresh")) {
                console.log("Error - Oauth specific response intercetor")
                document.location = "/login"
                return Promise.reject(error);
            }
            return Promise.reject(error);
        });
    }

}

const ApiOauth = new _ApiOauth({
    endpoint: configReactApp('api.endpoint.oauth'),
    base_url: [SERVER_URL]
});


export {ApiOauth}


export default Api
