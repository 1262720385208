import * as Action from '../constants/ReducerActions/Store'
import InitialState from '../constants/InitialState/Store'
import _ from 'lodash'
import {filterStoreList, mergeStores, newStoreList, rawSetStores} from "../helper";

const StoreReducer = (state = InitialState, {type, payload}) => {
    switch (type) {
        case Action.SCREEN_LOAD:
            return {...InitialState, ...payload};
        case Action.LIST:
            return {...state, waiting: true};
        case Action.LIST_APPEND:
            let append = mergeStores(state, payload);
            return rawSetStores({
                ...state,
                waiting: false,
                list: [...state.list, ...payload.list],
                all: {...append}
            }, payload);
        case Action.LIST_DONE:
            const new_list = newStoreList(state, payload);
            const stateFilter = {...state.filter, cityId: payload.cityId, storeId: payload.storeId};
            return rawSetStores({
                ...state,
                waiting: false,
                filter: stateFilter,
                list: [...payload.list],
                all: {...new_list}
            }, payload);
        case Action.CLEAR_PARENT:
            return {...state, parent: null}
        case Action.SET_PARENT:
            return rawSetStores({...state, parent: payload}, {list: [payload]});
        case Action.LIST_ERROR:
            return {...state, waiting: false, errors: {...payload}};
        case Action.SET_FILTER:
            return {...state, filter: _.merge(state.filter, payload)};
        case Action.FILTER_LIST:
            const list = filterStoreList(state, payload);
            return {...state, list: [...list]};

        case Action.CLEAR_ERRORS:
            return {...state, errors: null};
        default:
            return state;
    }
};

export default StoreReducer;
