import * as ReducerActions from '../constants/ReducerActions/Login'
import ProfileAction from './Profile'
import BookingAction from './Booking'
import {ApiOauth} from "api/Api";
import AuthAction from "./Auth";
import ApiSocialLogin from "api/ApiSocialLogin";
import ApiPhoneLogin from "api/ApiPhoneLogin";
import LoginForm from "constants/Forms/Login";
import {validate} from "./FieldValidator";
import {isAuth} from "../isAuth";
import {LOGIN_START} from "../constants/ReducerActions/Auth";

const LoginAction = {
    FetchRequest: '',
    initAction: () => {
        return {type: LoginAction.FetchRequest}
    },

    onValidateError: (dispatch) => (errors) => dispatch({type: ReducerActions.SUBMIT_ERROR, payload: errors}),

    validate: (data, dispatch) => {
        return validate(data, LoginForm.fields, LoginAction.onValidateError(dispatch))
    },

    save: () => (dispatch, getState) =>
        new Promise(function (resolve, reject) {
                let {errors, waiting, ...data} = {...getState().LoginReducer};
                if (LoginAction.validate(data, dispatch)) {
                    dispatch({type: LOGIN_START});
                    ApiOauth
                        .login(data)
                        .then(response => {
                            if (response.status === 200) {
                                dispatch(LoginAction.success(response))
                                dispatch(AuthAction.login(response.data.access_token, response.data.refresh_token))
                                return dispatch(ProfileAction.loadProfileData())
                            } else {
                                resolve(response)
                            }
                        })
                        .then(response => {
                            if (response?.status === 200) {
                                if (getState().BookingReducer.pendingSave && isAuth()) {
                                    return dispatch(BookingAction.save());
                                } else if (getState().VirtualQueueReducer.pendingSave && isAuth()) {
                                    return dispatch(BookingAction.newVirtualQueue())
                                } else {
                                    resolve(response)
                                }
                            }
                        })
                        .then(response => {
                            if (response?.status === 200 || response?.status === 201) {
                                resolve(response?.data?.queue ? {...response, queueSaved: true} : {
                                    ...response,
                                    bookingSaved: true
                                })
                            } else {
                                resolve({...response, bookingError: true, storeId: getState().StoreTimeReducer.store_id})
                            }
                        })
                        .catch(error => {
                            if (error.response) {
                                dispatch(LoginAction.showErrors(error.response.data))
                                reject(error.response.data)
                            } else {
                                dispatch(LoginAction.showErrors(error))
                                reject(error)
                            }
                        })
                    ;
                    dispatch(LoginAction.initAction())
                } else {
                    reject()
                }
            }
        ),

    socialLogin: (data) => (dispatch, getState) =>
        new Promise(function (resolve, reject) {
                dispatch({type: LOGIN_START});
                ApiSocialLogin
                    .login(data)
                    .then(response => {
                        if (response.status === 200) {
                            dispatch(AuthAction.login(response.data.access_token, response.data.refresh_token))
                            return dispatch(ProfileAction.loadProfileData())
                        } else {
                            resolve(response)
                        }
                    })
                    .then(response => {
                        if (response?.status === 200) {
                            if (getState().BookingReducer.pendingSave && isAuth()) {
                                return dispatch(BookingAction.save());
                            } else if (getState().VirtualQueueReducer.pendingSave && isAuth()) {
                                return dispatch(BookingAction.newVirtualQueue())
                            } else {
                                resolve(response)
                            }
                        }
                    })
                    .then(response => {
                        if (response?.status === 200 || response?.status === 201) {
                            resolve(response?.data?.queue ? {...response, queueSaved: true} : {
                                ...response,
                                bookingSaved: true
                            })
                        } else {
                            resolve({...response, bookingError: true, storeId: getState().StoreTimeReducer.store_id})
                        }
                    })
                    .catch(error => {
                        if (error.response) {
                            reject(error.response.data)
                        } else {
                            reject(error)
                        }
                    })
                ;
            }
        ),

    phoneLogin: (data) => (dispatch, getState) =>
        new Promise(function (resolve, reject) {
                dispatch({type: LOGIN_START});
                ApiPhoneLogin
                    .login(data)
                    .then(response => {
                        if (response.status === 200) {
                            dispatch(AuthAction.login(response.data.access_token, response.data.refresh_token))
                            return dispatch(ProfileAction.loadProfileData())
                        } else {
                            resolve(response)
                        }
                    })
                    .then(response => {
                        if (response?.status === 200) {
                            if (getState().BookingReducer.pendingSave && isAuth()) {
                                return dispatch(BookingAction.save());
                            } else if (getState().VirtualQueueReducer.pendingSave && isAuth()) {
                                return dispatch(BookingAction.newVirtualQueue())
                            } else {
                                resolve(response)
                            }
                        }
                    })
                    .then(response => {
                        if (response?.status === 200 || response?.status === 201) {
                            resolve(response?.data?.queue ? {...response, queueSaved: true} : {
                                ...response,
                                bookingSaved: true
                            })
                        } else {
                            resolve({...response, bookingError: true, storeId: getState().StoreTimeReducer.store_id})
                        }
                    })
                    .catch(error => {
                        if (error.response) {
                            reject(error.response.data)
                        } else {
                            reject(error)
                        }
                    })
                ;
            }
        ),

    guestLogin: (data) => (dispatch, getState) =>
        new Promise(function (resolve, reject) {
                dispatch({type: LOGIN_START});
                ApiPhoneLogin
                    .login({accept_terms_and_conditions: true, ...data})
                    .then(response => {
                        dispatch(AuthAction.login(response.data.access_token, response.data.refresh_token))
                        dispatch(ProfileAction.loadProfileData())
                        resolve(response)
                    })
                    .catch(error => {
                        if (error.response) {
                            reject(error.response.data)
                        } else {
                            reject(error)
                        }
                    })
                ;
            }
        ),

    showErrors: (response) => {
        return {type: ReducerActions.SUBMIT_ERROR, payload: response}
    },

    success: (response) => {
        return {type: ReducerActions.SUBMIT_DONE, payload: response.data || null};
    },

    setUserData: () => {
        return (dispatch, getState) => {
            dispatch({
                type: ReducerActions.EDIT_FORM,
                payload: {name: "username", value: getState().RegisterReducer.email}
            })
            dispatch({
                type: ReducerActions.EDIT_FORM,
                payload: {name: "password", value: getState().RegisterReducer.password}
            })
        }
    }

};

export default LoginAction
