import React from 'react';
import FormInput from './FormInput'
import PropTypes from "prop-types";
import Inflector from 'Inflector'


class FormRadio extends FormInput {

    renderFormRadio() {
        let options;
        let args = {...this.props};
        delete args.options;
        const type = this.constructor.name.replace('Form','').toLowerCase()
        options = this.props.options.map(o => {
            const input = {
                ...args,
                type ,
                id: Inflector.classify(this.props.name) + Inflector.classify(o.key.toString()),
                value: o.key,
                label: o.value,
                checked: this.props.value === o.key || this.props.default === o.key,
                check:true
            };
            return input;
        });
        return options.map(o => <FormInput {...o}/>)
    }

    renderInput() {
        return this.renderFormRadio()
    }



}

FormRadio.propTypes = {
    ...FormInput.propTypes,
    options: PropTypes.array.isRequired,
};
FormRadio.defaultProps = {
    ...FormInput.defaultProps,
    options: []
};

export default FormRadio
