import Alert from "./Alert"

class ErrorAlert extends Alert {}

ErrorAlert.defaultProps = {
    ...Alert.defaultProps,
    color: "danger",
}

export default ErrorAlert
