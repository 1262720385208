const InitialState = {
    data: {
        store_time_id: null,
        user_id: null,
        invites: 0
    },
    pendingSave: false,
    waiting: false

};
export default InitialState
