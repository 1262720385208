import * as Action from '../constants/ReducerActions/Progress'
import InitialState from '../constants/InitialState/Progress'

const ProgressReducer = (state = InitialState, {type, payload}) => {
    switch (type) {
        case Action.SET_CURRENT:
            return { ...state, pair: { ...state.pair, current: payload }};
        case Action.SET_MAX:
            return { ...state, pair: { ...state.pair, max: payload }};
        case Action.SET_VALUE:
            return { ...state, percentage: { ...state.percentage, value: payload }};
        case Action.RESET:
            return InitialState;
        default:
            return state;
    }
};

export default ProgressReducer;
