import React from 'react';
import FormInput from "./FormInput";
import PropTypes from "prop-types";
import {Input} from "reactstrap";
import Inflector from "Inflector";

class FormSelect extends FormInput {


    renderSelect() {
        let options = [];
        if (this.props.placeholder) {
            options.push({key: null, value: this.props.placeholder});
        }
        // process.env.NODE_ENV === "production" || console.log(this.props);

        if (this.props.items && this.props.options && this.props.items.length && this.props.options.length) {
            console.error("the field "+this.props.name+" has both options and items attributes. Must be one of.")
        }
        if (this.props.options && this.props.options.length ) {
            options.push(...this.props.options);
        } else if (this.props.items && this.props.items.length) {
            options.push(...this.props.items);
        }

        return options.map(o => {
            const t = typeof o;
            switch (t) {
                case 'string':
                    return {'key': o, 'value': o}
                case 'object':
                    const v = o.value || o[0];
                    const k = o.hasOwnProperty('key') ? o.key : v;
                    return {key: k, value: v}
                default:
                    console.warn(o);
            }
            return [];

        }).map(o => {
            const id = o.value === this.props.placeholder ? 'Placeholder' : Inflector.classify(o.key.toString());
            const input = {
                id: Inflector.classify(this.props.name) + id,
                value: o.value === this.props.placeholder ? "" : o.key,
                key: o.key,
                disabled: o.value === this.props.placeholder
            };

            return <option {...input} selected={this.props.selectedValue === o.value} >{o.value}</option>;
        });
    }

    renderInput() {
        const p = this.prepareProperties({
            id: this.getIdentification(),
            name: this.props.name,
            className: this.props.className,
            type: 'select'

        });
        delete p.value

        // console.log(p)
        const options = this.renderSelect();

        return <Input {...p} disabled={this.props.disabled} onChange={this.handleEvents}>{options}</Input>

    }

}

FormSelect.propTypes = {
    ...FormInput.propTypes,
    options: PropTypes.array.isRequired,

};
FormSelect.defaultProps = {
    ...FormInput.defaultProps,
    options: []
};

export default FormSelect
