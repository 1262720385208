import React from "react";
import DefaultButton from "./DefaultButton";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faLessThan} from '@fortawesome/free-solid-svg-icons'

export {default as SocialLoginButtons} from './SocialLoginButtons'

export const GoSignUpButton = (props) => <DefaultButton id="GoSignUpButton" {...{...props, color: "success"}} href="/register">Crear cuenta
    nueva</DefaultButton>
export const GoLoginButton = (props) => <DefaultButton id="GoLoginButton" {...{...props, color: "primary"}} href="/login">Iniciar
    Sesión</DefaultButton>
export const GoBackButton = (props) => <DefaultButton id="GoBackButton" {...{...props, color: "default", className: 'btn-go-back '}} href="back">
    <FontAwesomeIcon
        icon={faLessThan}/> Volver</DefaultButton>
export const GoForgotPasswordButton = (props) => <DefaultButton id="GoForgotPasswordButton" {...{
    color: "link",
    style: {fontSize: '0.8rem'},
    className: "text-blue text-center", ...props
}} href="/forgotpassword">Olvidé mi
    contraseña</DefaultButton>
export const DefaultCustomButton = DefaultButton;

export const GoGuestSignUpButton = (props) => <DefaultButton id="GoGuestSignUpButton" {...{outline: false, color: "info", ...props}}
                                                             href={`/guest/register${props.search}`}>Continuar como
    invitado</DefaultButton>

