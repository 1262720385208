import React, {Component} from 'react';
import {Button, Container, Row, Col} from 'reactstrap';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faCheckCircle, faArrowRight} from '@fortawesome/free-solid-svg-icons'
import PropTypes from "prop-types";

class Feedback extends Component {

    render() {
        const button = this.props.btnDesc ??
            <>Continuar<FontAwesomeIcon icon={faArrowRight} className="ml-2"/></>;
        return (
            <Container fluid className="bg-white pb-4 feedback">
                <Row>
                    <Col className="d-inline-flex flex-column align-items-center h2 text-center mb-2 mt-4">
                        {this.props.title}
                    </Col>
                </Row>
                {this.props.subtitle ? <Row>
                    <Col className="d-inline-flex flex-column align-items-center h5 text-center mb-4 mt-4">
                        {this.props.subtitle}
                    </Col>
                </Row> : ''}
                {this.props.description ? <Row>
                    <Col className="d-inline-flex flex-column align-items-center h5 text-center mb-4 mt-4">
                        {this.props.description}
                    </Col>
                </Row> : ''}
                {this.props.children ? <Row>
                    <Col className="d-inline-flex flex-column align-items-center text-justify mb-4 mt-4">
                        {this.props.children}
                    </Col>
                </Row> : ''}
                {this.props.icon ? <Row>
                    <Col className="d-inline-flex flex-column align-items-center h5 text-center mb-4 mt-4">
                        <FontAwesomeIcon size={this.props.icon_size} icon={this.props.icon}
                                         color={this.props.icon_color}/>
                    </Col>
                </Row> : ''}
                <Row>
                    <Col xs={{offset: 1, size: 10}}
                         className="d-inline-flex flex-column align-items-center h5 text-center mb-4 mt-4">
                        {this.props.customKeyPad ? this.props.customKeyPad :  this.props.btnLink ?
                            <Button block color="primary" href={this.props.btnLink}>{button}</Button> : ''}
                    </Col>
                </Row>
                <Row className="my-3"><Col>&nbsp;</Col></Row>
            </Container>
        );
    }

}

Feedback.propTypes = {
    title: PropTypes.string.isRequired,
    subtitle: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    description: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    icon_size: PropTypes.string.isRequired,
    icon_color: PropTypes.string.isRequired,
    icon: PropTypes.oneOfType([PropTypes.bool, PropTypes.object, PropTypes.string]),
    btnDesc: PropTypes.string,
    btnLink: PropTypes.string,
    customKeyPad: PropTypes.element,
}

Feedback.defaultProps = {
    subtitle: false,
    description: false,
    icon_size: "6x",
    icon_color: "green",
    icon: faCheckCircle,
}

export default Feedback;
