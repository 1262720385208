import * as Action from '../constants/ReducerActions/Profile'

const ProfileReducer = (state = {}, {type, payload}) => {
    switch (type) {
        case Action.LOAD_PROFILE_START :
            return {...state, waiting: true};
        case Action.LOAD_PROFILE_DATA:
            return {waiting: false, ...payload};
        case Action.CLEAN_PROFILE_DATA:
            return {};
        default:
            return state;
    }
};

export default ProfileReducer;
