import config from 'configReactApp'
import _ from "lodash"
import {isAuth} from "./isAuth";

// Add constants here
export const DNI = 3
export const RUT = 2
export const PASSPORT = 4
export const DNI_DISPLAY_NAME = "DNI"
export const ARGENTINA = 2;
export const CHILE = 1;
export const PERU = 3;

// Returns true if booking is not checked in and booking end time is less than current time
export const isBookingEnabled = (booking) => {
    const now = new Date()
    const bookingEnd = new Date(booking.store_time?.end_time)
    return !booking.checkin_at && now < bookingEnd;
}

// Returns true if booking is not checked in and current time is between entry margin time and end time
export const isBookingEntryTimeFrame = (booking) => {
    const now = new Date()
    const bookingEnd = new Date(booking.store_time?.end_time)
    const startBooking = new Date(booking.store_time?.start_booking_at)
    return !booking.checkin_at && now > startBooking && now < bookingEnd;
}

// Returns true if booking has already been checked in
export const isBookingCheckedIn = (booking) => {
    return booking.checkin_at;
}

// Returns true if current time is greater than end time
export const isBookingExpired = (booking) => {
    const now = new Date()
    const bookingEnd = new Date(booking.store_time?.end_time)
    return now > bookingEnd;
}

export const canDeleteBooking = (booking) => {
    return _.get(booking, 'calendar.calendar_type.can_delete', false);
}

export const canUpdateBooking = (booking) => {
    return _.get(booking, 'calendar.calendar_type.can_update', false);
}

export const canInviteBooking = (booking) => {
    return _.get(booking, 'calendar.calendar_type.can_invite', false);
}

export const isNotAppIntern = () => {
    return config('app.intern') === 'false'
}

export const isAppIntern = () => {
    return config('app.intern') === 'true'
}

export const showAvailability = (store) => {
    return store.show_available;
}

export const hasChildren = (store) => {
    return _.get(store, 'has_children', false);
}

export const isInMall = (store) => {
    return _.get(store, 'parent', false);
}

export const hasVirtualQueue = (store) => {
    return _.get(store, 'has_virtual_queue', false);
}

export const isQueueEnabled = (queue) => {
    return !queue.checkin_at && queue.iid >= (queue.queue.actual || 0);
}

export const hasBookingsAvailable = store => {
    return _.get(store, 'has_bookings_available', false);
}

export const storePath = (payload) => {
    return ['city_id_' + payload.cityId, 'store_id_' + payload.storeId];
}

export const allStores = (state) => {
    return _.get(state, 'all', {});
}

export const getStores = (state, payload) => {
    let new_list = allStores(state);
    return _.get(new_list, storePath(payload), []);
}

export const mergeStores = (state, payload) => {
    let new_list = allStores(state);
    let current_list = getStores(state, payload);
    _.set(new_list, storePath(payload), [...current_list, ...payload.list]);
    return new_list;
}

export const rawSetStores = (state, payload) => {
    const new_list = {...state};
    payload.list.forEach(store => {
        _.set(new_list, 'raw.store_id_' + store.id, store);
    });
    return new_list;
}

export const rawSearchStores = (state, payload) => {
    const new_list = {...state};


    return new_list;
}


export const newStoreList = (state, payload) => {
    let new_list = allStores(state);
    // console.log(new_list)
    // console.log(payload)
    _.set(new_list, storePath(payload), [...payload.list]);
    // console.log(new_list)
    return new_list;
}

export const filterStoreList = (state, payload) => {
    const filter = state.filter.text.toLowerCase().trim();
    let list = getStores(state, _.merge(state.filter, payload));
    if (filter !== '') {
        list = list.filter(s => {
            return s.name.toLowerCase().includes(filter) || s.street.toLowerCase().includes(filter);
        });
    }
    return list;
}

export const filterMenu = (menu, isVirtualQueue) =>
    menu.filter(menu_item => menu_item.visible())
        .filter(menu_item => (isVirtualQueue && menu_item.show_on_virtual_queue) ||
            (!isVirtualQueue && menu_item.show_on_booking));


export const redirectToUrl = (response) => {
    let redirectTo, params;

    if (response.bookingSaved) {
        redirectTo = config('app.booking.after_create').replace(':booking', response?.data.id);
    } else if (response.bookingError) {
        redirectTo = config('app.booking.new').replace(':store_id', response.storeId);
        params = {preserveErrors: true};
    } else if (response.queueSaved) {
        redirectTo = config('app.queue') + '/' + response.data?.id;
    } else if (isAuth()) {
        redirectTo = config('app.after_login');
    } else {
        redirectTo = config('app.after_login');
    }
    // console.log(response);
    // console.log(redirectTo, params);
    // console.trace()
    return {redirectTo,params};
}

export const parseLocationToURI=(location) =>{
    let l=[];
    l.push(location.pathname);
    if (location.bookings) { l.push(location.bookings)}
    if (location.hash) { l.push(location.hash)}
    return l.join('')
}
