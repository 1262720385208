import Api from './Api'
import config from 'configReactApp'

class _ApiStore extends Api {

    size_bookings(data, config) {
        return this.post('size_bookings', data, config);
    }

    times(store_id, calendar_id, config) {
        return this.get([store_id, 'times'], {calendar_id}, config);
    }

    check_in(storeId, bookingId) {
        return this.get(config('api.endpoint.check_in').replace(':storeId', storeId).replace(':bookingId', bookingId))
    }

    calendar(storeId) {
        return this.get(config('api.endpoint.calendars').replace(':storeId', storeId));
    }

    children(store_id, page = 1) {
        return this.get("", { parent_id: store_id, page });
    }

    queues(store_id) {
        return this.get([store_id, 'queues'],config);
    }
}

const ApiStore = new _ApiStore({endpoint: config('api.endpoint.store')});

export default ApiStore
