import React from 'react';
import FormInput from "./FormInput";
import PropTypes from "prop-types";
import { InputGroup, Input, InputGroupButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem, FormFeedback } from "reactstrap";
import Inflector from "Inflector";

class FormInputGroupButtonDropdown extends FormInput {

    state = {
        isOpen: false,
        selected: this.props.selectedValue
    }

    static getDerivedStateFromProps(props, state) {
        if (props.selectedValue !== state.selectedValue) {
            return { isOpen: state.isOpen, selected: props.selectedValue }
        }
        return state
    }

    renderSelect(handleEvents) {
        let options = [];

        if (this.props.items && this.props.options && this.props.items.length && this.props.options.length) {
            console.error("the field " + this.props.name + " has both options and items attributes. Must be one of.")
        }
        if (this.props.options && this.props.options.length) {
            options.push(...this.props.options);
        } else if (this.props.items && this.props.items.length) {
            options.push(...this.props.items);
        }

        return options.map(o => {
            const t = typeof o;
            switch (t) {
                case 'string':
                    return { 'key': o, 'value': o }
                case 'object':
                    const v = o.value || o[0];
                    const k = o.hasOwnProperty('key') ? o.key : v;
                    return { key: k, value: v }
                default:
                    console.warn(o);
            }
            return [];

        }).map(o => {
            const id = o.value === this.props.placeholder ? 'Placeholder' : Inflector.classify(o.key.toString());
            const input = {
                id: Inflector.classify(this.props.name) + id,
                value: o.key,
                key: o.key

            };

            return <DropdownItem {...input} selected={this.props.selectedValue === o.value} onClick={(event) => {
                event.target.name = Object.keys(this.props.dropdown)[0];
                event.type = "change";
                handleEvents(event);
                this.setState({ selected: event.target.innerText })
            }}>
                {o.value}
            </DropdownItem>;
        });
    }

    renderInput() {
        const p = this.prepareProperties({
            id: this.getIdentification(),
            name: this.props.name,
            className: this.props.className,
            valid: (!this.props.errors.length > 0) && this.props.value !== '',
            invalid: (this.props.errors.length > 0),
            value: this.props.value,
        });

        // console.log(p)
        const renderErrors = this.renderErrors();
        const options = this.renderSelect(this.handleEvents);

        return <InputGroup>
            <InputGroupButtonDropdown addonType="prepend" isOpen={this.state.isOpen} toggle={() => this.setState({ isOpen: !this.state.isOpen })}>
                <DropdownToggle color="primary" caret onChange={(value) => this.setState({ selected: value })}>
                    {this.state.selected}
                </DropdownToggle>
                <DropdownMenu onChange={(value) => this.setState({ selected: value })}>
                    {options}
                </DropdownMenu>
            </InputGroupButtonDropdown>
            <Input {...p} onChange={this.handleEvents} placeholder={this.props.placeholder} />
            {renderErrors}
        </InputGroup>
    }

    renderErrors() {
        if (this.props.errors) {
            const message = this.props.errors.map(e => <div>{e}<br /></div>);
            return <FormFeedback>{message}</FormFeedback>;
        }
    }    
}

FormInputGroupButtonDropdown.propTypes = {
    ...FormInput.propTypes,
    options: PropTypes.array.isRequired,

};
FormInputGroupButtonDropdown.defaultProps = {
    ...FormInput.defaultProps,
    options: []
};

export default FormInputGroupButtonDropdown
