import React, {Component} from 'react';
import FormTag from "components/Forms/FormTag";
import {connect} from 'react-redux'
import {Col, Container, CustomInput, FormGroup, Input, Label, Row} from 'reactstrap';
import {EDIT_FORM, SUBMIT} from 'constants/ReducerActions/Booking'
import BookingAction from "../../actions/Booking";
import StoreTimeAction from "../../actions/StoreTime";
import BookingForm from 'constants/Forms/Booking'
import {InputEvts, toOptions} from "../../components/Forms/Utils";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheck} from "@fortawesome/free-solid-svg-icons";
import {withRouter} from 'react-router-dom'
import {SET_STORE} from "../../constants/ReducerActions/StoreTime";
import config from "../../configReactApp";
import Waiting from "../../components/Waiting/Waiting";
import {isAuth} from "../../isAuth";
import InfoAlert from "../../components/Alert/InfoAlert";
import StoreAction from '../../actions/Store';
import StoreItem from '../../components/Lists/StoreItem';
import Inflector from "Inflector";
import _ from "lodash";

const person_count = {
    'label': '¿Con cuántas personas?',
    'value': 'persons_count',
    'type': 'dropdown',
    'items': ['1', '2', '3'],
    'required': true,
    e: new InputEvts()
}

class BookingNewView extends Component {

    state = {
        accompanied: false
    }

    componentDidMount() {
        const {store_id} = this.props.match.params
        this.props.onInit(store_id, this.props.location.state?.preserveErrors);
    }

    componentWillUnmount() {
        this.props.onUnload();
    }

    isNotEmptyBookingList = () => {
        return this.props.data && Object.values(this.props.dateList).length > 0 && Object.values(this.props.allStoresTimes).length > 0
    }

    isDateFullyBooked = (date) => {
        return Object.values(this.props.allStoresTimes[date]).every(val => val.fully_booked);
    }

    isNotFullyBooked = () => {
        return !Object.values(this.props.dateList).every(val => this.isDateFullyBooked(val.id))
    }

    handleSubmitNewBooking = (e) => {
        if (isAuth()) {
            this.props.onSubmit(e).then(response => {
                if (response.status === 201 || response.status === 200) {
                    this.props.history.push(config('app.booking.after_create').replace(':booking', response.data.id))
                }
            });
        } else {
            this.props.setPending();
            this.props.history.push(`${config('app.guest_home')}?store_id=${this.props.store?.id}`);
        }
    }

    prepare() {
        this.args = {
            ...BookingForm,
            onChange: this.props.onChange,
            onSubmit: this.handleSubmitNewBooking,

            inline: false,
        };
        const date_on_events = new InputEvts();
        date_on_events.add('change', this.props.onChange);
        date_on_events.add('change', this.props.onDateChange);
        this.args.fields.date.items = toOptions(this.props.dateList);
        this.args.fields.date.e = date_on_events;
        this.args.fields.store_time_id.items = toOptions(this.props.storeTimeList);
        // this.args.buttons.submit.label = <Row><Col>Confirmar</Col><Col><FontAwesomeIcon icon={faCheck}/></Col></Row>;
        this.args.buttons.submit.label = <>Confirmar <FontAwesomeIcon icon={faCheck}/></>;

        return this.args
    }

    render() {

        const args = this.prepare();
        let content;
        if (this.isNotEmptyBookingList() && this.isNotFullyBooked()) {
            content = <FormTag name='NewBooking' {...args}
                               data={this.props.data} errors={this.props.errors} footer={this.props.data.can_invite ?
                <>
                    <FormGroup>
                        <Label>¿Vienes acompañado?</Label>
                        <div>
                            <CustomInput type="radio" id="accompained_yes" label="Si" name="accompained"
                                         onClick={() => {
                                             this.props.onPersonsChange("1")
                                             this.setState({accompanied: true})
                                         }}/>
                            <CustomInput checked={!this.state.accompanied} type="radio" id="accompained_no" label="No"
                                         name="accompained" onClick={() => {
                                this.props.onPersonsChange("0")
                                this.setState({accompanied: false})
                            }}/>
                        </div>

                    </FormGroup>
                    {this.state.accompanied && <>
                        <fieldset>{person_count.label}</fieldset>
                        <Input onChange={(e) => this.props.onPersonsChange(e.currentTarget.value)}
                               type="select">{toOptions(person_count.items).map(o => {
                            const id = o.value === this.props.placeholder ? 'Placeholder' : Inflector.classify(o.key.toString());
                            const input = {
                                id: Inflector.classify("persons") + id,
                                value: o.key,
                                key: o.key

                            };

                            return <option {...input}>{o.value}</option>
                        })}</Input></>}
                </> : null
            }/>
        } else {
            content = <InfoAlert text="No hay reservas disponibles"/>
        }

        return <Waiting waiting={this.props.waiting}>
            <h2 className="d-none d-sm-block">Seleccione Día y Hora</h2>
            {this.props.store && <Container fluid>
                <Row className="d-none d-sm-block">
                    <Col className="pb-3 mb-4 pl-2 border-bottom"><StoreItem key={this.props.store.id}
                                                                             item={this.props.store}/></Col>
                </Row>
                <Row><Col>
                    {content}
                </Col></Row>
            </Container>}
        </Waiting>
    }
}

const mapStateToProps = (state, ownProps) => {
    const got_parent = _.get(state, 'StoreReducer.parent.id', false);
    return {
        errors: state.BookingReducer.errors || null,
        data: state.BookingReducer.data || null,
        waiting: state.StoreTimeReducer.waiting || state.BookingReducer.waiting || !got_parent,
        dateList: state.StoreTimeReducer.dates || [],
        storeTimeList: state.StoreTimeReducer.list || [],
        allStoresTimes: state.StoreTimeReducer.all || null,
        store: state.StoreReducer.parent

    }
};

const mapDispatchToProps = (dispatch, getState) => {
    return {
        onInit: (store_id, preserveErrors) => {
            dispatch({
                type: SET_STORE,
                payload: store_id
            });
            dispatch(StoreAction.get(store_id));
            dispatch(StoreAction.calendar(store_id)).then((response) => {
                //console.log(response);
                let data = _.get(response, 'data', []);
                console.log(data)
                if (data.length > 0) {
                    let user_calendar = data.filter(e => e.users).shift();
                    if (user_calendar) {
                        dispatch({
                            type: EDIT_FORM,
                            payload: {
                                name: "calendar_id",
                                value: user_calendar.id,
                            }
                        });
                        dispatch(StoreTimeAction.all(preserveErrors, user_calendar.id));
                        let can_invite = _.get(user_calendar, '.calendar_type.can_invite', false)
                        dispatch({
                            type: EDIT_FORM,
                            payload: {
                                name: "can_invite",
                                value: can_invite
                            }
                        });
                    }

                    // To retrieve the store information if the stores have not been retrieved yet
                }

            });

        },
        onChange: e => dispatch({
            type: EDIT_FORM,
            payload: {
                name: e.target.name,
                value: e.target.value,
                type: e.target.type,
                files: e.target.files
            }
        }),
        onPersonsChange: (persons) => dispatch({
            type: EDIT_FORM,
            payload: {
                name: "invites",
                value: Number(persons)
            }
        }),
        onSubmit: e => {
            e.preventDefault();
            BookingAction.FetchRequest = SUBMIT;
            return dispatch(BookingAction.save(dispatch, getState, e));
        },
        setPending: () => {
            dispatch(BookingAction.setPendingSaveOnLogin())
        },
        onDateChange: e => {
            e.preventDefault();
            dispatch(StoreTimeAction.selectDate(e.target.value));
        },
        onUnload: () => {
            dispatch(StoreAction.clearParent());
        }
    }
};


export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(BookingNewView));

