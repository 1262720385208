const InitialState = {
    load: false,
    title: undefined,
    forceFooter: false,
    forceHeader: false,
    sideLogo: false,
    headerLogo: false

};
export default InitialState
