import React from "react";
import Feedback from "../../components/Feedback/Feedback";

export default () => <Feedback title="Términos y Condiciones" icon={false}>

<h5>Reglamento de uso de la aplicación</h5>
<p>El presente documento establece las condiciones mediante las cuales se regirá el uso de la Aplicación Turnera (en adelante Aplicación). La Aplicación funcionará como un canal opcional para la realización de ciertas actividades descritas más adelante con el objeto de facilitar el acceso a los USUARIOS. El USUARIO se compromete a leer los términos y condiciones aquí establecidas, previamente al uso de la Aplicación, por tanto, en caso hacer uso se entiende que cuenta con el conocimiento integral de este documento y la consecuente aceptación de la totalidad de sus estipulaciones. El USUARIO reconoce que el ingreso de su información personal, y los datos que contiene la Aplicación a su disposición respecto a los servicios ofrecidos por Cencosud Retail Perú S.A. la realizan de manera voluntaria. Quienes optan por acceder a esta Aplicación en Perú, lo hacen por iniciativa propia y son responsables del cumplimiento de las leyes locales y manifiestan que son mayores de edad.</p>

<h5>Alcance y uso</h5>
<p>En la Aplicación se pondrá a disposición del USUARIO información y/o permitirá la realización de determinadas transacciones habilitadas por Cencosud Retail Perú S.A. Para cada solución en particular Cencosud Retail Perú S.A. podrá adicionar, modificar o eliminar las funcionalidades en cualquier momento, lo cual acepta el USUARIO mediante el uso de la Aplicación. El USUARIO acepta y autoriza que los registros electrónicos de las actividades mencionadas que realice en la Aplicación constituyen plena prueba de los mismos.</p>

<h5>Solicitud de modificación de turnos en supermercados de CENCOSUD a través de la aplicación</h5>
<p>Si el Usuario requiere modificar el turno para el recojo de cenas navideñas para cualquiera de los supermercados de propiedad de Cencosud, solo podrá hacerlo en cuanto al horario de recojo dentro de la fecha programada previamente, las mismas que deben de estar enmarcadas en las fechas del 23, 24, 30 y 31 de diciembre. Para realizar esta actividad el USUARIO deberá de ingresar a la aplicación mediante el correo electrónico y teléfono entregado en la zona de atención de clientes, donde podrá activar su cuenta y reprogramar solo la hora de recojo de acuerdo a disponibilidad.</p>

<h5>Requisitos para su uso</h5>
<p>El USUARIO deberá contar con un dispositivo informático con acceso a internet, seguro y confiable. Cencosud Retail Perú S.A. , no será responsable por la seguridad de los equipos utilizados por el USUARIO para el acceso a la Aplicación, ni por la disponibilidad del servicio en los dispositivos en los cuales utilice la Aplicación. Cencosud Retail Perú S.A. no asume responsabilidad por pérdida directa o indirecta o consecuencial del uso de la Aplicación por tanto, será de responsabilidad única del USUARIO el uso de la misma y los datos incluidos en ella. En la forma permitida por la ley, los materiales de la Aplicación se suministran sin garantía de ningún género, expresa o implícita, incluyendo sin limitación las garantías de calidad satisfactoria, comerciabilidad, adecuación para un fin particular o no infracción, por tanto, Cencosud Retail Perú S.A., no garantiza el funcionamiento adecuado en los distintos sistemas operativos o dispositivos en los cuales se haga uso del Aplicación. Cencosud Retail Perú S.A., no acepta responsabilidad por virus que infecten al USUARIO luego del uso del audio, video, datos o textos del sitio.</p>

<h5>Ley y jurisdicción aplicable</h5>
<p>Las Condiciones Generales que regulan el uso de la Aplicación se rigen por las Leyes de la República de Perú. Cualquier disputa o conflicto que se genere entre el USUARIO y Cencosud Retail Perú S.A. por el ingreso y/o uso del Aplicación y de los servicios que allí se prestan, se llevará ante los jueces de la República de PERU y será resuelto de acuerdo con las Leyes PERUANAS sin tener efecto el conflicto con otras leyes de otros países o su estado de residencia actual. El USUARIO que ingrese a la Aplicación desde lugares que se encuentren fuera de la jurisdicción de Perú, lo hace por su propia cuenta y riesgo y es total y personalmente responsable por el cumplimiento de las leyes que rigen el lugar desde donde ingresa y/o utiliza los servicios de las Páginas.</p>

<h5>Obligaciones del usuario</h5>
<p>Además de las obligaciones señaladas en las anteriores Condiciones Generales, el USUARIO se compromete a utilizar la Aplicación de conformidad con la Ley, las Condiciones Generales, así como con la moral, las buenas costumbres generalmente aceptadas y el orden público. Asimismo, el USUARIO se obliga a usar la Aplicación y los contenidos encontrados en ella de una manera diligente, correcta, lícita y en especial, se compromete a NO realizar las conductas descritas a continuación:</p>
<ol type="a">
<li>Reproducir, copiar, representar, utilizar, distribuir, transformar o modificar los contenidos de la Aplicación, por cualquier procedimiento o sobre cualquier soporte, total o parcial, o permitir el acceso del público a través de cualquier modalidad de comunicación pública;</li>
<li>Utilizar los contenidos de cualquier manera que entrañen un riesgo de daño o inutilización de la Aplicación o de los contenidos;</li>
<li>Suprimir, eludir o manipular el derecho de autor y demás datos identificativos de los derechos de autor incorporados a los contenidos, así como los dispositivos técnicos de protección, o cualesquiera mecanismos de información que pudieren tener los contenido;</li>
<li>Emplear los contenidos y, en particular, la información de cualquier clase obtenida a través de la Aplicación para distribuir, transmitir, remitir, modificar, rehusar o reportar la publicidad o los contenidos de esta con fines de venta directa o con cualquier otra clase de finalidad comercial, mensajes no solicitados dirigidos a una pluralidad de personas con independencia de su finalidad, así como comercializar o divulgar de cualquier modo dicha información;</li>
<li>No permitir que terceros ajenos a usted usen la Aplicación con su clave;</li>
<li>Utilizar la Aplicación y los contenidos con fines lícitos y/o ilícitos, contrarios a lo establecido en estos Términos y Condiciones, o al uso mismo de la Aplicación, que sean lesivos de los derechos e intereses de terceros, o que de cualquier forma puedan dañar, inutilizar, sobrecargar o deteriorar la Aplicación y los contenidos o impedir la normal utilización o disfrute de esta y de los contenidos por parte de los USUARIOS.</li>
</ol>

<p>Además de las responsabilidades que asume el USUARIO conforme a las Condiciones Generales y particulares, el USUARIO responderá de los daños y perjuicios de toda naturaleza que Cencosud Retail Perú S.A. pueda sufrir, directa o indirectamente, como consecuencia del incumplimiento de cualquiera de las obligaciones derivadas de las Condiciones Generales y particulares o de la ley en relación con la utilización del Aplicación.</p>

<h5>Propiedad intelectual</h5>
<p>Todo el material informático, gráfico, publicitario, fotográfico, de multimedia, audiovisual y de diseño, así como todos los contenidos, textos y bases de datos puestos a su disposición en esta Aplicación están protegidos por derechos de autor y/o propiedad industrial cuyo titular es Cencosud Retail Perú S.A. Todos los contenidos en la Aplicación están protegidos por las normas sobre derecho de autor y por todas las normas nacionales e internacionales que le sean aplicables. Exceptuando lo expresamente estipulado en estos Términos y Condiciones, queda prohibido todo acto de copia, reproducción, modificación, creación de trabajos derivados, venta o distribución, exhibición de los contenidos de esta Aplicación, de manera o por medio alguno, incluyendo, más no limitado a, medios electrónicos, mecánicos, de fotocopiado, de grabación o de cualquier otra índole, sin el permiso previo y por escrito de Cencosud Retail Perú S.A. o del titular de los respectivos derechos de ser el caso. En ningún caso estos Términos y Condiciones confieren derechos, licencias ni autorizaciones para realizar los actos anteriormente prohibidos. Cualquier uso no autorizado de los contenidos constituirá una violación del presente documento y a las normas vigentes sobre derechos de autor, a las normas vigentes nacionales e internacionales sobre Propiedad Industrial, y a cualquier otra que sea aplicable.</p>

<h5>Localización desde equipos móviles</h5>
<p>Esta Aplicación usa su localización actual para mejorar la experiencia en el mapa a la hora de encontrar un punto de servicio. El USUARIO siempre podrá desactivar y activar el servicio de geolocalización en la opción de configuración del menú. El único objetivo de permitir compartir la ubicación es el de brindar información precisa de los puntos de servicio más cercanos. En ningún momento la ubicación será rastreada o almacenada por Urbano Express Perú SA ni será utilizada con fines distintos al de la prestación del servicio ofrecido en la Aplicación.</p>

<h5>Notificaciones</h5>
<p>Cencosud Retail Perú S.A. podrá realizar las notificaciones oportunas a través de una notificación general en la Aplicación, a través de la dirección de correo electrónico facilitada por el Usuario al momento de generar la reserva en la zona de atención al clientes del local o mediante correo ordinario a la dirección facilitada por el Usuario al momento de generar la reserva en la zona de atención al clientes del local.</p>

<h5>Responsabilidad</h5>
<p>Cencosud Retail Perú S.A. procurará garantizar disponibilidad, continuidad o buen funcionamiento de la Aplicación. Podrá bloquear, interrumpir o restringir el acceso a esta cuando lo considere necesario para el mejoramiento de la Aplicación o por dada de baja de la misma. Se recomienda al USUARIO tomar medidas adecuadas y actuar diligentemente al momento de acceder a la Aplicación, como por ejemplo, contar con programas de protección, antivirus, para manejo de malware, spyware y herramientas similares. Cencosud Retail Perú S.A. no será responsable por: a) La falta de diligencia y/o uso irresponsable que haga el USUARIO de la Aplicación; b) Fuerza mayor o caso fortuito; c) Por la pérdida, extravío o hurto de su dispositivo móvil que implique el acceso de terceros a la Aplicación d) Por errores en la digitación o accesos por parte del USUARIO; e) Por los perjuicios, lucro cesante, daño emergente, morales, y en general sumas a cargo de Cencosud Retail Perú S.A., por los retrasos, no procesamiento de información o suspensión del servicio del operador móvil o daños en los dispositivos móviles.</p>

<h5>Denegación y retirada del acceso a la aplicación</h5>
<p>En el Evento en que un USUARIO incumpla estos Términos y Condiciones, o cualesquiera otras disposiciones que resulten del Aplicación, Cencosud Retail Perú S.A. podrá suspender su acceso.</p>

<h5>Aceptación de términos y condiciones</h5>
<p>El USUARIO acepta expresamente los Términos y Condiciones, siendo condición esencial para la utilización del Aplicación. En el evento en que se encuentre en desacuerdo con estos Términos y Condiciones, solicitamos abandonar el Aplicación de forma inmediata. Cencosud Retail Perú S.A. podrá modificar los presentes términos y condiciones, sin ningún aviso previo a los USUARIOS de la Aplicación, lo cual se entenderá aceptado por el USUARIO si éste continúa con el uso de la Aplicación.</p>

<h5>Uso de información no personal</h5>
<p>Cencosud Retail Perú S.A. también recolecta información no personal en forma agregada para seguimiento de datos con o el número total de descargas del Aplicación. Utilizamos esta información, que permanece en forma agregada, para entender el comportamiento del Aplicación y su mejoramiento continuo a través de las actualizaciones.</p>

<h5>Aviso de privacidad y política de protección de datos personales</h5>
<p>Cencosud Retail Perú S.A. identificada con RUC 20109072177, y sede principal en la Cal. Augusto Angulo Nro. 130 - Miraflores - Lima, Perú, conforme a las disposiciones contenidas en la Ley 29733 protección de datos personales, considerada como responsable y/o encargada del tratamiento de datos personales, en cuanto a su recolección, almacenamiento y administración; que se reciban de los usuarios del aplicativo móvil.</p>
<p>Cencosud Retail Perú S.A. respeta la privacidad de cada uno de los usuarios y en general, de toda persona natural titular de datos personales, que suministren su información. Cencosud Retail Perú S.A. recibe información, la almacena de forma adecuada y segura. No obstante, los usuarios pueden consultar la exactitud de esta información y tomar las medidas para su actualización y/o solicitar su eliminación. La información se recolecta, procesa y usa de conformidad con las regulaciones actuales de protección de información y privacidad de la misma.</p>
<p>Cencosud Retail Perú S.A. recopila información personal relacionada de los usuarios con fines de consulta, procesamiento y uso, únicamente si éstos deciden voluntariamente registrar o suministrar la información y dan su autorización al respecto, reconociendo que el ingreso de información personal lo realiza de manera voluntaria, teniendo en cuenta las características habilitadas para tal propósito y las facultades de uso por parte de Cencosud Retail Perú S.A., en los términos aquí establecidos y en el entendido que tal información hará parte de un archivo y/o base de datos. Los datos se recaudan especialmente para la efectiva prestación de los servicios en los que se enmarca el objeto social de Cencosud Retail Perú S.A. que involucra la identificación de los clientes, el cumplimiento de disposiciones legales, compromisos contractuales y de otra índole, el suministro de información sobre las soluciones dispuestas y para el desarrollo de programas de fidelización.</p>
<p>La información suministrada no la utilizaremos, procesaremos o transferiremos más allá de los límites establecidos por la ley y lo consagrado en la declaración de consentimiento del cliente. Excepcionalmente, transferiremos la información suministrada, únicamente a terceros autorizados por el titular y por requerimiento de autoridad competente.</p>
<p>Cencosud Retail Perú S.A. no se responsabiliza por cualquier consecuencia derivada del ingreso indebido de terceros a la base de datos y/o por alguna falla técnica en el funcionamiento y/o conservación de datos en el sistema de almacenamiento de la información.</p>
<p>Cencosud Retail Perú S.A., se reserva el derecho a modificar esta Política en cualquier momento y sin previo aviso. Toda modificación se realizará con apego a la normatividad legal vigente.</p>


<br />
<h2>Políticas de Privacidad</h2>

<h5>Introducción</h5>
<p>En CENCOSUD RETAIL PERÚ S.A. (en adelante “Cencosud”), aseguramos la máxima reserva y protección sobre aquellos datos personales de los clientes/usuarios que sean ingresados en el aplicativo de su propiedad: <a href="https://turnera.cenco.app">https://turnera.cenco.app</a>.<br/>
En este documento describimos la “Política de Privacidad” (en adelante, también denominado “Política”) que regula el tratamiento de los datos personales que los clientes/usuarios registran en el sitio web.</p>

<h5>Objetivo</h5>
<p>En Cencosud estamos especialmente interesados en ofrecer a nuestros clientes/usuarios el más alto nivel de seguridad y proteger la confidencialidad de los datos que nos aporta. Por ello, las relaciones comerciales se realizan en un entorno de servidor seguro bajo protocolo SSL (Secure Socket Layer), actualmente empleado por las empresas más importantes del mundo para realizar transacciones electrónicas seguras.<br/>
Nuestro aplicativo ha sido creado y diseñado para facilitar la reserva de un turno de recojo de las cenas navideñas adquiridas físicamente por el cliente en las tiendas de supermercados de Cencosud.</p>

<h5>Destinatarios de los datos personales</h5>
<p>El destinatario de la información personal de los usuarios/clientes que se recopile mediante los formularios y/o documentos análogos es Cencosud, por lo expuesto, ningún tercero podrá acceder a la información incluida en el aplicativo.</p>

<h5>Legislación</h5>
<p>Esta política está regulada por la legislación peruana y en particular por:
<ul>
<li>Ley N° 29733 – Ley de Protección de Datos Personales (en adelante, denominado “LPDP”).</li>
<li>Reglamento de la Ley N° 29733, aprobado por el Decreto Supremo N° 003-2013-JUS (en adelante, denominado “RLPDP”).</li>
<li>Directiva de Seguridad de la Información, aprobada por la Resolución Directoral N° 019-2013-JUS/DGPDP (en adelante, denominado “Directiva”).</li>
</ul>
De acuerdo con la LPDP y el RLPDP, se entiende por datos personales toda información numérica, alfabética, gráfica, fotográfica, acústica, sobre hábitos personales, o de cualquier otro tipo concerniente a una persona natural que la identifica o la hace identificable a través de medios que pueden ser razonablemente utilizados.<br/>
Asimismo, se entiende por tratamiento de datos personales a cualquier operación o procedimiento técnico, automatizado o no, que permite la recopilación, registro, organización, almacenamiento, conservación, elaboración, modificación, extracción, consulta, utilización, bloqueo, supresión, comunicación por transferencia o por difusión o cualquier otra forma de procesamiento que facilite el acceso, correlación o interconexión de los datos personales.</p>

<h5>Calidad de los datos personales</h5>
<p>Los datos personales solicitados a nuestros clientes/usuarios, son datos básicos de contacto y son adecuados, pertinentes y no excesivos en relación con la finalidad para los que se recogen.<br/>
Los datos personales a los que en Cencosud tendremos acceso, serán aquellos que los clientes/usuarios faciliten voluntariamente.<br/>
En Cencosud les proporcionamos a nuestros clientes/usuarios los recursos técnicos adecuados para que tomen conocimiento de la presente Política y de cualquier otra información que pueda ser relevante.<br/>
Los datos personales facilitados por nuestros clientes/usuarios tienen que ser exactos y correctos de forma que respondan con veracidad a su situación actual. En caso contrario estos datos serán cancelados.<br/>
Los datos personales serán cancelados cuando hayan dejado de ser necesarios para las finalidades para las cuales han sido recopilados. Sin embargo, se conservarán durante el tiempo en que en Cencosud nos puedan exigir algún tipo de responsabilidad derivada de esta relación con nuestros clientes/usuarios.</p>

<h5>Tiempo de conservación de sus datos personales</h5>
<p>Los datos personales del cliente/usuario serán conservados en los términos establecidos en la LPDP y el RLPDP, en el tiempo que sea necesario para cumplir las finalidades identificadas en el punto número 8 de la presente Política.<br/>
Cencosud, en condición de Titular del banco de datos, se abstendrá de conservar los datos personales del Cliente/usuario en los siguientes eventos:
<ul>
<li>Cuando tenga conocimiento de su carácter inexacto o incompleto, sin perjuicio de los derechos del cliente/usuario al respecto.</li>
<li>Cuando los datos personales objeto de tratamiento hayan dejado de ser necesarios o pertinentes para el cumplimiento de las finalidades previstas en la presente Política, salvo medie consentimiento expreso del cliente para su conservación o procedimiento de anonimización o disociación.</li>
</ul>
La supresión no procede cuando los datos personales deban ser conservados en virtud de un mandato legal o en virtud de las relaciones contractuales entre Cencosud, en condición de responsable del tratamiento y el Titular de los datos personales, que justifiquen el tratamiento de estos, con fundamento en el Artículo 69 del RLPDP.</p>

<h5>Información que recolectamos</h5>
<p>Cencosud, como responsable del tratamiento, a través de los formularios subidos en nuestro aplicativo, puede recopilar los siguientes datos:

<ul>
<li>Nombres</li>
<li>Teléfono</li>
<li>Correo electrónico</li>
<li>Comprobante de pago</li>
<li>Número de documento de identidad</li>
<li>Dirección</li>
</ul>
</p>

<h5>Finalidad de los tratamientos de la información personal</h5>
<p>Las finalidades de tratamiento de los datos personales que nuestros clientes/usuario introducen en los diferentes formularios del sitio web se orientan a gestionar un programa eficiente de entrega de pedidos en tienda que permita la ejecución de las prestaciones contractuales de manera eficiente, realizar coordinaciones y comunicaciones con el cliente con el fin de coordinar las entregas, contestación de sus consultas, peticiones y la atención de cualquier otro tipo de información que nos requieran a través de éstos.<br/>
Los datos personales facilitados por nuestros clientes/usuarios se almacenarán en los bancos de datos que forman parte del sistema de información de Cencosud y serán tratados para poder llevar a cabo las finalidades expuestas anteriormente.<br/>
Los bancos de datos que están registrados actualmente ante la Autoridad de Protección de Datos Personales son los siguientes:

<ul>
<li>CLIENTES con código RNPDP-PJP N° 1680.</li>
<li>COLABORADORES con código RNPDP-PJP N° 1681.</li>
<li>CONTRO DE ACCESOS con código RNPDP-PJP N° 1682.</li>
<li>PROVEEDORES con código RNPDP-PJP N° 1683.</li>
<li>RECLUTAMIENTO DE COLABORADORES con código RNPDP-PJP N° 1684.</li>
<li>SEGURIDAD Y SALUD EN EL TRABAJO con código RNPDP-PJP N° 1685.</li>
<li>VIDEOVIGILANCIA con código RNPDP-PJP N° 1686.</li>
<li>QUEJAS Y RECLAMOS con código RNPDP-PJP N° 18061.</li>
</ul>

Cabe destacar que este apartado se actualizará conforme se hagan modificaciones en los bancos de datos.<br/>
La dirección del titular del banco de datos es la siguiente: Calle Augusto Angulo N° 130. Urbanización San Antonio, distrito de Miraflores, provincia y departamento de Lima.<br/>
La información que se recaba a través de los formularios y/o documentos análogos alojados en el aplicativo de Cencosud se almacenarán en el banco de datos denominado CLIENTES, inscrito ante la Autoridad de Protección de Datos Personales con el código RNPDP-PJP N° 1680.<br/>
En ese sentido, los bancos de datos que contienen datos personales han sido inscritos en el Registro de Protección de Datos de la Autoridad de Protección de Datos Personales. Los datos personales que faciliten nuestros clientes/usuarios sólo podrán ser conocidos y manejados por el personal de Cencosud que necesite conocer dicha información para poder tratar la información conforme a las finalidades autorizadas o contestar las solicitudes de nuestros clientes/usuarios.<br/>
Estos datos personales serán tratados de forma leal y lícita y no serán utilizados para otras finalidades incompatibles con las especificadas.</p>

<h5>Comunicación por transferencia de datos personales y tratamientos por encargo</h5>
<p>En Cencosud respetamos la privacidad de nuestros clientes/usuarios, no transferimos su información a terceros si usted no desea expresamente que lo hagamos.<br/>
Asimismo, es conveniente que nuestros clientes/usuarios sepan que sus datos personales podrán ser comunicados por transferencia a las entidades administrativas, autoridades judiciales y/o policiales, siempre y cuando esté establecido por Ley.</p>

<h5>Flujo transfronterizo de datos personales</h5>
<p>La información personal que se recopila mediante los formularios y/o documentos análogos alojados en el aplicativo de Cencosud se almacena en nuestros servidores, los cuales están alojados en Santiago de Chile.<br/>
En ese sentido, con la finalidad de ejecutar las prestaciones que corresponden y que están relacionadas con el servicio, y previa declaración de la autoridad competente, transfiere a los siguientes Terceros los datos personales cuyo acceso ha sido brindado por usted en condición de titular de la Información:<br/>
Datos recopilados mediante formularios:<br/>
Cencosud – Chile (Ubicación Física: Santiago de Chile). Fines de almacenamiento de la información.</p> 

<h5>Confidencialidad de los datos personales</h5>
<p>Los datos personales facilitados por los clientes/usuarios serán tratados con total confidencialidad. Cencosud se compromete a guardar secreto profesional indefinidamente respecto de los mismos y garantiza el deber de guardarlos adoptando todas las medidas de seguridad necesarias.</p>

<h5>Seguridad de los datos personales</h5>
<p>En cumplimiento de la normativa vigente, en Cencosud hemos adoptado las medidas técnicas de seguridad apropiadas a la categoría de los datos personales necesarias para mantener el nivel de seguridad requerido, con el objetivo de evitar la alteración, pérdida o el tratamiento o accesos no autorizados que puedan afectar a la integridad, confidencialidad y disponibilidad de la información.<br/>
En Cencosud tenemos implementadas todas las medidas de índole legal, técnica y organizativa necesarias para garantizar la seguridad de los datos personales y evitar su alteración, pérdida y tratamiento y/o acceso no autorizado, teniendo en cuenta el estado de la tecnología, la naturaleza de los datos almacenados y los riesgos a que están expuestos, ya sea que provengan de la acción humana, del medio físico o natural, tal y como establece la legislación peruana vigente de protección de datos personales.<br/>
En Cencosud también tenemos implementadas medidas de seguridad adicionales para reforzar la confidencialidad e integridad de la información y continuamente mantenemos la supervisión, control y evaluación de los procesos para asegurar la privacidad de los datos personales. Sin embargo, la transmisión de información mediante las redes de comunicación y de Internet no es totalmente segura; por eso, y a pesar de que en Cencosud realizamos los mejores esfuerzos para proteger los datos personales, no podemos garantizar la seguridad de los mismos durante el tránsito hasta el sitio web.<br/>
En tal sentido, toda la información nuestros los clientes/usuarios proporcionen, se enviará por su cuenta y riesgo. Es por ello que en Cencosud recomendamos la máxima diligencia a nuestros clientes/usuarios cuando trasladen a terceros o publiquen información personal para evitar poner en riesgo sus datos personales, eludiendo, Cencosud, de toda responsabilidad en caso de sustracciones, modificaciones o pérdidas de datos ilícitas.</p>

<h5>Ejercicio de derechos</h5>
<p>Los clientes/usuarios que nos hayan facilitado sus datos personales, pueden dirigirse a Cencosud, con el fin de poder ejercer sus derechos de información, acceso, rectificación, cancelación y oposición en los términos recogidos en la legislación peruana vigente.<br/>
Los clientes/usuarios podrán ejercer sus derechos presentando una solicitud escrita en nuestra oficina principal, ubicada en Calle Augusto Angulo Nº 130 Urb. San Antonio Miraflores - Lima, conforme al “Formato Modelo para el Ejercicio de Derechos ARCO” en el horario establecido para la atención al público o mediante el envío del formato debidamente llenado al correo electrónico: <a href="mailto:servicioalclienteonline@cencosud.com.pe">servicioalclienteonline@cencosud.com.pe</a>.</p>

<h5>Menores de edad</h5>
<p>En Cencosud entendemos la importancia de proteger la privacidad de los niños, especialmente en un entorno online. Por este motivo, nuestro aplicativo no está diseñado ni dirigido a menores de 14 años. Cencosud no llevará a cabo voluntariamente el tratamiento de datos personales relativos a menores de edad. En el supuesto que tengamos conocimiento que los datos personales recogidos corresponden a un menor de edad sin autorización, adoptaremos las medidas oportunas para eliminar estos datos tan pronto como sea posible.</p>

<h5>Consentimiento</h5>
<p>Al aceptar esta Política de Privacidad, nuestros clientes/usuarios están de acuerdo con todos los aspectos expuestos en este documento y nos autorizan a tratar sus datos de carácter personal para las finalidades expuestas anteriormente.<br/>
El usuario/cliente titular de la información, autoriza expresamente a Cencosud, a realizar el tratamiento de sus datos personales identificados en la presente Política, ejecutando actividades tales como recopilar, registrar, organizar, almacenar, conservar, elaborar, modificar, extraer, consultar, utilizar, bloquear, suprimir, comunicar por transferencia, difundir, o cualquier otra forma de procesamiento que facilite el acceso, correlación o interconexión de los datos personales de forma parcial o total, en los términos expresados en la presente Política.</p>

<h5>Las consecuencias de proporcionar sus datos personales y su negativa a hacerlo</h5>
<p>El suministro de los datos personales identificados en la presente Política y la obtención expresa e inequívoca del consentimiento contenido en la misma, conducirá al tratamiento de los datos personales suministrados por el titular de datos personales, según las finalidades determinadas.<br/>
Usted en condición de titular de los datos personales puede negarse a proporcionar sus datos personales a Cencosud. Por lo que este último se abstendrá de realizar el tratamiento de sus datos personales, en caso de negarse a aceptar la presente Política que contiene el consentimiento para tratamiento de datos personales. En este sentido, como consecuencia de dicha acción, usted no podrá acceder al servicio proporcionado por Cencosud a través del aplicativo toda vez que para el desarrollo del servicio resulta imprescindible contar con los datos personales del titular.</p>

<h5>Vigencia y modificación de la presente política de privacidad</h5>
<p>La Política de Privacidad de Cencosud ha sido actualizada el mes de Noviembre del 2020.<br/>
Cencosud se reserva el derecho a modificar su Política de Privacidad web en el supuesto de que exista un cambio en la legislación vigente, doctrinal, jurisprudencial o por criterios propios empresariales. Si se introdujera algún cambio en esta Política, el nuevo texto se publicará en este mismo web. Se recomienda a nuestros clientes/usuarios que accedan periódicamente a esta Política de privacidad que encontraran en este mismo sitio web.</p>

<h5>Otros</h5>
<p>Para realizar cualquier tipo de consulta respecto a esta política puede dirigirse a la siguiente dirección de correo electrónico: <a href="mailto:servicioalclienteonline@cencosud.com.pe">servicioalclienteonline@cencosud.com.pe</a>.</p>


<br />
<h2>Políticas de Cookies</h2>

<h5>Introducción</h5>
<p>CENCOSUD RETAIL PERÚ S.A. (en adelante CENCOSUD), describe en este documento la Política de Cookies que regula el aplicativo web con URL <a href="https://turnera.cenco.app">https://turnera.cenco.app</a> en adelante el sitio Web, con el objetivo de garantizar la privacidad de los Usuarios de Internet, en adelante Usuario.<br/>
Informamos a los Usuarios que en el sitio Web utilizamos cookies, tanto propias como de terceros.<br/>
Estas cookies nos permiten facilitar el uso y navegación, garantizar el acceso a determinadas funcionalidades y adicionalmente, nos ayudan a mejorar la calidad del sitio Web de acuerdo a los hábitos y estilos de navegación de los Usuarios.<br/>
CENCOSUD utiliza las cookies estrictamente necesarias y esenciales para que usted utilice el Web y le permitan moverse libremente, utilizar áreas seguras u opciones personalizadas.<br/>
El sitio Web dispone de enlaces a las redes sociales Facebook, Google. CENCOSUD no controla las cookies utilizadas por estas Web externas. Para más información sobre las cookies de las redes sociales u otros sitios Webs ajenos, aconsejamos revisar sus propias políticas de cookies.</p>

<h5>¿Qué son las cookies?</h5>
<p>Las cookies son pequeños archivos de texto que los sitios Web almacenan en su ordenador, celular inteligente (Smartphone), Tablet o cualquier otro dispositivo de acceso a Internet.</p>

<h5>¿Para qué sirven?</h5>
<p>Las cookies sirven para reconocer el dispositivo de los Usuarios cuando vuelven a visitar el sitio Web, facilitándole su uso recordando sus preferencias y configuración de navegación (p. ej. Idioma, país, etc.). También sirven para mejorar los servicios que ofrecemos y para poder recopilar información estadística que nos permite entender cómo los Usuarios utilizan nuestro Web y nos ayudan a mejorar su estructura y contenidos.<br/>
Algunas cookies son estrictamente necesarias para que el sitio Web funcione correctamente y otras sirven para mejorar el rendimiento y su experiencia como usuario.<br/>
Las cookies se asocian únicamente a un Usuario anónimo y su dispositivo de acceso a Internet y no proporcionan referencias que permitan deducir datos personales del Usuario. Las cookies no pueden dañar su dispositivo y además son muy útiles, puesto que nos ayudan a identificar y resolver errores.<br/>
Mostramos información sobre nuestra Política de Cookies en la parte (superior o inferior) cada vez que se accede al sitio Web.<br/>
Ante esta información el Usuario puede realizar las siguientes acciones:<br/>

<ul>
<li>Aceptar. No se volverá a visualizar este aviso durante la presente sesión.</li>
<li>Cerrar. Se oculta el aviso en la presente página, pero al acceder a cualquier otra página del Web se volverá a mostrar el aviso en la parte superior del mismo.</li>
<li>Modificar su configuración. Podrá modificar la configuración de su navegación pero ello no evitará que se muestre el aviso sobre cookies al acceder a nuevas páginas del Web.</li>
</ul>
</p>

<h5>Tipos de cookies</h5>
<p>Según cuál sea la entidad que gestione el sitio Web desde donde se envíen las cookies y traten los datos que se obtengan, se pueden distinguir las siguientes:
<ul>
<li>Cookies propias: Son aquellas que se envían al dispositivo de acceso a Internet del Usuario desde el sitio web gestionado por el propio titular del sitio y desde el que se presta el servicio solicitado por el Usuario de Internet.</li>
<li>Cookies de tercero: Son aquellas que se envían al dispositivo de acceso a Internet del usuario desde el sitio Web pero que no es gestionado por el titular del sitio, sino por otra entidad que trata los datos obtenidos mediante las cookies.</li>
</ul>

Según el plazo de tiempo que permanecen activadas en el dispositivo de acceso a Internet del Usuario, se pueden distinguir las siguientes:
<ul>
<li>Cookies de sesión: Son un tipo de cookies diseñadas para recoger y almacenar datos mientras el Usuario accede al sitio Web. Se suelen utilizar para almacenar información que sólo interesa conservar para la prestación del servicio solicitado por el Usuario en una sola ocasión (la sesión que está utilizando en ese momento).</li>
<li>Cookies persistentes: Son un tipo de cookies en que los datos siguen almacenados en el dispositivo de acceso a Internet del usuario y pueden ser accedidas y tratadas durante un periodo definido por el responsable de la cookie, y que puede ir de unos minutos a varios años.</li>
</ul>

Según la finalidad para la que se traten los datos obtenidos mediante las cookies, se pueden distinguir las siguientes:
<ul>
<li>Cookies técnicas: Las cookies técnicas son aquellas imprescindibles y estrictamente necesarias para el correcto funcionamiento del sitio Web y la utilización de las diferentes opciones y servicios que ofrece. Son de este tipo, entre otras, las que sirven para el mantenimiento de la sesión, la gestión del tiempo de respuesta, rendimiento o validación de opciones, utilizar elementos de seguridad o compartir contenido con redes sociales.</li>
<li>Cookies de Registro: Son aquellas que se generan una vez que el Usuario se ha registrado o posteriormente ha abierto su sesión, y se utilizan para identificarle en los servicios de forma que, si cierra un servicio, el navegador o el ordenador, y en otro momento u otro día vuelve a entrar en dicho servicio, seguirá identificado, facilitando así su navegación sin tener que volver a identificarse. Esta funcionalidad se puede suprimir si el Usuario pulsa la funcionalidad “cerrar sesión”, de forma que esta Cookie se elimina y la próxima vez que entre en el servicio el Usuario tendrá que iniciar sesión para estar identificado.</li>
</ul>

El aplicativo no trabaja con Cookies analíticas y/o de optimización.</p>

<h5>Enlaces a redes sociales</h5>
<p>En el Portal también se incluyen indicadores que los Usuarios pueden utilizar, de forma sencilla e intuitiva, para enlazar y compartir ciertos contenidos del sitio Web con redes sociales. Esta funcionalidad conlleva el uso de cookies que enlazan con los sitios web de estas redes sociales, almacenando la información únicamente en ellas.</p>

<h5>¿Qué tipo de cookies utiliza este sitio web?</h5>
<p>El aplicativo sólo utiliza cookies descritas en el numeral 4) de la presente Política de Cookies.</p>

<h5>Gestión de las cookies</h5>
<p>Proporcionando esta política, CENCOSUD expone a nuestros Usuarios el compromiso adquirido sobre el uso de cookies, dándole acceso a información para que usted pueda comprender qué tipo de cookies utilizamos y por qué lo hacemos. Con esto, pretendemos proporcionarle transparencia en cuanto a los datos tratados acerca de la navegación realizada desde su equipo en el sitio Web.</p>

<h5>Otras cuestiones</h5>
<p>Para realizar cualquier tipo de consulta respecto a esta política puede dirigirse a la siguiente dirección de correo electrónico: <a href="mailto:servicioalclienteonline@cencosud.com.pe">servicioalclienteonline@cencosud.com.pe</a>.</p>

<h5>Vigencia y modificación de la presente política de privacidad</h5>
<p>La Política de cookies Web de CENCOSUD ha sido actualizada el mes de Diciembre 2020.<br/>
CENCOSUD puede modificar esta Política de Cookies en función de exigencias legislativas, reglamentarias, jurisprudenciales o con la finalidad de adaptar dicha política a las instrucciones dictadas por la Autoridad Nacional de Protección de Datos Personales, así como por criterios propios empresariales.<br/>
Por ello, y dado que dicha política puede ser actualizada periódicamente, sugerimos a nuestros Usuario que la revisen de forma regular y que la encontrarán en el sitio Web.</p>


<hr />
<h2>Protección de Datos Personales</h2>

<h5>Información para nuestros clientes</h5>
<p>Los datos personales que el Cliente ha facilitado para la reserva de turnos de recojo para las cenas navideñas en nuestras tiendas, son utilizados con la finalidad de ejecutar la relación contractual que lo vincula con nosotros y forman parte del banco de datos denominado “Clientes” de titularidad de Cencosud Retail Perú S.A. (en adelante CENCOSUD).<br/>
El Cliente puede ejercer sus derechos de acceso, actualización, inclusión, rectificación, supresión y oposición, respecto de sus datos personales en los términos previstos en la Ley N° 29733 – Ley de Protección de Datos Personales, y su Reglamento aprobado por el Decreto Supremo N° 003-2013-JUS, conforme a lo indicado en el acápite siguiente “Información para el Ejercicio de Derechos ARCO”.</p>

<h5>Información para el ejercicio de derechos ARCO</h5>
<p>El titular de los datos personales, para ejercer sus derechos de acceso, actualización, inclusión, rectificación, supresión y oposición, o cualquier otro que la ley establezca, deberá presentar una solicitud escrita en nuestra oficina principal ubicada en Calle Augusto Angulo Nº 130 Urb. San Antonio Miraflores – Lima, conforme al “Formato Modelo para el Ejercicio de Derechos ARCO” en el horario establecido para la atención al público o mediante el envío del formato debidamente llenado al correo electrónico: <a href="mailto:servicioalclienteonline@cencosud.com.pe">servicioalclienteonline@cencosud.com.pe</a>. Se podrán establecer otros canales para tramitar estas solicitudes, lo que será informado oportunamente por CENCOSUD.</p>

<h5>Formatos modelo para el ejercicio de derechos ARCO</h5>
<p>En el siguiente <a href="https://drive.google.com/file/d/1va_NEasRGuw0RirinB0g0eYowov9NHMH/view">enlace</a> puede descargar los Formatos Modelo para el Ejercicio de Derechos ARCO.</p>


</Feedback>