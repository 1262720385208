import Inflector from "Inflector";
import * as configs from "config"

export function getenv(path, defaultValue) {
    path = "REACT_APP_" + Inflector.snakefy(path.toLowerCase().replace(/\./g, '_')).toUpperCase()
    return process.env[path] || defaultValue || undefined
}

const getImpl = function (object, property) {
    const elems = Array.isArray(property) ? property : property.split('.');
    const name = elems[0];
    const value = object.hasOwnProperty(name) ? object[name] : undefined;
    if (elems.length <= 1) {
        return value;
    }
    // Note that typeof null === 'object'
    if (value === null || typeof value !== 'object') {
        return undefined;
    }
    return getImpl(value, elems.slice(1));
};

export default function config(path) {

    const value = getImpl(configs, path.split('.')) ?? getenv(path);
    return value;
}


