import {getenv} from 'configReactApp'

const _ = {
    "server": getenv('API_URL', "http://localhost:4000"),
    "base_path": getenv('BASE_PATH', 'api'),
    "grant_type": getenv('GRANT_TYPE', 'password'),
    "grant_type_social": getenv('GRANT_TYPE_SOCIAL','social'),
    "grant_type_phone": getenv('GRANT_TYPE_PHONE','phone'),
    "client_id": getenv('OAUTH_CLIENT_ID', null),
    "client_secret": getenv('OAUTH_CLIENT_SECRET', null),
    "store_items_per_page": getenv('STORE_ITEMS_PER_PAGE', 50),
    "scopes": getenv('OAUTH_SCOPES', 'user'),
    "endpoint": {
        "booking": getenv('API_ENDPOINT_BOOKING', 'bookings'),
        "brand": getenv('API_ENDPOINT_BRAND', 'brands'),
        "city": getenv('API_ENDPOINT_CITY', 'cities'),
        "country": getenv('API_ENDPOINT_COUNTRY', 'countries'),
        "family": getenv('API_ENDPOINT_FAMILY', 'families'),
        "password": getenv('API_ENDPOINT_FORGOT_PASSWORD', 'password/email'),
        "reset_password": getenv('API_ENDPOINT_FORGOT_PASSWORD', 'password/reset'),
        "identity": getenv('API_ENDPOINT_IDENTITY', 'identities'),
        "locations": getenv('API_ENDPOINT_LOCATIONS', 'locations'),
        "login": getenv('API_ENDPOINT_LOGIN', 'oauth/token'),
        "oauth": getenv('API_ENDPOINT_OAUTH', 'oauth'),
        "profile": getenv('API_ENDPOINT_PROFILE', 'profile'),
        "register": getenv('API_ENDPOINT_REGISTER', 'register'),
        "store": getenv('API_ENDPOINT_STORE', 'stores'),
        "store_times": getenv('API_ENDPOINT_STORE_TIMES', 'store_times'),
        "refresh": getenv('API_ENDPOINT_REFRESH_TOKEN', 'oauth/token/refresh'),
        "social_login": getenv('API_ENDPOINT_SOCIAL_LOGIN', 'social'),
        "check_in": getenv('API_ENDPOINT_CHECKIN', ':storeId/checkin/:bookingId'),
        "calendars": getenv('API_ENDPOINT_CALENDARS', ':storeId/calendars'),
        "queue": getenv('API_ENDPOINT_QUEUE', 'user'),
        "register_phone": getenv('API_ENDPOINT_REGISTER_PHONE', 'register/phone'),
        "login_phone": getenv('API_ENDPOINT_PHONE_LOGIN', 'phone')
    },
    "host": getenv('HOST_URL',"https://"+window.location.hostname),
}
export default _;
