import React from 'react';
import {Media} from 'reactstrap';
import LoadImage from '../Branding/Logo';
import {withRouter} from "react-router-dom"
import config from "../../configReactApp";
import moment from 'moment';
import 'moment/locale/es';
import {isBookingEnabled} from "../../helper";
import Inflector from "../../Inflector";

moment.locale('es');

const imgStyle = {
    maxHeight: '84px',
    maxWidth: '84px',
    height: 'auto',
    width: '8vh'
}


class BookingItem extends React.Component {

    render() {

        const booking = this.props.item;
        const store_time = booking.store_time;
        const store = booking.store_time.store;
        const to = config('app.booking.detail').replace(':booking', booking.id);
        const logo = LoadImage(store.brand.logo);
        const enabled = isBookingEnabled(booking);
        const type_turn = booking.calendar?.calendar_type?.name || "Turno";
        const code = booking.code;


        return (
            <Media id={"Booking" + booking.id} style={{opacity: !enabled ? '40%' : '100%', cursor: enabled ? 'pointer' : 'default'}}
                   onClick={() => {
                       if (enabled) {
                           this.props.history.push(to)
                       }
                   }}>
                <Media left middle>
                    <Media object
                           className="mr-2 mt-2"
                           style={imgStyle}
                           src={logo}
                           alt={store.name}
                    />
                </Media>
                <Media body>
                    <Media heading>{store.name}</Media>
                    <p style={{marginBottom: 0}}>#{code}</p>
                    <p style={{marginBottom: 0}}>{Inflector.ucWords(moment(store_time.start_time).calendar(), 4)}</p>
                    {type_turn !== 'Turno' &&
                    <p
                        style={{marginBottom: 0}}>{"Tipo: " + type_turn}</p>}
                    <p>{store.street}&nbsp;{store.street_number}, {store.suburb}</p>
                </Media>
            </Media>
        );

    }
}

export default withRouter(BookingItem)
