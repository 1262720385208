import * as ReducerActions from '../constants/ReducerActions/ChangePassword'
import ApiProfile from "api/ApiProfile";
import AuthAction from '../actions/Auth'
import ProfileAction from '../actions/Profile'
import ChangePasswordForm from "constants/Forms/ChangePassword";
import { validate } from "./FieldValidator";

const ChangePasswordAction = {
    FetchRequest: '',

    onValidateError: (dispatch) => (errors) => dispatch({ type: ReducerActions.SUBMIT_ERROR, payload: errors }),

    validate: (data, dispatch) => {
        return validate(data, ChangePasswordForm.fields, ChangePasswordAction.onValidateError(dispatch))
    },

    changePassword: () => (dispatch, getState) =>
        new Promise(function (resolve, reject) {
            let { errors, waiting, ...data } = { ...getState().ChangePasswordReducer };
            if(ChangePasswordAction.validate(data,dispatch)){
                ApiProfile.password(data)
                .then(response => {
                    dispatch(ChangePasswordAction.success(response))
                    dispatch(AuthAction.logout())
                    dispatch(ProfileAction.cleanProfileData())
                    dispatch(ChangePasswordAction.clearForm())
                    resolve(response)
                })
                .catch(error => {
                    if (error.response) {
                        dispatch(ChangePasswordAction.showErrors(error.response.data))
                        reject(error.response.data)
                    } else {
                        dispatch(ChangePasswordAction.showErrors(error))
                        reject(error)
                    }
                })
                ;
            } else {
                reject()
            }
        })
    ,

    showErrors: (response) => {
        return { type: ReducerActions.SUBMIT_ERROR, payload: response || null }
    },

    success: (response) => {
        return { type: ReducerActions.SUBMIT_DONE, payload: response.data || null };
    },

    clearForm: () => {
        return { type: ReducerActions.CLEAR_FORM };
    }

};

export default ChangePasswordAction
